import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = apiDomain + "/payrolls";

const getPayrollData = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const detailPayroll = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/payroll/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    let message = "Terjadi kesalahan, silakan coba lagi.";
    // Cek jika error.response dan error.response.data ada
    if (error.response && error.response.data) {
      message = error.response.data.message || message;
    } else if (error.request) {
      // Error terkait dengan request yang dibuat tapi tidak mendapatkan response
      message = "Tidak ada response dari server.";
    } else {
      // Error yang terjadi sebelum melakukan request
      message = error.message || message;
    }

    Swal.fire({
      position: "center",
      icon: "error",
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

const addPayroll = async (obj) => {
  try {
    let result = await axios({
      method: "POST",
      url: URL + "/add",
      headers: { usertoken: localStorage.getItem("usertoken") },

      data: obj,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Ditambahkan",
      showConfirmButton: false,
      timer: 1500,
    });
    return true;
  } catch (error) {
    console.log(error);
    let message = "Terjadi kesalahan, silakan coba lagi.";
    // Cek jika error.response dan error.response.data ada
    if (error.response && error.response.data) {
      message = error.response.data.message || message;
    } else if (error.request) {
      // Error terkait dengan request yang dibuat tapi tidak mendapatkan response
      message = "Tidak ada response dari server.";
    } else {
      // Error yang terjadi sebelum melakukan request
      message = error.message || message;
    }

    Swal.fire({
      position: "center",
      icon: "error",
      title: message,
      text : error.response.data.error,
      showConfirmButton: true,
    });
  }
};
const updatePayroll = async (data) => {
  try {
    let result = await axios({
      method: "PUT",
      url: URL + "/update/",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: data,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dirubah",
      showConfirmButton: false,
      timer: 1500,
    });
    return true;
  } catch (error) {
    console.log(error);
    let message = "Terjadi kesalahan, silakan coba lagi.";
    // Cek jika error.response dan error.response.data ada
    if (error.response && error.response.data) {
      message = error.response.data.message || message;
    } else if (error.request) {
      // Error terkait dengan request yang dibuat tapi tidak mendapatkan response
      message = "Tidak ada response dari server.";
    } else {
      // Error yang terjadi sebelum melakukan request
      message = error.message || message;
    }

    Swal.fire({
      position: "center",
      icon: "error",
      text : error.response.data.error,
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

const deletePayrolls = async (data, cb) => {
  try {
    let result = await axios({
      method: "DELETE",
      url: URL + "/deletes",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: { ids: data },
    });
    cb(result.data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dihapus",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (err) {
    console.log(err);
    Swal.fire({
      position: "center",
      icon: "error",
      title: err.response.data.message,
      text : err.response.data.error,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

export {
  getPayrollData,
  updatePayroll,
  deletePayrolls,
  addPayroll,
  detailPayroll,
};
