import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = apiDomain + "/sales";

const getInvoices = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/sale/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const getInvoiceCustomer = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/customer/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
    return true;
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};
const getInvoiceNumber = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/getInvoice/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const detailInvoice = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/detail/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const detailInvoiceSale = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/invoice/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const addInvoice = async (id, obj) => {
  console.log("Data yang dikirim : ", obj);

  try {
    let result = await axios({
      method: "POST",
      url: URL + "/creates/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },

      data: obj,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Ditambahkan",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
const updateInvoices = async (id, data) => {
  try {
    let result = await axios({
      method: "PUT",
      url: URL + "/update/" + id,
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: data,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dirubah",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (err) {
    console.log(err);
    Swal.fire({
      position: "center",
      icon: "error",
      title: err.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

const deleteInvoices = async (data, cb) => {
  try {
    let result = await axios({
      method: "DELETE",
      url: URL + "/deletes",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: { ids: data },
    });
    cb(result.data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dihapus",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (err) {
    console.log(err);
    Swal.fire({
      position: "center",
      icon: "error",
      title: err.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
const comparisonSales = async (obj, cb) => {
  try {
    let result = await axios({
      method: "POST",
      url: URL + "/comparison",
      headers: { usertoken: localStorage.getItem("usertoken") },

      data: obj,
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
export {
  getInvoices,
  getInvoiceNumber,
  updateInvoices,
  deleteInvoices,
  addInvoice,
  getInvoiceCustomer,
  detailInvoice,
  detailInvoiceSale,
  comparisonSales,
};
