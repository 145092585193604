import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import Select from "react-select";

import { Tooltip } from "react-tooltip";
import * as Icons from "react-bootstrap-icons";

import "./style.css";
import { getCustomers } from "../../../fetch/customerFetch";
import { getToken } from "../../../fetch/userFetch";
import {
  addProduct,
  detailProduct,
  updateProduct,
} from "../../../fetch/productFetch";

const FormProduct = () => {
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const [id, setId] = useState(0);
  const [editable, setEditable] = useState(false);
  const [valid, setValid] = useState({
    isName: true,
    isType: true,
  });
  const [data, setData] = useState({
    name: "",
    ProductId: 0,
    type: "",
    Taxable: false,
  });
  const navigation = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const productId = queryParams.productId;

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    const userToken = localStorage.getItem("usertoken");
    if (productId) {
      localStorage.setItem("productId", productId);
    }
    const storedProductId = localStorage.getItem("productId");
    if (storedProductId) {
      getOneProduct(storedProductId);
      setEditable(true);
    }

    const handleBeforeUnload = (e) => {
      if (isFormModified) {
        e.preventDefault();
        e.returnValue =
          "Perubahan belum disimpan. Anda yakin ingin meninggalkan halaman ini?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const getOneProduct = (id) => {
    detailProduct(id, (data) => {
      setData({
        name: data.name,
        ProductId: data.ProductId,
        type: data.type,
        Taxable: data.Taxable,
      });
    });
  };

  const validName = (e) => {
    const { value } = e.target;
    setData((prevState) => ({
      ...prevState,
      name: value,
    }));
    setValid((prevState) => ({
      ...prevState,
      isName: e.target.value.trim() !== "",
    }));
    setIsFormModified(true);
  };
  const cancelSubmit = (e) => {
    e.preventDefault();
    setIsFormModified(false);
    navigation("/products");
  };
  const handleTypeChange = (selectedOption) => {
    setData({
      ...data,
      type: selectedOption ? selectedOption.label : "",
    });
    setValid({
      ...valid,
      isType: true,
    });
    if (!selectedOption) {
      setValid({
        ...valid,
        isType: selectedOption,
      });
    }
  };
  const addForm = (e) => {
    e.preventDefault();

    setIsFormModified(false);
    setLoadingButton(true);
    const newProduct = {
      name: data.name,
      ProductId: data.ProductId,
      type: data.type,
      Taxable: data.Taxable,
    };
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        setTimeout(() => {
          addProduct(id, newProduct);
          setLoadingButton(false);
          navigation("/products");
        }, 3000);
      });
    }
  };
  const editForm = (e) => {
    e.preventDefault();
    setIsFormModified(false);
    setLoadingButton(true);
    const editNewProduct = {
      id: productId,
      name: data.name,
      ProductId: data.ProductId,
      type: data.type,
      Taxable: data.Taxable,
    };
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        setTimeout(() => {
          updateProduct(editNewProduct);
          setLoadingButton(false);
          navigation("/products");
        }, 3000);
      });
    }
  };
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Barang & Jasa</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/products">Barang & Jasa</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {editable ? "Edit" : "Add"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="form col-lg-8">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {editable ? "Edit Barang & Jasa" : "Barang & Jasa Baru"}
                </h5>
                <hr />
                <form id="formProduct" onSubmit={editable ? editForm : addForm}>
                  <div className="row mb-3">
                    <label
                      htmlFor="nameProduct"
                      className="col-sm-4 col-form-label"
                    >
                      Nama Barang & Jasa<span className="required">*</span>
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="nameProduct"
                        name="nameProduct"
                        className={`form-control ${
                          !valid.isName ? "is-invalid" : ""
                        }`}
                        onChange={validName}
                        placeholder="Nama Barang & Jasa"
                        required
                        value={data.name}
                      />
                      {!valid.isName && (
                        <div className="invalid-feedback">
                          Nama Barang & Jasa harus diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="IDCustomer"
                      className="col-sm-4 col-form-label"
                    >
                      ID Product<span className="required">*</span>
                      <Icons.InfoCircle
                        data-tooltip-id="info-id-product"
                        className="ms-2"
                        size="0.8em"
                      />
                      <Tooltip
                        id="info-id-product"
                        place="right"
                        ariant="info"
                        content="ID Product Akan Otomatis Terbuat Dengan ID P.0000"
                      />
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="IDProduct"
                        name="IDProduct"
                        className="form-control"
                        placeholder="ID Product"
                        value={editable ? data.ProductId : "P.0000"}
                        disabled
                      />
                      {!valid.isName && (
                        <div className="invalid-feedback">
                          ID Barang & Jasa harus diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="typeProduct"
                      className="col-sm-4 col-form-label"
                    >
                      Jenis Barang & Jasa<span className="required">*</span>
                    </label>
                    <div className="col-sm-6">
                      <Select
                        id="typeProduct"
                        key={data.type || "no-value"}
                        options={[
                          { label: "Barang", value: "barang" },
                          { label: "Jasa", value: "jasa" },
                        ]}
                        value={
                          data.type
                            ? {
                                label: data.type === "Jasa" ? "Jasa" : "Barang",
                                value: data.type,
                              }
                            : null
                        }
                        onChange={handleTypeChange}
                        className={!valid.isType ? "is-invalid react-select" : "react-select"}
                        placeholder="Pilih Jenis Barang & Jasa..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        required
                      />
                      {!valid.isType && (
                        <div className="invalid-feedback">
                          Tipe Barang & Jasa Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <fieldset class="row mb-3">
                    <legend class="col-form-label col-sm-4 pt-0">Pajak</legend>
                    <div class="col-sm-8">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="pajakProduct"
                          id="gridRadios1"
                          value="true"
                          onChange={(e) =>
                            setData({
                              ...data,
                              Taxable: e.target.value,
                            })
                          }
                        />
                        <label class="form-check-label" for="gridRadios1">
                          Ada
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="pajakProduct"
                          id="gridRadios2"
                          value="false"
                          onChange={(e) =>
                            setData({
                              ...data,
                              Taxable: e.target.value,
                            })
                          }
                        />
                        <label class="form-check-label" for="gridRadios2">
                          Tidak Ada
                        </label>
                      </div>
                    </div>
                  </fieldset>
                  <div className="row mb-3 submit-button">
                    <div className="col-sm-10">
                      <button
                        className={`btn btn-sm me-3 btn-primary ${
                          loadingButton ? "" : "d-none"
                        }`}
                        type="button"
                        disabled={loadingButton}
                      >
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Menyimpan...
                      </button>
                      <button
                        type="submit"
                        className={`btn btn-sm btn-primary me-3 ${
                          loadingButton ? "d-none" : ""
                        }`}
                      >
                        Simpan
                      </button>
                      <Link
                        className="btn btn-sm  btn-secondary"
                        onClick={(e) => cancelSubmit(e)}
                      >
                        Batalkan
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FormProduct;
