import React, { useEffect, useState } from "react";
import Select from "react-select";
import * as Icons from "react-bootstrap-icons";

import { getCustomers } from "../../../fetch/customerFetch";
import { getToken } from "../../../fetch/userFetch";
import ReactDatePicker from "react-datepicker";
import { getAccountBank } from "../../../fetch/accountFetch";
import { AgGridReact } from "ag-grid-react";
import {
  detailInvoice,
  detailInvoiceSale,
  getInvoiceCustomer,
} from "../../../fetch/invoiceFetch";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  addPayments,
  detailPayment,
  updatePayment,
} from "../../../fetch/paymentFetch";
import queryString from "query-string";
const currencyFormatter = (params) => {
  if (params.value == null || isNaN(params.value)) {
    return "";
  }
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(params.value);
};
const formatDateToIndonesian = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("id-ID", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
const FormPayments = () => {
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const [editable, setEditable] = useState(false);
  const [customer, setCustomer] = useState({});
  const [bank, setBank] = useState({});
  const [invoice, setInvoice] = useState([]);
  const [valid, setValid] = useState({
    isCustomer: true,
    isDate: true,
    isBank: true,
    isInvoice: true,
  });
  const [data, setData] = useState({
    CustomerId: null,
    accountId: null,
    paymentDate: new Date(),
    InvoiceId: null,
    numberInvoice: null,
    items: [],
  });
  const [gridOptions] = useState({
    overlayNoRowsTemplate:
      '<span style="padding: 10px; border: 1px solid #444; background: lightgoldenrodyellow;">Sesuiakan Data yang Akan Dimasukan Disini.Klik Dua Kali Di Kolom Yang Ingin Diubah</span>',
    // ... properti gridOptions lainnya
  });

  const [rowData, setRowData] = useState([]);
  const [columnDefs] = useState([
    {
      field: "",
      rowDrag: true,
      width: 50,
    },
    {
      field: "No Faktur",
      sortable: true,
      width: 220,
    },
    {
      field: "Tanggal Faktur",
      filter: true,
      sortable: true,
      width: 120,
    },
    {
      field: "Total Faktur",
      filter: true,
      sortable: true,
      valueFormatter: currencyFormatter,
      width: 200,
    },
    {
      headerName: "Bayar*",
      field: "Bayar",
      sortable: true,
      valueFormatter: currencyFormatter,
      headerTooltip: "Klik dua kali pada kolom untuk mengedit",
      editable: true,
      width: 220,
    },
    {
      field: "Metode Pembayaran*",
      sortable: true,
      headerTooltip: "Klik dua kali pada kolom untuk mengedit",
      editable: true,
      width: 150,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Tunai", "Kartu Kredit", "Transfer Bank"],
      },
      cellRenderer: (params) => {
        return params.value;
      },
    },
    {
      field: "id",
      headerName: "Hapus",
      cellRenderer: (params) => (
        <Link
          className="btn btn-sm "
          onClick={() => handleRemoveRow(params.data.uniqueKey)}
          style={{
            marginLeft: "20px",
            cursor: "pointer",
          }}
        >
          <Icons.XCircle size={20} color="red" />
        </Link>
      ),
      width: 135,
    },
  ]);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const paymentId = queryParams.paymentId;
  const navigation = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    getCustomer();
    getBank();
    if (paymentId) {
      localStorage.setItem("paymentId", paymentId);
    }
    const storedProductId = localStorage.getItem("paymentId");

    if (storedProductId) {
      getOnePayment(storedProductId);
      setEditable(true);
    }
    const handleBeforeUnload = (e) => {
      if (isFormModified) {
        const message =
          "Perubahan belum disimpan. Anda yakin ingin meninggalkan halaman ini?";
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      clearTimeout(timer);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const getOnePayment = (id) => {
    detailPayment(id, (result) => {
      if (result && result.length > 0) {
        const payment = result[0];
        setData({
          CustomerId: payment.InvoicePayments[0].CustomerId,
          paymentDate: new Date(payment.PaymentDate),
          accountId: payment.Account.id,
          Status: payment.InvoicePayments[0]?.Invoice.Status,
        });
        const newRows = payment.InvoicePayments.map(
          (invoicePayment, index) => ({
            uniqueKey: Date.now() + index,
            id: invoicePayment.Invoice.id,
            "No Faktur": invoicePayment.Invoice.InvoiceNumber,
            "Tanggal Faktur": formatDateToIndonesian(
              invoicePayment.Invoice.IssueDate
            ),
            "Total Faktur": invoicePayment.Invoice.TotalAmount,
            Bayar: invoicePayment.Invoice.AmountPaid,
            "Metode Pembayaran*": invoicePayment.PaymentMethod,
          })
        );
        console.log("New Rows : ", newRows);
        getInvoice(payment.InvoicePayments[0].CustomerId);
        setRowData(newRows);
      }
    });
  };

  const getCustomer = () => {
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        getCustomers(id, (cb) => {
          setCustomer(cb);
        });
      });
    }
  };
  const getBank = () => {
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        getAccountBank(id, (cb) => {
          setBank(cb);
        });
      });
    }
  };

  const getInvoice = (id) => {
    const result = getInvoiceCustomer(id, (cb) => {
      if (result) {
        setInvoice(cb);
      } else {
        setInvoice([]);
      }
    });
  };
  const handleCustomer = (selectedOption) => {
    setData({
      ...data,
      CustomerId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isCustomer: selectedOption,
    });
    getInvoice(selectedOption ? selectedOption.value : "");
    setIsFormModified(true);
  };
  const handleBank = (selectedOption) => {
    setData({
      ...data,
      accountId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isBank: selectedOption,
    });
    setIsFormModified(true);
  };
  const handleInvoice = (selectedOption) => {
    setData({
      ...data,
      InvoiceId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isInvoice: selectedOption,
    });
    setIsFormModified(true);

    if (selectedOption) {
      getInvoiceDetail(selectedOption.value);
    }
  };
  const getInvoiceDetail = (value) => {
    detailInvoiceSale(value, (cb) => {
      if (!cb || cb.length === 0) {
        console.error("No invoice data found");
        return;
      }
      const invoiceData = cb[0];

      const isDuplicate = rowData.some(
        (row) => row["No Faktur"] === invoiceData.InvoiceNumber
      );
      if (isDuplicate) {
        // Tampilkan pesan kesalahan
        Swal.fire({
          icon: "error",
          title: "Duplikat Faktur Penjualan Perkiraan",
          text: "Hanya satu akun Faktur Penjualan dengan kode tertentu yang diizinkan.",
        });
        return; // Jangan tambahkan akun ke rowData
      }
      // Mengakses objek pertama dalam array

      const newInvoice = {
        uniqueKey: Date.now(),
        id: invoiceData.id,
        "Metode Pembayaran*": "Tunai",
        "No Faktur": invoiceData.InvoiceNumber,
        "Tanggal Faktur": formatDateToIndonesian(invoiceData.IssueDate),
        "Total Faktur": invoiceData.TotalAmount,
        Bayar: invoiceData.AmountPaid,
      };
      setRowData([...rowData, newInvoice]);
    });
  };

  const handleDateChange = (date) => {
    setData({
      ...data,
      paymentDate: date,
    });
    setIsFormModified(true);
  };
  const handleCellValueChanged = (event) => {
    const updatedData = rowData.map((row) => {
      if (row.uniqueKey === event.data.uniqueKey) {
        // Jika Kode cocok, perbarui data sesuai perubahan
        return {
          ...row,
          Nama: event.data.Nama,
          Kuantitas: event.data.Kuantitas,
          Harga: event.data.Harga,
          "Total Harga": event.data.Harga * event.data.Kuantitas,
        };
      }
      return row; // Jika Kode tidak cocok, biarkan data tetap seperti sebelumnya
    });

    setRowData(updatedData); // Perbarui state dengan data yang diperbarui
    setIsFormModified(true);
  };
  const handleRemoveRow = (uniqueKey) => {
    // Pastikan Anda memanggil setRowData dengan fungsi updater
    // untuk mendapatkan state terkini dari rowData
    setRowData((currentRowData) => {
      const updatedData = currentRowData.filter(
        (row) => row.uniqueKey !== uniqueKey
      );
      return updatedData; // Return the filtered array
    });
  };
  const cancelSubmit = (e) => {
    e.preventDefault();
    setIsFormModified(false);
    navigation("/payments");
  };
  const addForm = (e) => {
    e.preventDefault();
    const { CustomerId, paymentDate, accountId } = data;
    setLoadingButton(true);
    setIsFormModified(false);
    const isDataValid = validateData();
    if (!isDataValid) {
      setLoadingButton(false);
      return;
    }
    const payload = {
      customerId: CustomerId.toString(),
      accountId: accountId.toString(),
      paymentDate: paymentDate.toISOString().split("T")[0],
      invoices: rowData.map((row) => ({
        invoiceId: row.id.toString(),
        PaymentMethod: row["Metode Pembayaran*"].toString(),
        AmountDue: row["Total Faktur"].toString(),
        AmountReceived: row.Bayar.toString(),
      })),
    };
    setTimeout(async () => {
      const result = await addPayments(payload);
      if (result) {
        setLoadingButton(false);
        navigation("/payments");
      } else {
        setLoadingButton(false);
      }
    }, 3000);
  };
  const editForm = (e) => {
    e.preventDefault();
    const { CustomerId, paymentDate, accountId } = data;
    setLoadingButton(true);
    setIsFormModified(false);

    // Validasi data sebelum mengirim permintaan ke server
    const isDataValid = validateData();
    if (!isDataValid) {
      setLoadingButton(false);
      return;
    }
    const idPayment = localStorage.getItem("paymentId");
    // Menyiapkan payload untuk dikirim ke server
    const payload = {
      paymentId: idPayment,
      customerId: CustomerId,
      paymentDate: paymentDate,
      accountId: accountId,
      invoices: rowData.map((row) => ({
        AmountDue: row["Total Faktur"],
        invoiceId: row.id,
        PaymentMethod: row["Metode Pembayaran"],
        AmountReceived: parseFloat(row.Bayar),
      })),
    };
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        setTimeout(async () => {
          const pay = await updatePayment(payload);
          if (pay) {
            setLoadingButton(false);
            navigation("/payments");
          } else {
            setLoadingButton(false);
          }
        }, 3000);
      });
    }
  };
  const validateData = () => {
    let errorMessage = "";
    let isValid = true;

    // Pengecekan untuk setiap row
    for (const row of rowData) {
      // Pengecekan Bayar >= Total Faktur
      if (row.Bayar && row["Total Faktur"]) {
        const paidAmount = parseFloat(row.Bayar);
        const totalInvoice = parseFloat(row["Total Faktur"]);

        if (
          isNaN(paidAmount) ||
          isNaN(totalInvoice) ||
          paidAmount < totalInvoice
        ) {
          isValid = false;
          errorMessage = `Nilai Pembayaran tidak mencukupi untuk melunasi pembayaran!.`;
          break;
        }
      }
    }

    if (rowData.length === 0) {
      isValid = false;
      errorMessage = "Faktur Penjualan tidak boleh kosong.";
    }

    if (!isValid) {
      // Tampilkan pesan kesalahan atau lakukan tindakan validasi lainnya
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Validasi Gagal",
        text: errorMessage,
        showConfirmButton: false,
        timer: 1500,
      });
    }

    return isValid;
  };

  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Penerimaan Penjualan</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/invoices">Penerimaan Penjualan</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {editable ? "Edit" : "Add"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="formPayment col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {editable
                    ? "Edit Penerimaan Penjualan"
                    : "Penerimaan Penjualan Baru"}
                </h5>
                <hr />
                <form id="formPayment" onSubmit={editable ? editForm : addForm}>
                  <div className="row mb-3">
                    <label
                      htmlFor="vendorSelectPayment"
                      className="col-sm-2 col-form-label"
                    >
                      Pelanggan<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        id="vendorSelectPayment"
                        key={data.CustomerId || "no-value"}
                        options={customer.map((custom) => ({
                          label: custom.Name,
                          value: custom.id,
                        }))}
                        value={
                          data.CustomerId
                            ? {
                                label: customer.find(
                                  (custom) => custom.id === data.CustomerId
                                )?.Name,
                                value: data.CustomerId,
                              }
                            : null
                        }
                        onChange={handleCustomer}
                        className={
                          !valid.isCustomer
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Pelanggan..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        isDisabled={editable}
                        required
                      />
                      {!valid.isCustomer && (
                        <div className="invalid-feedback">
                          Pelanggan Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="paymentDate"
                      className="col-sm-2 col-form-label"
                    >
                      Tanggal<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <ReactDatePicker
                        id="paymentDate"
                        selected={data.paymentDate}
                        onChange={handleDateChange}
                        className="form-control"
                        dateFormat="dd/MM/yyyy" // Format date in the day/month/year format
                        placeholderText="Pilih Tanggal"
                        customInput={<input readOnly={editable} />}
                        required
                      />
                      {!valid.isDate && (
                        <div className="invalid-feedback">
                          Tanggal Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="bankSelect"
                      className="col-sm-2 col-form-label"
                    >
                      Kas & Bank<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        id="bankSelect"
                        key={data.accountId || "no-value"}
                        options={bank.map((custom) => ({
                          label: custom.AccountName,
                          value: custom.id,
                        }))}
                        value={
                          data.accountId
                            ? {
                                label: bank.find(
                                  (custom) => custom.id === data.accountId
                                )?.AccountName,
                                value: data.accountId,
                              }
                            : null
                        }
                        onChange={handleBank}
                        className={
                          !valid.isBank
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Kas & Bank..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        required
                      />
                      {!valid.isBank && (
                        <div className="invalid-feedback">
                          Kas & Bank Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label
                      htmlFor="invoiceSelect"
                      className="col-sm-2 col-form-label"
                    >
                      Faktur Penjualan<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        id="invoiceSelect"
                        options={invoice.map((invoices) => ({
                          label: invoices.InvoiceNumber,
                          value: invoices.id,
                        }))}
                        value={
                          data.InvoiceId
                            ? {
                                label: invoice.find(
                                  (invoices) => invoices.id === data.InvoiceId
                                )?.InvoiceNumber,
                                value: data.InvoiceId,
                              }
                            : null
                        }
                        onChange={handleInvoice}
                        className={
                          !valid.isInvoice
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Faktur Penjualan..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                      />
                      {!valid.isInvoice && (
                        <div className="invalid-feedback">
                          Faktur Penjualan Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-5 mb-5">
                    <div
                      style={{ width: "100%", height: "100%" }}
                      className="ag-theme-material"
                    >
                      <AgGridReact
                        rowData={rowData}
                        rowSelection={"multiple"}
                        animateRows={true}
                        columnDefs={columnDefs}
                        tooltipShowDelay={0}
                        rowDragManaged={true}
                        rowDragMultiRow={true}
                        cacheBlockSize={10}
                        gridOptions={gridOptions}
                        onCellValueChanged={handleCellValueChanged}
                        domLayout="autoHeight"
                      ></AgGridReact>
                    </div>
                  </div>
                  <div className="row mb-3 ">
                    <span style={{ fontSize: 14, fontStyle: "italic" }}>
                      <span className="text-danger">*</span> Kolom harus diisi
                      dengan cara mengklik dua kali kolom
                    </span>
                  </div>
                  <div className="row mb-3 submit-button">
                    <div className="col-sm-10">
                      <button
                        className={`btn btn-sm me-3 btn-primary ${
                          loadingButton ? "" : "d-none"
                        }`}
                        type="button"
                        disabled={loadingButton}
                      >
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Menyimpan...
                      </button>
                      <button
                        type="submit"
                        className={`btn btn-sm btn-primary me-3 ${
                          loadingButton ? "d-none" : ""
                        }`}
                      >
                        Simpan
                      </button>
                      <Link
                        className="btn btn-sm  btn-secondary"
                        onClick={(e) => cancelSubmit(e)}
                      >
                        Batalkan
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FormPayments;
