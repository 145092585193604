import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = apiDomain + "/products";

const getProducts = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/tenant/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const detailProduct = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/product/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const addProduct = async (id, obj) => {
  try {
    let result = await axios({
      method: "POST",
      url: URL + "/add/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },

      data: obj,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Ditambahkan",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
const updateProduct = async (data) => {
  try {
    let result = await axios({
      method: "PUT",
      url: URL + "/updates/" + data.id,
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: data,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dirubah",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (err) {
    console.log(err);
    Swal.fire({
      position: "center",
      icon: "error",
      title: err.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

const deleteProducts = async (data, cb) => {
  try {
    let result = await axios({
      method: "DELETE",
      url: URL + "/delete",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: { ids: data },
    });
    cb(result.data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dihapus",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (err) {
    console.log(err);
    Swal.fire({
      position: "center",
      icon: "error",
      title: err.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

export {
  getProducts,
  updateProduct,
  deleteProducts,
  addProduct,
  detailProduct,
};
