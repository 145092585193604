import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = apiDomain + "/users";

const userLogin = async (data, cb) => {
  try {
    let result = await axios({
      method: "POST",
      url: URL + "/login",
      data,
    });
    cb(result.data);
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Login Successful !",
      text: "Successfully Login !",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

const getUser = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL,
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
  }
};

const checkStatus = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      headers: { usertoken: localStorage.getItem("usertoken") },
      url: URL + `/status/${id}`,
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
  }
};

const getToken = async (data, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + `/user`,
      headers: { usertoken: data },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
  }
};

const detail = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + `/details/${id}`,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
  }
};
const checkCash = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + `/check`,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
  }
};
const addCashBankAccount = async (data) => {
  try {
    let result = await axios({
      method: "POST",
      url: URL + "/start",
      headers: { usertoken: localStorage.getItem("usertoken") },
      data,
    });
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Berhasil !",
      text: "Berhasil membuat Kas/Bank !",
      showConfirmButton: false,
      timer: 1500,
    });
    return true;
  } catch (error) {
    console.log(error);
    let message = "Terjadi kesalahan, silakan coba lagi.";
    // Cek jika error.response dan error.response.data ada
    if (error.response && error.response.data) {
      message = error.response.data.message || message;
    } else if (error.request) {
      // Error terkait dengan request yang dibuat tapi tidak mendapatkan response
      message = "Tidak ada response dari server.";
    } else {
      // Error yang terjadi sebelum melakukan request
      message = error.message || message;
    }

    Swal.fire({
      position: "center",
      icon: "error",
      text: error.response.data.error,
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

export {
  userLogin,
  getUser,
  checkStatus,
  getToken,
  detail,
  checkCash,
  addCashBankAccount,
};
