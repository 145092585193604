import React from "react";

import "./printStyle.css";
const convertToWords = (number) => {
  const units = [
    "",
    "Satu",
    "Dua",
    "Tiga",
    "Empat",
    "Lima",
    "Enam",
    "Tujuh",
    "Delapan",
    "Sembilan",
    "Sepuluh", // Tambahkan penanganan khusus untuk 10
    "Sebelas"  // dan 11
  ];
  const scales = ["", "Ribu", "Juta", "Miliar", "Triliun"];

  let words = "";

  let numString = String(number);
  let numLength = numString.length;
  let numChunks = Math.ceil(numLength / 3);

  for (let i = 0; i < numChunks; i++) {
    let chunk = parseInt(numString.slice(-3), 10);
    numString = numString.slice(0, -3);

    if (chunk > 0) {
      let chunkWords = "";

      if (chunk >= 100) {
        chunkWords += units[Math.floor(chunk / 100)] + " Ratus ";
        chunk %= 100;
      }

      if (chunk === 10) {
        chunkWords += "Sepuluh "; // Penanganan khusus untuk 10
      } else if (chunk === 11) {
        chunkWords += "Sebelas "; // Penanganan khusus untuk 11
      } else {
        if (chunk >= 12 && chunk <= 19) {
          chunkWords += units[chunk % 10] + " Belas ";
        } else if (chunk >= 20) {
          chunkWords += units[Math.floor(chunk / 10)] + " Puluh ";
          if (chunk % 10 > 0) {
            chunkWords += units[chunk % 10] + " ";
          }
        } else if (chunk > 0) {
          chunkWords += units[chunk] + " ";
        }
      }

      if (i > 0) {
        chunkWords += scales[i] + " ";
      }

      words = chunkWords + words;
    }
  }

  return words.trim();
};

const PrintInvoice = ({ data }) => {
  if (!data) return <div>No data available</div>;

  const {
    company,
    pelanggan,
    date,
    invoiceNumber,
    dueDate,
    totalAmount,
    items,
  } = data;
  return (
    <div className="invoice-box">
      <table cellPadding="0" cellSpacing="0">
        <tr className="top">
          <td colSpan="2">
            <table>
              <tr>
                <td className="title border-1" colSpan="2">
                  <div className="logo-tittle">
                    <img
                      src="https://pakngos.com.pk/wp-content/uploads/2020/03/logoPlaceHolder_img.png"
                      alt="Company Logo"
                    />
                    <small>PASANG LOGO PERUSAHAAN ANDA</small>
                  </div>
                </td>
                <td className="title-company">
                  <h4>{company}</h4>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <table>
              <tr>
                <td className="vendor-section col-6">Kepada :</td>
                <td className="info-faktur col-6 text-center ">
                  <h6 className="fw-bold">Faktur Penjualan</h6>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <table>
              <tr>
                <td className="vendor col-6 fw-bold">{pelanggan}</td>
                <td className="info-faktur-detail col-6 ">
                  <div className="row mb-3 list-faktur">
                    <div className="col-6">
                      <small>
                        Tanggal <br />
                        <span className="fw-bold">{date}</span>
                      </small>
                    </div>
                    <div className="col-6">
                      <small>
                        Nomor <br />
                        <span className="fw-bold">{invoiceNumber}</span>
                      </small>
                    </div>
                  </div>
                  <div className="row mb-3 list-faktur">
                    <div className="col-6">
                      <small>
                        Jatuh Tempo <br />
                        <span className="fw-bold">{dueDate}</span>
                      </small>
                    </div>
                    <div className="col-6">
                      <small>
                        Mata Uang <br />
                        <span className="fw-bold">IDR Rupiah</span>
                      </small>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table className="table" border={1}>
              <thead>
                <tr>
                  <th>Kode Barang</th>
                  <th>Nama Barang</th>
                  <th>Qty</th>
                  <th>Harga</th>
                  <th>Total Harga</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  items &&
                  items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.kode}</td>
                      <td>{item.nama}</td>
                      <td>{item.qty}</td>
                      <td>{item.price}</td>
                      <td>{item.total}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table>
              <tr>
                <td className="col-2">Terbilang : </td>
                <td className="border-1">{convertToWords(totalAmount)}</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <table>
              <tr>
                <td className="col-8"></td>
                <td className="span-info border-1">Sub Total</td>
                <td className="text-end border-1 col-8">{totalAmount}</td>
              </tr>
              <tr>
                <td className="col-8"></td>
                <td className="span-info border-1">PPN 0%</td>
                <td className="text-end border-1 col-8">0</td>
              </tr>
              <tr className="total-tr">
                <td className="col-8"></td>
                <td
                  className="span-info border-1 fw-bold"
                  style={{ borderWidth: "2px", borderColor: "#000" }}
                >
                  Total
                </td>
                <td
                  className="text-end border-1 col-8 fw-bold"
                  style={{ borderWidth: "2px", borderColor: "#000" }}
                >
                  {totalAmount}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <table className="sign-section">
              <tr>
                <td className="text-center col-2 ">Disiapkan Oleh</td>
                <td>
                  <span className="aggre">Disetujui Oleh</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ justifyContent: "center" }}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <hr
                      style={{ borderTop: "2px solid #000", width: "200px" }}
                    />
                    {/* Garis dengan ketebalan 2px */}
                  </div>
                </td>
                <td>
                  <div style={{ justifyContent: "center" }}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <hr
                      style={{ borderTop: "2px solid #000", width: "200px" }}
                    />
                    {/* Garis dengan ketebalan 2px */}
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default PrintInvoice;
