import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import * as Icons from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addEmployee, detailEmployee, updateEmployee } from "../../../fetch/employeeFetch";
import queryString from "query-string";

const EmployeeForm = () => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editable, setEditable] = useState(false);
  const isFormModifiedRef = useRef(false);
  const [addAddress, setAddAddress] = useState(false);
  const [code, setCode] = useState("");
  const [provinsi, setProvinsi] = useState([]);
  const [cities, setCities] = useState([]);

  const [valid, setValid] = useState({
    isFullName: true,
    isPhone: true,
    isEmail: true,
  });
  const [data, setData] = useState({
    FullName: null,
    EmployeeNumber: null,
    NIK: null,
    Position: null,
    JoinDate: new Date(),
    Email: null,
    Phone: null,
    EmploymentStatus: null,
    Gender: null,
    BirthDate: null,
    MaritalStatus: null,
    Address: null,
    City: null,
    PostalCode: null,
    Country: null,
    Provinsi: null,
  });
  const navigation = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const employeeId = queryParams.employeeId;
  useEffect(() => {
    if (code) {
      fetchLocation(code);
    }
  }, [code]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);

    fetchLocation();
    if (employeeId) {
      localStorage.setItem("employeeId", employeeId);
    }
    const storedProductId = localStorage.getItem("employeeId");
    if (storedProductId) {
      getEmployee(storedProductId);
      setEditable(true);
    }
    const handleBeforeUnload = (e) => {
      if (isFormModifiedRef.current) {
        e.preventDefault();
        e.returnValue =
          "Perubahan belum disimpan. Anda yakin ingin meninggalkan halaman ini?";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      clearTimeout(timer);
    };
  }, []);
  

  const getEmployee = (id) => {
    detailEmployee(id, (result) => {
      if (result.Address && result.City) {
        setAddAddress(true);
      }
      setData({
        FullName: result.FullName,
        EmployeeNumber: result.EmployeeNumber,
        NIK: result.NIK,
        Position: result.Position,
        JoinDate: new Date(result.JoinDate),
        Email: result.Email,
        Phone: result.PhoneNumber,
        EmploymentStatus: result.EmploymentStatus,
        Gender: result.Gender,
        BirthDate: new Date(result.BirthDate),
        MaritalStatus: result.MaritalStatus,
        Address: result.Address,
        Provinsi: result.City ? result.City.split(".")[0] : null,
        City: result.City,
        PostalCode: result.PostalCode,
      });
      setCode(result.City ? result.City.split(".")[0] : null);
    });
  };
  const fetchLocation = async (kode) => {
    const apiKey =
      "cab3171a3ed4221835e9a860756533201915f91be1a976ebc862711b8b8e3551";
    const kodeApi = kode ? kode : code;
    try {
      const response = await axios.get(
        `https://api.binderbyte.com/wilayah/provinsi?api_key=${apiKey}`
      );
      setProvinsi(response.data.value);
      const result = await axios.get(
        `https://api.binderbyte.com/wilayah/kabupaten?api_key=${apiKey}&id_provinsi=${kodeApi}`
      );
      setCities(result.data.value);
    } catch (err) {
      console.error(err.message || err);
    }
  };
  const handleSelect = (selectedOption) => {
    setData({
      ...data,
      Provinsi: selectedOption ? selectedOption.value : "",
    });
    setCode(selectedOption ? selectedOption.value : "");
    fetchLocation(selectedOption ? selectedOption.value : "");
  };
  const validFullName = (e) => {
    const { value } = e.target;
    setData((prevState) => ({
      ...prevState,
      FullName: value,
    }));
    setValid((prevState) => ({
      ...prevState,
      isFullName: e.target.value.trim() !== "",
    }));
    isFormModifiedRef.current = true;
  };
  const handleDateChange = (date) => {
    setData({
      ...data,
      JoinDate: date,
    });
    isFormModifiedRef.current = true;
  };
  const handleEmailChange = (event) => {
    const value = event.target.value;
    // Regex untuk validasi format email sederhana
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setData((prevState) => ({
      ...prevState,
      Email: value,
    }));
    setValid((prevState) => ({
      ...prevState,
      isEmail: emailRegex.test(value),
    }));
    isFormModifiedRef.current = true;
  };
  const handlePhoneChange = (event) => {
    let value = event.target.value;
    if (value.startsWith("08")) {
      value = "+62" + value.substring(2);
    }

    const phoneRegex = /^(?:\+62\d{10,}|08\d{9,})$/;

    const isPhoneValid = phoneRegex.test(value);
    if (isPhoneValid && value.startsWith("08")) {
      value = "+62" + value.substring(2);
    }

    setData((prevState) => ({
      ...prevState,
      Phone: value,
    }));

    setValid((prevState) => ({
      ...prevState,
      isPhone: isPhoneValid,
    }));
    isFormModifiedRef.current = true;
  };
  const handleStatusEmployeeSelect = (selectedOption) => {
    setData({
      ...data,
      EmploymentStatus: selectedOption ? selectedOption.value : "",
    });
  };
  const handleGenderEmployeeSelect = (selectedOption) => {
    setData({
      ...data,
      Gender: selectedOption ? selectedOption.value : "",
    });
  };
  const handleBirthDateChange = (date) => {
    setData({
      ...data,
      BirthDate: date,
    });
    isFormModifiedRef.current = true;
  };
  const handleMaritalStatusEmployee = (selectedOption) => {
    setData({
      ...data,
      MaritalStatus: selectedOption ? selectedOption.value : "",
    });
  };
  const handleAddAddressChange = (event) => {
    setAddAddress(event.target.checked);
  };
  const cancelSubmit = (e) => {
    e.preventDefault();
    isFormModifiedRef.current = false;
    navigation("/employees");
  };
  const addForm = (e) => {
    e.preventDefault();
    isFormModifiedRef.current = false;
    setLoadingButton(true);
    const payload = {
      FullName: data.FullName,
      NIK: data.NIK,
      Position: data.Position,
      JoinDate: data.JoinDate,
      Email: data.Email,
      PhoneNumber: data.Phone,
      EmploymentStatus: data.EmploymentStatus,
      Gender: data.Gender,
      BirthDate: data.BirthDate,
      MaritalStatus: data.MaritalStatus,
      Address: data.Address,
      City: data.City,
      PostalCode: data.PostalCode,
    };
    setTimeout(async () => {
      const result = await addEmployee(payload);
      if (result) {
        setLoadingButton(false);
        navigation("/employees");
      } else {
        setLoadingButton(false);
      }
    }, 3000);
  };
  const editForm = (e) => {
    e.preventDefault();
    isFormModifiedRef.current = false;
    setLoadingButton(true);
    const payload = {
      id: employeeId,
      FullName: data.FullName,
      NIK: data.NIK,
      Position: data.Position,
      JoinDate: data.JoinDate,
      Email: data.Email,
      PhoneNumber: data.Phone,
      EmploymentStatus: data.EmploymentStatus,
      Gender: data.Gender,
      BirthDate: data.BirthDate,
      MaritalStatus: data.MaritalStatus,
      Address: data.Address,
      City: data.City,
      PostalCode: data.PostalCode,
    };
    setTimeout(async () => {
      const result = await updateEmployee(payload);
      if (result) {
        setLoadingButton(false);
        navigation("/employees");
      } else {
        setLoadingButton(false);
      }
    }, 3000);
  };
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Karyawan</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">Perusahaan</li>
                  <li className="breadcrumb-item">
                    <a href="/employees">Karyawan</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {editable ? "Edit" : "Add"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="employeeForm col-lg-8">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {editable ? "Edit Karyawan" : "Karyawan Baru"}
                </h5>
                <hr />
                <form
                  onSubmit={editable ? editForm : addForm}
                  id="formEmployee"
                >
                  <div className="row mb-3">
                    <label
                      htmlFor="nameEmployee"
                      className="col-sm-4 col-form-label"
                    >
                      Nama Karyawan<span className="required">*</span>
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="nameEmployee"
                        name="nameEmployee"
                        className={`form-control ${
                          !valid.isFullName ? "is-invalid" : ""
                        }`}
                        onChange={validFullName}
                        placeholder="Nama Karyawan"
                        required
                        value={data.FullName}
                      />
                      {!valid.isFullName && (
                        <div className="invalid-feedback">
                          Nama Karyawan harus diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="idEmployee"
                      className="col-sm-4 col-form-label"
                    >
                      ID Karyawan<span className="required">*</span>
                      <Icons.InfoCircle
                        data-tooltip-id="info-id-Employee"
                        className="ms-2"
                        size="0.8em"
                      />
                      <Tooltip
                        id="info-id-Employee"
                        place="right"
                        ariant="info"
                        content="ID Karyawan Akan Otomatis Terbuat Dengan ID E.0000"
                      />
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="idEmployee"
                        name="idEmployee"
                        className="form-control"
                        placeholder="ID Karyawan"
                        value={editable ? data.EmployeeNumber : "E.0000"}
                        disabled
                      />
                      {!valid.isName && (
                        <div className="invalid-feedback">
                          ID Karyawan harus diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="identityEmployee"
                      className="col-sm-4 col-form-label"
                    >
                      NIK
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="identityEmployee"
                        name="identityEmployee"
                        className="form-control"
                        onChange={(e) => {
                          setData({
                            ...data,
                            NIK: e.target.value,
                          });
                        }}
                        placeholder="NIK"
                        value={data.NIK}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="positionEmployee"
                      className="col-sm-4 col-form-label"
                    >
                      Posisi Jabatan
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="positionEmployee"
                        name="positionEmployee"
                        className="form-control"
                        onChange={(e) => {
                          setData({
                            ...data,
                            Position: e.target.value,
                          });
                        }}
                        placeholder="Posisi Jabatan"
                        value={data.Position}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="joinDateEmployee"
                      className="col-sm-4 col-form-label"
                    >
                      Tanggal Masuk
                    </label>
                    <div className="col-sm-6">
                      <ReactDatePicker
                        id="joinDateEmployee"
                        selected={data.JoinDate}
                        onChange={handleDateChange}
                        className="form-control"
                        dateFormat="dd/MM/yyyy" // Format date in the day/month/year format
                        placeholderText="Pilih Tanggal"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="statusEmployee"
                      className="col-sm-4 col-form-label"
                    >
                      Status Karyawan
                    </label>
                    <div className="col-sm-6">
                      <Select
                        id="statusEmployee"
                        key={data.EmploymentStatus || "no-value"}
                        options={[
                          { label: "Karyawan Kontrak", value: "Kontrak" },
                          { label: "Karyawan Tetap", value: "Tetap" },
                          { label: "Karyawan Magang", value: "Magang" },
                        ]}
                        value={
                          data.EmploymentStatus
                            ? {
                                label:
                                  data.EmploymentStatus === "Kontrak"
                                    ? "Karyawan Kontrak"
                                    : data.EmploymentStatus === "Tetap"
                                    ? "Karyawan Tetap"
                                    : data.EmploymentStatus === "Magang"
                                    ? "Karyawan Magang"
                                    : "",
                                value: data.EmploymentStatus,
                              }
                            : null
                        }
                        onChange={handleStatusEmployeeSelect}
                        className="react-select"
                        placeholder="Pilih Status Karyawan..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="genderEmployee"
                      className="col-sm-4 col-form-label"
                    >
                      Jenis Kelamin
                    </label>
                    <div className="col-sm-6">
                      <Select
                        id="genderEmployee"
                        key={data.Gender || "no-value"}
                        options={[
                          { label: "Pria", value: "Pria" },
                          { label: "Wanita", value: "Wanita" },
                        ]}
                        value={
                          data.Gender
                            ? {
                                label:
                                  data.Gender === "Pria" ? "Pria" : "Wanita",
                                value: data.Gender,
                              }
                            : null
                        }
                        onChange={handleGenderEmployeeSelect}
                        className="react-select"
                        placeholder="Pilih Jenis Kelamin..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="birthDayEmployee"
                      className="col-sm-4 col-form-label"
                    >
                      Tanggal Lahir
                    </label>
                    <div className="col-sm-6">
                      <ReactDatePicker
                        id="birthDayEmployee"
                        selected={data.BirthDate}
                        onChange={handleBirthDateChange}
                        className="form-control"
                        dateFormat="dd/MM/yyyy" // Format date in the day/month/year format
                        placeholderText="Pilih Tanggal Lahir"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="emailEmployee"
                      className="col-sm-4 col-form-label"
                    >
                      Email
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="email"
                        id="emailEmployee"
                        name="emailEmployee"
                        className={`form-control ${
                          !valid.isEmail ? "is-invalid" : ""
                        }`}
                        placeholder="alamatemail@example.com"
                        value={data.Email}
                        onChange={handleEmailChange}
                      />
                      {!valid.isEmail && (
                        <div className="invalid-feedback">
                          Harap masukkan alamat email yang valid.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="maritalStatusEmployee"
                      className="col-sm-4 col-form-label"
                    >
                      Status Menikah
                    </label>
                    <div className="col-sm-6">
                      <Select
                        id="maritalStatusEmployee"
                        key={data.MaritalStatus || "no-value"}
                        options={[
                          { label: "Belum Menikah", value: "Belum Menikah" },
                          { label: "Menikah", value: "Menikah" },
                          { label: "Cerai", value: "Cerai" },
                        ]}
                        value={
                          data.MaritalStatus
                            ? {
                                label:
                                  data.MaritalStatus === "Belum Menikah"
                                    ? "Belum Menikah"
                                    : data.MaritalStatus === "Menikah"
                                    ? "Menikah"
                                    : data.MaritalStatus === "Cerai"
                                    ? "Cerai"
                                    : "",
                                value: data.MaritalStatus,
                              }
                            : null
                        }
                        onChange={handleMaritalStatusEmployee}
                        className="react-select"
                        placeholder="Pilih Status Menikah..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="phoneEmployee"
                      className="col-sm-4 col-form-label"
                    >
                      Nomor Handphone
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="tel"
                        id="phoneEmployee"
                        name="phoneEmployee"
                        className={`form-control ${
                          !valid.isPhone ? "is-invalid" : ""
                        }`}
                        placeholder="+6281234567890"
                        value={data.Phone}
                        onChange={handlePhoneChange}
                      />
                      {!valid.isPhone && (
                        <div className="invalid-feedback">
                          Format Nomor Handphone Salah
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-4 col-form-label">
                      Tambahkan Alamat
                    </div>
                    <div className="col-sm-6">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={addAddress}
                          onChange={handleAddAddressChange}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {addAddress && (
                    <>
                      <div className="row mb-3">
                        <label
                          htmlFor="address"
                          className="col-sm-4 col-form-label"
                        >
                          Alamat Lengkap
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            id="address"
                            name="Address"
                            className="form-control"
                            value={data.Address}
                            onChange={(e) =>
                              setData({ ...data, Address: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="country"
                          className="col-sm-4 col-form-label"
                        >
                          Negara
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            id="country"
                            name="Country"
                            className="form-control"
                            value="Indonesia"
                            disabled
                            onChange={(e) =>
                              setData({ ...data, Country: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="province"
                          className="col-sm-4 col-form-label"
                        >
                          Provinsi
                        </label>
                        <div className="col-sm-6">
                          <Select
                            id="province"
                            key={data.Provinsi || "no-value"}
                            options={provinsi.map((province) => ({
                              label: province.name,
                              value: province.id,
                            }))}
                            value={
                              data.Provinsi
                                ? {
                                    label: provinsi.find(
                                      (province) =>
                                        province.id === data.Provinsi
                                    )?.name,
                                    value: data.Provinsi,
                                  }
                                : null
                            }
                            onChange={handleSelect}
                            placeholder="Pilih Provinsi..."
                            isClearable={true}
                            closeMenuOnSelect={true}
                            isSearchable={true}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="city"
                          className="col-sm-4 col-form-label"
                        >
                          Kota
                        </label>
                        <div className="col-sm-6">
                          <Select
                            id="city"
                            options={cities.map((city) => ({
                              label: city.name,
                              value: city.id,
                            }))}
                            onChange={(selectedOption) => {
                              setData({
                                ...data,
                                City: selectedOption
                                  ? selectedOption.value
                                  : "",
                              });
                            }}
                            value={
                              data.City
                                ? {
                                    label: cities.find(
                                      (city) => city.id === data.City
                                    )?.name,
                                    value: data.City,
                                  }
                                : null
                            }
                            placeholder="Pilih Kota/Kabupaten..."
                            isClearable={true}
                            isSearchable={true}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="postalCode"
                          className="col-sm-4 col-form-label"
                        >
                          Kode Pos
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            id="postalCode"
                            name="postalCode"
                            className="form-control"
                            value={data.PostalCode}
                            onChange={(e) =>
                              setData({ ...data, PostalCode: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="row mb-3 submit-button">
                    <div className="col-sm-10">
                      <button
                        className={`btn btn-sm me-3 btn-primary ${
                          loadingButton ? "" : "d-none"
                        }`}
                        type="button"
                        disabled={loadingButton}
                      >
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Menyimpan...
                      </button>
                      <button
                        type="submit"
                        className={`btn btn-sm btn-primary me-3 ${
                          loadingButton ? "d-none" : ""
                        }`}
                      >
                        Simpan
                      </button>
                      <Link
                        className="btn btn-sm  btn-secondary"
                        onClick={(e) => cancelSubmit(e)}
                      >
                        Batalkan
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EmployeeForm;
