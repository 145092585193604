import React, { useCallback, useEffect, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { HiDotsHorizontal } from "react-icons/hi";
import * as Icons from "react-bootstrap-icons";
import { AgGridReact } from "ag-grid-react";
import { useNavigate, Link } from "react-router-dom";
import { deleteReceiptBank, getBankReceipts } from "../../../fetch/bankFetch";
import Swal from "sweetalert2";

const currencyFormatter = (params) => {
  if (params.value == null || isNaN(params.value)) {
    return "";
  }
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(params.value);
};
const formatDateToIndonesian = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("id-ID", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
const ReceiptBank = () => {
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [ready, setReady] = useState(false);
  const [rowData, setRowData] = useState([
    {
      Nomor: "",
      Tanggal: "",
      Bank: "",
      "Nilai Pembayaran": "",
      "Metode Pembayaran": "",
    },
  ]);
  const [columnDefs] = useState([
    {
      field: "Nomor",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      sortable: true,
      width: 250,
    },
    {
      field: "Tanggal",
      filter: true,
      sortable: true,
      width: 150,
      editable: true,
    },
    {
      field: "Bank",
      filter: true,
      sortable: true,
      width: 250,
      editable: true,
    },
    {
      field: "Metode Pembayaran",
      filter: true,
      sortable: true,
      width: 250,
      editable: true,
    },
    {
      field: "Nilai Pembayaran",
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: currencyFormatter,
      width: 250,
    },
  ]);
  const [gridApi, setGridApi] = useState(null);
  const navigation = useNavigate();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    const userToken = localStorage.getItem("usertoken");
    localStorage.removeItem("accountReceiptId");
    if (userToken) {
      getBankReceipts((result) => {
        if (result && Array.isArray(result) && result.length > 0) {
          const formattedData = result.map((payment) => {
            // Mengambil detail InvoicePayment pertama (asumsi setiap Payment memiliki setidaknya satu InvoicePayment)
            const BankPayment = payment.BankPayments[0];

            return {
              id: payment.id,
              Nomor: payment.PaymentNumber || 0,
              "Nilai Pembayaran": payment.TotalAmountPaid || 0,
              Tanggal: formatDateToIndonesian(payment.PaymentDate) || "",
              Bank: payment.Account.AccountName || "",
              "Metode Pembayaran": BankPayment?.PaymentMethod || "",
            };
          });

          setRowData(formattedData);
          if (gridApi) {
            gridApi.setRowData([...formattedData]);
          }

          setReady(formattedData.length > 0);
        } else {
          setReady(false);
        }
      });
    }
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
  };
  const handleNew = () => {
    navigation("form");
  };
  const handleEdit = () => {
    const selectedIds = selectedRows.map((row) => row.id);

    if (selectedRows.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Tidak Ada Baris Yang Dipilih !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    if (selectedRows.length > 1) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Hanya Satu yang bisa Diedit !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    Swal.fire({
      title: "Are you sure to Edited This ?",
      text: "Are You Sure ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigation(`/banks/receipts/form?accountReceiptId=${selectedIds[0]}`);
      }
    });
  };
  const handleBulkDelete = useCallback(() => {
    const selectedIds = selectedRows.map((row) => row.id);

    if (selectedRows.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Tidak Ada Baris Yang Dipilih !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    Swal.fire({
      title: "Are you sure to delete ?",
      text: "Are You Sure ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteReceiptBank(selectedIds, (result) => {
            if (result.status === 204 || result.status === 200) {
              const newRows = rowData.filter(
                (row) => !selectedIds.includes(row.id)
              );
              setRowData(newRows);
              setSelectedRows([]);
            }
          });
        } catch (error) {
          const errorMsg =
            error.message || "Terjadi kesalahan saat menghapus akun.";
        }
      }
    });
  }, [selectedRows, rowData]);
  const handlePrint = () => {};
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Penerimaan</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">Kas & Bank</li>
                  <li className="breadcrumb-item active">Penerimaan</li>
                </ol>
              </nav>
            </div>
            <div className="action-button">
              <Link
                className="btn btn-sm btn-primary mx-3"
                onClick={(e) => {
                  handleNew();
                  e.preventDefault();
                }}
              >
                <Icons.PlusLg className="me-1" /> Baru
              </Link>
              <DropdownButton
                as={ButtonGroup}
                id={`dropdown-button-drop`}
                size="sm"
                title={<HiDotsHorizontal color="black" size="18px" />}
                className="no-chevron-dropdown"
              >
                <Dropdown.Item onClick={handleEdit}>
                  <Icons.Pencil color="blue" /> Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={handleBulkDelete}>
                  <Icons.Trash color="red" /> Delete
                </Dropdown.Item>
                <Dropdown.Item onClick={handlePrint}>
                  <Icons.Printer color="black" /> Print
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          {ready ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{ width: "100%", height: "100%" }}
                className="ag-theme-material"
              >
                <AgGridReact
                  rowData={rowData}
                  rowSelection={"multiple"}
                  animateRows={true}
                  columnDefs={columnDefs}
                  rowDragManaged={true}
                  rowDragMultiRow={true}
                  onSelectionChanged={onSelectionChanged}
                  cacheBlockSize={10}
                  domLayout="autoHeight"
                  pagination={true}
                  onGridReady={onGridReady}
                  paginationPageSize={10}
                ></AgGridReact>
              </div>
            </div>
          ) : (
            <div
              style={{
                padding: "20px",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              <h5
                style={{
                  fontSize: "1h5x",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Penerimaan Tidak Ditemukan
              </h5>
              <small className="text-muted my-5">
                Buat Penerimaan Terlebih Dahulu Untuk Melakukan Traksasi
              </small>
              <br></br>
              <button className=" mt-5 btn btn-primary" onClick={handleNew}>
                Buat Penerimaan Baru
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ReceiptBank;
