import React, { useCallback, useEffect, useState } from "react";
import "./style.css";
import { Dropdown } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import LogoS from "../../assets/images/favicon-big.png";
import {
  Bell,
  ChatLeftText,
  ExclamationCircle,
  XCircle,
  CheckCircle,
  InfoCircle,
  Person,
  Gear,
  Search,
  List,
  QuestionCircle,
  BoxArrowRight,
} from "react-bootstrap-icons";
import { detail, getToken } from "../../fetch/userFetch";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";

const Header = (props) => {
  const { handleToggleSidebar, loginHandler, loginStatus, setLoading } = props;
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [User, setUser] = useState({
    username: "",
    tenant: "",
  });
  const navigation = useNavigate();
  useEffect(() => {
    const userToken = localStorage.getItem("usertoken");
    getToken(userToken, (cb) => {
      detail(cb.id, (result) => {
        setUser({
          ...User,
          username: result.username,
          tenant: result.Tenant.name,
        });
      });
    });
  }, []);
  const toggleSearchBar = useCallback(() => {
    setShowSearchBar((prevShowSearchBar) => !prevShowSearchBar);
  }, []);

  const updateSuggestions = useCallback((searchText) => {
    // In a real app, replace this with a debounced API call
    if (searchText.length > 0) {
      const allSuggestions = [
        {
          name: "Dashboard",
          link: "dashboard",
          description: "View your dashboard",
        },
        {
          name: "Akun Perkiraan",
          link: "/accounts",
          description: "Manage your acount transactions",
        },
        {
          name: "Akun Perkiraan",
          link: "/accounts",
          description: "Manage your accounts",
        },
        {
          name: "Karyawan",
          link: "/employees",
          description: "Manage your employee",
        },
        {
          name: "Gaji / Tunjangan",
          link: "/salaries",
          description: "Manage your salarie",
        },
        {
          name: "Pelanggan",
          link: "/customers",
          description: "Manage your customers",
        },
        {
          name: "Produk",
          link: "/products",
          description: "Manage your products",
        },
        {
          name: "Faktur Penjualan",
          link: "/invoices",
          description: "Manage your sales",
        },
        {
          name: "Jurnal Umum",
          link: "/journals",
          description: "Manage your trasactions",
        },
        {
          name: "Pembayaran",
          link: "/banks/payments",
          description: "Manage your payments",
        },
        {
          name: "Penerimaan",
          link: "/banks/receipts",
          description: "Manage your receipts",
        },
        {
          name: "Pencatatan Gaji",
          link: "/payrolls",
          description: "Manage your payrolls",
        },
        {
          name: "Pencatatan Beban",
          link: "/expenses",
          description: "Manage your expenses",
        },
        {
          name: "Laporan Laba/Rugi",
          link: "/profit/reports",
          description: "Manage your profit reports",
        },
        {
          name: "Laporan Neraca Keuangan",
          link: "/scales/reports",
          description: "Manage your scale reports",
        },
      ];

      const filteredSuggestions = allSuggestions.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setIsSearchEmpty(filteredSuggestions.length === 0);
    } else {
      setSuggestions([]);
      setIsSearchEmpty(false);
    }
  }, []);

  const handleSearchChange = useCallback(
    (e) => {
      const value = e.target.value;
      setQuery(value);
      updateSuggestions(value);
    },
    [updateSuggestions]
  );
  const logoutHandler = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are You Sure ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (loginStatus) {
          localStorage.clear();
          setLoading(true);
          setTimeout(() => {
            loginHandler(false);
            navigation("/login");
          }, 1200);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Logout Successful !",
            text: "Successfully Logout !",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          loginHandler(true);
        }
      }
    });
  };
  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      {/* Logo */}
      <div className="d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center">
          <img src={LogoS} alt="" />
          <span className="d-none d-lg-block">Rana BLUE</span>
        </a>
        <List className="toggle-sidebar-btn" onClick={handleToggleSidebar} />
      </div>

      {/* Search Bar */}
      <div className={`search-bar ${showSearchBar ? "search-bar-show" : ""}`}>
        <form
          className="search-form d-flex align-items-center"
          method="POST"
          action="#"
        >
          <input
            type="text"
            className="search-input"
            value={query}
            onChange={handleSearchChange}
            placeholder="Type to search..."
            aria-label="Search"
          />
          <button type="submit" title="Search">
            <Search />
          </button>
          <div className="suggestions">
            {suggestions.length > 0 ? (
              <ul className="results">
                {suggestions.map((suggestion, index) => (
                  <li key={index} className="suggestion-item">
                    <Link
                      to={suggestion.link}
                      className="suggestion-link"
                      onClick={() => {
                        setQuery("");
                        setSuggestions([]);
                      }}
                    >
                      {suggestion.name}
                      <br />
                      <span>{suggestion.description}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              isSearchEmpty && (
                <ul className="results">
                  <li className="suggestion-item">
                    <span>Tidak ada hasil yang ditemukan</span>
                  </li>
                </ul>
              )
            )}
          </div>
        </form>
      </div>

      {/* Icons Navigation */}
      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          {/* Search Icon */}
          <li className="nav-item d-block d-lg-none">
            <a
              className="nav-link nav-icon search-bar-toggle "
              href="#"
              onClick={toggleSearchBar}
            >
              <Search />
            </a>
          </li>
          <Dropdown className="nav-item dropdown pe-3">
            <Dropdown.Toggle className="nav-link nav-profile d-flex align-items-center pe-0">
              <img
                src="https://via.placeholder.com/150"
                alt="Profile"
                className="rounded-circle"
              />
              <span className="d-none d-md-block ps-2">{User.username}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu dropdown-menu-end dropdown-menu-arrow-right profile">
              <Dropdown.Item className="dropdown-header">
                <h6>{User.username}</h6>
                <span>{User.tenant}</span>
              </Dropdown.Item>
              <Dropdown.Item>
                <hr className="dropdown-divider" />
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-3"
                className="dropdown-item d-flex align-items-center"
              >
                <i>
                  <Person />
                </i>
                <span>My Profile</span>
              </Dropdown.Item>
              <Dropdown.Item>
                <hr className="dropdown-divider" />
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-4"
                className="dropdown-item d-flex align-items-center"
              >
                <i>
                  <Gear />
                </i>
                <span>Account Settings</span>
              </Dropdown.Item>
              <Dropdown.Item>
                <hr className="dropdown-divider" />
              </Dropdown.Item>{" "}
              <Dropdown.Item
                onClick={logoutHandler}
                className="dropdown-item d-flex align-items-center"
              >
                <i>
                  <BoxArrowRight />
                </i>
                <span>Sign Out</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
