import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Icons from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";
import { Bar, Line } from "react-chartjs-2";
import "chart.js/auto";
import { getCashFlow } from "../../../fetch/widgetFetch";
const formatDateToEnglish = (dateStr) => {
  const date = new Date(dateStr);
  const formatter = new Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "short",
  });
  return formatter.format(date);
};

const Cash = (props) => {
  const { isMoveable, setIsMoveable } = props;
  const [data, setData] = useState({
    Tanggal: new Date(),
    Nilai: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [period, setPeriod] = useState("daily");
  const [chartData, setChartData] = useState(null);
  const [lastHoveredDatasetIndex, setLastHoveredDatasetIndex] = useState(null);
  const [lastHoveredIndex, setLastHoveredIndex] = useState(null);
  useEffect(() => {
    if (period) {
      getCashFlow({ period: period }, handleCashFlowResult);
    }
  }, [period]);
  const fetchDataCash = (data) => {
    setIsLoading(true);
    setPeriod(data);
  };
  const handleCashFlowResult = (result) => {
    console.log(result);
    if (result.error) {
      console.error("Terjadi kesalahan: ", result.error);
      // Set error state here if needed
    } else {
      const chartData = transformDataToMixChartData(result.cashFlowPerPeriod);
      setChartData(chartData);
    }
    setIsLoading(false);
  };

  const transformCashFlowDates = (cashFlowData) => {
    const transformedData = {};

    Object.keys(cashFlowData).forEach((dateKey) => {
      const formattedDate = formatDateToEnglish(dateKey);
      transformedData[formattedDate] = cashFlowData[dateKey];
    });

    return transformedData;
  };

  // Fungsi untuk mengubah data menjadi format chart
  const transformDataToMixChartData = (data) => {
    const transformedData = transformCashFlowDates(data);
    const labels = Object.keys(transformedData);

    // Inisialisasi array untuk menyimpan warna bar
    const backgroundColors = labels.map((label) => {
      // Jika total kurang dari 0, warna merah, jika tidak, warna biru
      return transformedData[label].total < 0
        ? "rgba(203, 16, 9, 0.91)"
        : "rgba(0, 309, 0, 0.8)";
    });

    const barData = labels.map((label) => transformedData[label].total);
    const lineData = labels.map((label) => transformedData[label].total);

    return {
      labels,
      datasets: [
        {
          type: "bar",
          label: "Rata-Rata",
          data: barData,
          backgroundColor: backgroundColors, // Gunakan array warna yang sesuai dengan nilai data
          borderWidth: 0.4,
          order: 2,
        },
        {
          type: "line",
          label: "Rata-Rata",
          data: lineData,
          borderColor: "rgba(65, 84, 241, 1)",
          borderWidth: 4,
          pointBackgroundColor: "rgba(0, 123, 255, 1)", // Set this to the color you want for the points
          pointBorderColor: "rgba(0, 123, 255, 1)", // Make sure this matches the background color if you don't want a border
          pointBorderWidth: 0, // Set this to 0 to remove the border
          pointRadius: 4,
          fill: false,
          tension: 0.4,
          order: 1,
        },
      ],
    };
  };

  const handleRefresh = () => {
    setIsLoading(true);

    setTimeout(() => {
      getCashFlow({ period: period }, handleCashFlowResult);
    }, 1200);
  };

  const toggleMoveable = () => {
    setIsMoveable(!isMoveable);
  };
  const changeColorOpacity = (color, opacity) => {
    if (typeof color === "string" && color.startsWith("rgba")) {
      return color.replace(/[\d\.]+\)$/g, `${opacity})`);
    }
    return color; // Return the original color if it's not an rgba string
  };

  const changeDatasetOpacity = (datasets, opacity, exceptIndex = null) => {
    return datasets.map((dataset, index) => {
      if (index === exceptIndex) {
        return dataset; // Skip the dataset that is being hovered over
      }

      const backgroundColor = Array.isArray(dataset.backgroundColor)
        ? dataset.backgroundColor.map((color) =>
            changeColorOpacity(color, opacity)
          )
        : changeColorOpacity(dataset.backgroundColor, opacity);

      return {
        ...dataset,
        backgroundColor,
      };
    });
  };
  // Function to set low opacity for non-hovered elements
  const setLowOpacity = (chart, hoverDatasetIndex, hoverIndex) => {
    chart.data.datasets = chart.data.datasets.map((dataset, index) => {
      if (index === hoverDatasetIndex) {
        // For the hovered dataset, change only the non-hovered elements
        const backgroundColor = Array.isArray(dataset.backgroundColor)
          ? dataset.backgroundColor.map((color, i) =>
              i === hoverIndex ? color : changeColorOpacity(color, "0.2")
            )
          : changeColorOpacity(dataset.backgroundColor, "0.2");

        // Additional handling for line chart properties
        const borderColor =
          dataset.type === "line"
            ? changeColorOpacity(dataset.borderColor, "1.0") // Keep line fully opaque
            : dataset.borderColor;

        return { ...dataset, backgroundColor, borderColor };
      } else {
        // For non-hovered datasets, dim all elements
        const backgroundColor = Array.isArray(dataset.backgroundColor)
          ? dataset.backgroundColor.map((color) =>
              changeColorOpacity(color, "0.2")
            )
          : changeColorOpacity(dataset.backgroundColor, "0.2");

        const borderColor =
          dataset.type === "line"
            ? changeColorOpacity(dataset.borderColor, "0.2")
            : dataset.borderColor;

        return { ...dataset, backgroundColor, borderColor };
      }
    });

    chart.update();
  };

  // Function to reset opacity for all elements
  const resetOpacity = (chart) => {
    chart.data.datasets = chart.data.datasets.map((dataset) => {
      // Reset background color
      const backgroundColor = Array.isArray(dataset.backgroundColor)
        ? dataset.backgroundColor.map((color) =>
            changeColorOpacity(color, "1.0")
          )
        : changeColorOpacity(dataset.backgroundColor, "1.0");

      // Reset border color for line datasets
      const borderColor =
        dataset.type === "line"
          ? changeColorOpacity(dataset.borderColor, "1.0")
          : dataset.borderColor;

      // Reset point properties for line datasets
      const pointBackgroundColor = dataset.pointBackgroundColor
        ? changeColorOpacity(dataset.pointBackgroundColor, "1.0")
        : undefined;
      const pointBorderColor = dataset.pointBorderColor
        ? changeColorOpacity(dataset.pointBorderColor, "1.0")
        : undefined;

      return {
        ...dataset,
        backgroundColor,
        borderColor,
        ...(pointBackgroundColor && { pointBackgroundColor }),
        ...(pointBorderColor && { pointBorderColor }),
      };
    });

    chart.update();
  };

  // In your component

  const handleHover = (event, chartElements, chart) => {
    if (chartElements.length > 0) {
      const hoverElement = chartElements[0];
      if (
        lastHoveredDatasetIndex !== hoverElement.datasetIndex ||
        lastHoveredIndex !== hoverElement.index
      ) {
        setLowOpacity(chart, hoverElement.datasetIndex, hoverElement.index);
        chart.update();
        setLastHoveredDatasetIndex(hoverElement.datasetIndex);
        setLastHoveredIndex(hoverElement.index);
      }
    } else if (lastHoveredDatasetIndex !== null || lastHoveredIndex !== null) {
      resetOpacity(chart);
      chart.update();
      setLastHoveredDatasetIndex(null);
      setLastHoveredIndex(null);
    }
  };

  return (
    <div className="card">
      <div className="filter">
        <Link onClick={handleRefresh} className="refresh-button">
          <Icons.ArrowClockwise className="icon-refresh" />
        </Link>
        <Dropdown>
          <Dropdown.Toggle
            id={`dropdown-widget-button-drop`}
            size="sm"
            className="icon"
          >
            <Icons.ThreeDots className="icon-three" />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="end"
            className="dropdown-menu dropdown-menu-end dropdown-menu-arrow-right"
          >
            <Dropdown.Item className="dropdown-item" onClick={toggleMoveable}>
              <Icons.ArrowsMove size={14} className="me-2" />
              Move
            </Dropdown.Item>
            <Dropdown.Item
              className="dropdown-item"
              onClick={() => {
                fetchDataCash("daily");
              }}
            >
              <Icons.Calendar2Plus size={14} className="me-2" />
              Perhari
            </Dropdown.Item>
            <Dropdown.Item
              className="dropdown-item"
              onClick={() => {
                fetchDataCash("monthly");
              }}
            >
              <Icons.Calendar2Minus size={14} className="me-2" />
              Perbulan
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="card-body">
        <h5 className="card-title">Arus Kas/Bank</h5>
        <div className="activity-section">
          {isLoading ? (
            <div className="spinner-main-widget">
              <div className="loader-widget">
                <div className="bounce1-widget"></div>
                <div className="bounce2-widget"></div>
                <div className="bounce3-widget"></div>
              </div>
              <p>Memuat Data...</p>
            </div>
          ) : chartData ? (
            <>
              <div
                style={{ width: "550px", height: "200px", margin: "0 auto" }}
              >
                <Bar
                  data={chartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        barThickness: 20, // Adjust as needed
                        maxBarThickness: 25,
                        categoryPercentage: 0.8,
                        barPercentage: 0.8,
                      },
                      y: {
                        stacked: false, // Turn off stacking for the y-axis
                      },
                    },
                    onHover: handleHover,
                    plugins: {
                      legend: {
                        labels: {
                          filter: function (legendItem, data) {
                            if (legendItem.text === "Rata-Rata") {
                              return false;
                            }
                            return true;
                          },
                        },
                      },
                      tooltip: {
                        displayColors: false,
                        callbacks: {
                          title: function (context) {
                            // Check if the hovered item is from a bar dataset
                            const datasetIndex = context[0].datasetIndex;
                            const datasetType =
                              context[0].chart.data.datasets[datasetIndex].type;
                            if (datasetType === "bar") {
                              const value = context[0].parsed.y;
                              const label =
                                value < 0 ? "Uang Keluar" : "Uang Masuk";
                              return `${label}`;
                            } else {
                              // Return nothing or an empty string for other dataset types
                              return "Rata-Rata";
                            }
                          },
                          label: function (tooltipItem) {
                            return `${tooltipItem.label}: ${tooltipItem.formattedValue}`;
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </>
          ) : (
            <div>Data tidak tersedia atau terjadi kesalahan</div> // Tampilkan pesan ini jika tidak ada data
          )}
        </div>
      </div>
    </div>
  );
};

export default Cash;
