import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AgGridReact } from "ag-grid-react";
import { getToken } from "../../fetch/userFetch";
import {
  deleteAccount,
  getAccounts,
  updateAccount,
} from "../../fetch/accountFetch";

import * as Icons from "react-bootstrap-icons";
import { HiDotsHorizontal } from "react-icons/hi";

import "./style.css";
import Swal from "sweetalert2";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
const currencyFormatter = (params) => {
  if (params.value == null || isNaN(params.value)) {
    return "";
  }
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(params.value);
};
const Accounts = (props) => {
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);

  const [rowData, setRowData] = useState([
    {
      No: 0,
      Nama: "",
      Tipe: "",
      Saldo: "",
      "Kode Perkiraan": "",
      Currency: "",
      Status: "",
    },
  ]);
  const [columnDefs] = useState([
    {
      field: "No",
      checkboxSelection: true,
      sortable: true,
      headerCheckboxSelection: true,
      width: 150,
    },
    {
      field: "Kode Perkiraan",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 200,
    },
    {
      field: "Nama",
      sortable: true,
      filter: true,
      width: 250,
      editable: true,
    },
    {
      field: "Tipe",
      filter: "agSetColumnFilter",
      editable: true,
      sortable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        cellHeight: 50,
        values: [
          "Kas",
          "Bank",
          "Piutang Usaha",
          "Persediaan",
          "Aset Lancar Lainnya",
          "Aset Tetap",
          "Akumulasi Penyusutan",
          "Aset Lainnya",
          "Hutang Usaha",
          "Liabilitas Jangka Pendek",
          "Liabilitas Jangka Panjang",
          "Modal",
          "Pendapatan",
          "Beban Pokok Penjualan",
          "Beban",
          "Beban Lainnya",
          "Pendapatan Lainnya",
        ],
      },
      width: 250,
    },
    {
      field: "Saldo",
      filter: "agNumberColumnFilter",
      sortable: true,
      editable: true,
      valueFormatter: currencyFormatter,
      width: 275,
    },
    {
      field: "Currency",
      filter: "agSetColumnFilter",
      sortable: true,
      editable: false,
      width: 275,
      hide: true,
    },
    {
      field: "Status",
      filter: "agSetColumnFilter",
      sortable: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        cellHeight: 50,
        values: ["Aktif", "Tidak Aktif", "Review", "Closed"],
        width: 275,
      },
      hide: true,
    },
  ]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { accountId, setAccountId } = props;
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("accountId");
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (cb) => {
        if (cb && cb.id) {
          getAccounts(cb.id, (result) => {
            if (result && Array.isArray(result)) {
              const formattedData = result.map((account, idx) => ({
                id: account.id,
                No: idx + 1 || 0,
                "Kode Perkiraan": account.AccountNumber || "",
                Nama: account.AccountName || "",
                Tipe: account.AccountType || "",
                Saldo: account.Balance || "",
                Currency: account.Currency || "",
                Status: account.Status || "",
              }));
              setRowData(formattedData);
              setReady(formattedData.length > 0);
              setLoading(false);
            } else {
              setReady(false);
            }
          });
        }
      });
    }
  }, []);

  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
  };
  const handleBulkDelete = useCallback(() => {
    const selectedIds = selectedRows.map((row) => row.id);

    if (selectedRows.length === 0) {
      alert("Tidak ada baris yang dipilih!");
      return;
    }
    Swal.fire({
      title: "Are you sure to delete ?",
      text: "Are You Sure ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteAccount(selectedIds, (result) => {
            if (result.status === 204 || result.status === 200) {
              const newRows = rowData.filter(
                (row) => !selectedIds.includes(row.id)
              );
              setRowData(newRows);
              setSelectedRows([]);
            }
          });
        } catch (error) {
          const errorMsg =
            error.message || "Terjadi kesalahan saat menghapus akun.";
        }
      }
    });
  }, [selectedRows, rowData]);

  const handleNew = () => {
    setAccountId(0);
    navigate("/accounts/add");
  };
  const handleCellValueChanged = (event) => {
    const updatedData = {
      id: event.data.id,
      AccountName: event.data.Nama,
      AccountType: event.data.Tipe,
      Balance: event.data.Balance,
    };
    updateAccount(updatedData);
  };
  const handleEdit = () => {
    const selectedIds = selectedRows.map((row) => row.id);

    if (selectedRows.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Tidak Ada Baris Yang Dipilih !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    if (selectedRows.length > 1) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Hanya Satu yang bisa Diedit !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    Swal.fire({
      title: "Are you sure to Edited This ?",
      text: "Are You Sure ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setAccountId(selectedIds[0]);
        navigate("/accounts/add");
      }
    });
  };
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Kas & Bank</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Kas & Bank</li>
                </ol>
              </nav>
            </div>
            <div className="action-button">
              <Link
                className="btn btn-sm btn-primary mx-3"
                onClick={(e) => {
                  handleNew();
                  e.preventDefault();
                }}
              >
                <Icons.PlusLg className="me-1" /> Baru
              </Link>
              <DropdownButton
                as={ButtonGroup}
                id={`dropdown-button-drop`}
                size="sm"
                title={<HiDotsHorizontal color="black" size="18px" />}
                className="no-chevron-dropdown"
              >
                <Dropdown.Item onClick={handleEdit}>
                  <Icons.Pencil /> Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={handleBulkDelete}>
                  <Icons.Trash /> Delete
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>

          {ready ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{ width: "100%", height: "100%" }}
                className="ag-theme-material"
              >
                <AgGridReact
                  rowData={rowData}
                  rowSelection={"multiple"}
                  animateRows={true}
                  columnDefs={columnDefs}
                  rowDragManaged={true}
                  rowDragMultiRow={true}
                  onSelectionChanged={onSelectionChanged}
                  cacheBlockSize={10}
                  pagination={true}
                  filter={true}
                  flex={1}
                  domLayout="autoHeight"
                  paginationPageSize={10}
                  minWidth={100}
                  onCellValueChanged={handleCellValueChanged}
                ></AgGridReact>
              </div>
            </div>
          ) : (
            <div
              style={{
                padding: "20px",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              <h5
                style={{
                  fontSize: "1h5x",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Akun Perkiraan Tidak Ditemukan
              </h5>
              <small className="text-muted my-5">
                Buat Akun Perkiraan Terlebih Dahulu Untuk Melakukan Traksasi
              </small>
              <br></br>
              <button className=" mt-5 btn btn-primary" onClick={handleNew}>
                Buat Akun Perkiraan Baru
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Accounts;
