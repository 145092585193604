import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Icons from "react-bootstrap-icons";
import { getToken } from "../../../../fetch/userFetch";
import Swal from "sweetalert2";
import { AgGridReact } from "ag-grid-react";
import queryString from "query-string";
import {
  addPayBank,
  detailPayBank,
  updatePaid,
  updatePayBanks,
} from "../../../../fetch/bankFetch";
import {
  detailAccount,
  getAccountBank,
  getAccountPayBank,
} from "../../../../fetch/accountFetch";
import { getPayJournal } from "../../../../fetch/journalFetch";
const currencyFormatter = (params) => {
  if (params.value == null || isNaN(params.value)) {
    return "";
  }
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(params.value);
};
const formatDateToIndonesian = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("id-ID", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
const PayBankForm = () => {
  const [loading, setLoading] = useState(true);
  const [editable, setEditable] = useState(false);
  const [pay, setPay] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const [bank, setBank] = useState({});
  const [accountsList, setAccountsList] = useState({});
  const [valid, setValid] = useState({
    isAccount: true,
    isDate: true,
    isBank: true,
  });
  const [data, setData] = useState({
    accountId: null,
    bankId: null,
    paymentDate: new Date(),
    accounts: [],
  });
  const [gridOptions] = useState({
    overlayNoRowsTemplate:
      "<span>Sesuiakan Data yang Akan Dimasukan Disini.Klik Dua Kali Di Kolom Yang Ingin Diubah</span>",
    // ... properti gridOptions lainnya
  });

  const [rowData, setRowData] = useState([]);
  const [columnDefs] = useState([
    {
      field: "",
      rowDrag: true,
      width: 50,
    },
    {
      field: "Kode",
      filter: true,
      sortable: true,
      width: 250,
    },
    {
      field: "Nama",
      sortable: true,
      width: 250,
    },
    {
      field: "Metode Pembayaran*",
      sortable: true,
      editable: true,
      headerTooltip: "Klik dua kali pada kolom untuk mengedit",
      width: 150,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Tunai", "Kartu Kredit", "Transfer Bank"],
      },
      cellRenderer: (params) => {
        return params.value;
      },
    },
    {
      field: "Nilai",
      headerName: "Nilai*",
      filter: true,
      headerTooltip: "Klik dua kali pada kolom untuk mengedit",
      sortable: true,
      editable: true,
      valueFormatter: currencyFormatter,
      width: 300,
    },
    {
      field: "id",
      headerName: "Hapus",
      cellRenderer: (params) => (
        <Link
          className="btn btn-sm "
          onClick={() => handleRemoveRow(params.data.uniqueKey)}
          style={{
            marginLeft: "20px",
            cursor: "pointer",
          }}
        >
          <Icons.XCircle size={20} color="red" />
        </Link>
      ),
      width: 120,
    },
  ]);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const accountBankId = queryParams.accountBankId;
  const numberTransaction = queryParams.number;
  const navigation = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    getBank();
    getAccountsList();
    if (numberTransaction) {
      getPayTransaction(numberTransaction);
    }
    if (accountBankId) {
      localStorage.setItem("accountBankId", accountBankId);
    }
    const storedProductId = localStorage.getItem("accountBankId");

    if (storedProductId) {
      getOnePayBank(storedProductId);
      setEditable(true);
    }
    const handleBeforeUnload = (e) => {
      if (isFormModified) {
        const message =
          "Perubahan belum disimpan. Anda yakin ingin meninggalkan halaman ini?";
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const getOnePayBank = (id) => {
    detailPayBank(id, (result) => {
      if (result) {
        const payment = result;
        console.log(payment);
        setData({
          bankId: payment.AccountId,
          paymentDate: new Date(payment.PaymentDate),
        });
        const newRows = payment.BankPayments.map((BankPayment, index) => ({
          uniqueKey: Date.now() + index,
          id: BankPayment.Account.id,
          Nama: BankPayment.Account.AccountName,
          Kode: BankPayment.Account.AccountNumber,
          "Metode Pembayaran*": BankPayment.PaymentMethod,
          Nilai: BankPayment.Amount,
        }));
        console.log(newRows);
        // getInvoice(payment.InvoicePayments[0].CustomerId);
        setRowData(newRows);
      }
    });
  };
  const getPayTransaction = (number) => {
    setPay(true);
    getPayJournal(number, (result) => {
      console.log(result);

      const newPay = result.map((acc, index) => ({
        uniqueKey: Date.now() + index,
        id: acc.Account.id,
        Nama: acc.Account.AccountName,
        Kode: acc.Account.AccountNumber,
        "Metode Pembayaran*": "Tunai",
        Nilai: acc.Credit,
      }));

      setRowData(newPay); // Menggabungkan newPay dengan rowData yang ada
    });
  };

  const getBank = () => {
    const userToken = localStorage.getItem("usertoken");
    if (!userToken) {
      console.log("No user token found.");
      // Set default state or perform other error handling
      return;
    }

    getToken(userToken, (result) => {
      if (!result || !result.id) {
        console.log("Invalid token or user ID not found in token.");
        // Set default state or perform other error handling
        return;
      }

      getAccountBank(result.id, (cb) => {
        if (!cb) {
          console.log("Failed to fetch bank account information.");
          // Set default state or perform other error handling
          return;
        }
        setBank(cb);
      });
    });
  };

  const getAccountsList = () => {
    const userToken = localStorage.getItem("usertoken");
    if (!userToken) {
      console.log("No user token found.");
      // Set default state or perform other error handling
      return;
    }

    getToken(userToken, async (result) => {
      if (!result || !result.id) {
        console.log("Invalid token or user ID not found in token.");
        // Set default state or perform other error handling
        return;
      }

      try {
        await getAccountPayBank(result.id, (cb) => {
          if (!cb) {
            console.log("Failed to fetch accounts list.");
            // Set default state or perform other error handling
            return;
          }
          setAccountsList(cb);
        });
      } catch (error) {
        console.error("Error fetching accounts list:", error);
        // Set default state or perform other error handling
      }
    });
  };
  const handleBank = (selectedOption) => {
    setData({
      ...data,
      bankId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isBank: selectedOption,
    });
    setIsFormModified(true);
  };
  const handleDateChange = (date) => {
    setData({
      ...data,
      paymentDate: date,
    });
    setIsFormModified(true);
  };
  const handleAccounts = (selectedOption) => {
    setData({
      ...data,
      accountId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isAccount: selectedOption,
    });
    setIsFormModified(true);
    if (selectedOption) {
      getDetailAccount(selectedOption.value);
    }
  };
  const getDetailAccount = (value) => {
    detailAccount(value, (cb) => {
      console.log(cb.AccountNumber);

      const isDuplicate = rowData.some((row) => row.Kode === cb.AccountNumber);
      if (isDuplicate) {
        // Tampilkan pesan kesalahan
        Swal.fire({
          icon: "error",
          title: "Duplikat Akun Perkiraan",
          text: "Hanya satu akun perkiraan dengan kode tertentu yang diizinkan.",
        });
        return; // Jangan tambahkan akun ke rowData
      }
      const newProduct = {
        uniqueKey: Date.now(),
        id: cb.id,
        "Metode Pembayaran*": "Tunai",
        Nama: cb.AccountName,
        Kode: cb.AccountNumber,
        Nilai: 0,
      };
      setRowData([...rowData, newProduct]);
    });
  };
  const handleCellValueChanged = (event) => {
    const updatedData = rowData.map((row) => {
      if (row.uniqueKey === event.data.uniqueKey) {
        // Jika Kode cocok, perbarui data sesuai perubahan
        return {
          ...row,
          Nama: event.data.Nama,
          Kuantitas: event.data.Kuantitas,
          Harga: event.data.Harga,
          "Total Harga": event.data.Harga * event.data.Kuantitas,
        };
      }
      return row; // Jika Kode tidak cocok, biarkan data tetap seperti sebelumnya
    });

    setRowData(updatedData); // Perbarui state dengan data yang diperbarui
    setIsFormModified(true);
  };
  const handleRemoveRow = (uniqueKey) => {
    // Pastikan Anda memanggil setRowData dengan fungsi updater
    // untuk mendapatkan state terkini dari rowData
    setRowData((currentRowData) => {
      const updatedData = currentRowData.filter(
        (row) => row.uniqueKey !== uniqueKey
      );
      return updatedData; // Return the filtered array
    });
  };
  const cancelSubmit = (e) => {
    e.preventDefault();
    setIsFormModified(false);
    navigation("/banks/payments");
  };
  const addForm = (e) => {
    e.preventDefault();
    const { paymentDate, bankId } = data;
    setLoadingButton(true);
    setIsFormModified(false);
    const isDataValid = validateData();
    if (!isDataValid) {
      setLoadingButton(false);
      return;
    }
    const payload = {
      paymentDate: paymentDate.toISOString().split("T")[0],
      accountId: bankId.toString(),
      accounts: rowData.map((row) => ({
        AccountId: row.id,
        PaymentMethod: row["Metode Pembayaran*"].toString(),
        Amount: row.Nilai.toString(),
      })),
    };
    const totalAmount = rowData.reduce(
      (sum, row) => sum + parseFloat(row.Nilai),
      0
    );
    setTimeout(async () => {
      const result = await addPayBank(payload);
      if (result) {
        setLoadingButton(false);
        navigation("/banks/payments");
        if (numberTransaction) {
          const paidload = {
            paymentNumber: result.payment.PaymentNumber,
            numberTransaction: numberTransaction,
            paidAmount: totalAmount.toString(),
          };
          await updatePaid(paidload);
        }
      } else {
        setLoadingButton(false);
      }
    }, 3000);
  };

  const editForm = (e) => {
    e.preventDefault();
    console.log(e);
    const { paymentDate, bankId } = data;
    setLoadingButton(true);
    setIsFormModified(false);
    const isDataValid = validateData();
    if (!isDataValid) {
      setLoadingButton(false);
      return;
    }
    const paymentId = localStorage.getItem("accountBankId");
    const payload = {
      paymentId: paymentId,
      paymentDate: paymentDate,
      accountId: bankId,
      accounts: rowData.map((row) => ({
        AccountId: row.id,
        PaymentMethod: row["Metode Pembayaran"],
        Amount: parseFloat(row.Nilai),
      })),
    };
    setTimeout(async () => {
      const pay = await updatePayBanks(payload);
      if (pay) {
        setLoadingButton(false);
        navigation("/banks/payments");
      } else {
        setLoadingButton(false);
      }
    }, 3000);
  };
  const validateData = () => {
    let errorMessage = "";

    let isValid = true;

    // Contoh validasi: cek apakah setiap row memiliki item yang tidak kosong
    for (const row of rowData) {
      if (!row.Nilai) {
        isValid = false;
        errorMessage =
          "Setiap Akun Perkiraan harus memiliki nilai untuk di Debit.";
        break;
      }
    }
    if (rowData.length === 0) {
      isValid = false;
      errorMessage = "Akun Perkiraan tidak boleh kosong .";
    }

    if (!isValid) {
      // Tampilkan pesan kesalahan atau lakukan tindakan validasi lainnya
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Validasi Gagal",
        text: errorMessage,
        showConfirmButton: false,
        timer: 1500,
      });
    }

    return isValid;
  };
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Pembayaran</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/invoices">Pembayaran</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {editable ? "Edit" : "Add"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="formPayBank col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {editable
                    ? "Edit Pembayaran Kas & Bank"
                    : "Pembayaran Kas & Bank Baru"}
                </h5>
                <hr />
                <form id="formPayBank" onSubmit={editable ? editForm : addForm}>
                  <div className="row mb-3">
                    <label
                      htmlFor="payBankSelect"
                      className="col-sm-2 col-form-label"
                    >
                      Kas & Bank<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        id="payBankSelect"
                        key={data.bankId || "no-value"}
                        options={bank.map((custom) => ({
                          label: custom.AccountName,
                          value: custom.id,
                        }))}
                        value={
                          data.bankId
                            ? {
                                label: bank.find(
                                  (custom) => custom.id === data.bankId
                                )?.AccountName,
                                value: data.bankId,
                              }
                            : null
                        }
                        onChange={handleBank}
                        className={
                          !valid.isBank
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Kas & Bank..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        required
                      />
                      {!valid.isBank && (
                        <div className="invalid-feedback">
                          Kas & Bank Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="payBankDate"
                      className="col-sm-2 col-form-label"
                    >
                      Tanggal<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <ReactDatePicker
                        id="payBankDate"
                        selected={data.paymentDate}
                        onChange={handleDateChange}
                        className="form-control"
                        dateFormat="dd/MM/yyyy" // Format date in the day/month/year format
                        placeholderText="Pilih Tanggal"
                        customInput={<input readOnly={editable} />}
                        required
                      />
                      {!valid.isDate && (
                        <div className="invalid-feedback">
                          Tanggal Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="payAccountSelect"
                      className="col-sm-2 col-form-label"
                    >
                      Akun Perkiraan<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        id="payAccountSelect"
                        key={data.accountId || "no-value"}
                        options={accountsList.map((account) => ({
                          label: account.AccountName,
                          value: account.id,
                        }))}
                        value={
                          data.accountId
                            ? {
                                label: accountsList.find(
                                  (account) => account.id === data.accountId
                                )?.AccountName,
                                value: data.accountId,
                              }
                            : null
                        }
                        onChange={handleAccounts}
                        className={
                          !valid.isAccount
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Akun Perkiraan..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        required={editable || pay ? false : true}
                      />
                      {!valid.isAccount && (
                        <div className="invalid-feedback">
                          Akun Perkiraan Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-5 mb-5">
                    <div
                      style={{ width: "100%", height: "100%", zIndex: "0" }}
                      className="ag-theme-material"
                    >
                      <AgGridReact
                        rowData={rowData}
                        rowSelection={"multiple"}
                        animateRows={true}
                        tooltipShowDelay={0}
                        columnDefs={columnDefs}
                        rowDragManaged={true}
                        rowDragMultiRow={true}
                        cacheBlockSize={10}
                        gridOptions={gridOptions}
                        onCellValueChanged={handleCellValueChanged}
                        domLayout="autoHeight"
                      ></AgGridReact>
                    </div>
                  </div>
                  <div className="row mb-3 ">
                    <span style={{ fontSize: 14, fontStyle: "italic" }}>
                      <span className="text-danger">*</span> Kolom harus diisi
                      dengan cara mengklik dua kali kolom
                    </span>
                  </div>
                  <div className="row mb-3 submit-button">
                    <div className="col-sm-10">
                      <button
                        className={`btn btn-sm me-3 btn-primary ${
                          loadingButton ? "" : "d-none"
                        }`}
                        type="button"
                        disabled={loadingButton}
                      >
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Menyimpan...
                      </button>
                      <button
                        type="submit"
                        className={`btn btn-sm btn-primary me-3 ${
                          loadingButton ? "d-none" : ""
                        }`}
                      >
                        Simpan
                      </button>
                      <Link
                        className="btn btn-sm  btn-secondary"
                        onClick={(e) => cancelSubmit(e)}
                      >
                        Batalkan
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PayBankForm;
