import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = apiDomain + "/journals";

const getJournals = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/",
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    handleError(error, "Mengambil Jurnal");
  }
};
const getInvoiceNumber = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/getInvoice/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    handleError(error, "Mengambil Nomor Faktur");
  }
};

const detailJournal = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/detail/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    handleError(error, "Detail Jurnal");
  }
};
const getPayJournal = async (number, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/journal/" + number,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    handleError(error, "Detail Jurnal");
  }
};

const addJournals = async (obj) => {
  console.log("Data yang dikirim : ", obj);

  try {
    let result = await axios({
      method: "POST",
      url: URL + "/add/",
      headers: { usertoken: localStorage.getItem("usertoken") },

      data: obj,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Ditambahkan",
      showConfirmButton: false,
      timer: 1500,
    });
    return true;
  } catch (error) {
    handleError(error, "Menambahkan Jurnal");
  }
};
const updateJournals = async (data) => {
  try {
    let result = await axios({
      method: "PUT",
      url: URL + "/update",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: data,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dirubah",
      showConfirmButton: false,
      timer: 1500,
    });
    return true;
  } catch (error) {
    handleError(error, "Memperbarui Jurnal");
  }
};

const deleteJournals = async (data, cb) => {
  try {
    let result = await axios({
      method: "DELETE",
      url: URL + "/deletes",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: { ids: data },
    });
    cb(result.data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dihapus",
      showConfirmButton: false,
      timer: 1500,
    });
    return true;
  } catch (error) {
    handleError(error, "Menghapus Jurnal");
  }
};
const handleError = (error, action) => {
  console.log(error);
  let errorTitle = `${action} Gagal`;
  let errorMessage = "Terjadi kesalahan tidak terduga.";

  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  } else if (error.message) {
    errorMessage = error.message;
  }

  Swal.fire({
    position: "center",
    icon: "error",
    title: errorTitle,
    text: errorMessage,
    showConfirmButton: false,
    timer: 2500,
  });
};
export {
  getJournals,
  getInvoiceNumber,
  updateJournals,
  deleteJournals,
  addJournals,
  detailJournal,
  getPayJournal,
};
