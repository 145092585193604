import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = apiDomain + "/accounts";

const getAccounts = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/details/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
const addAccount = async (id, obj) => {
  try {
    let result = await axios({
      method: "POST",
      url: URL + "/add/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },

      data: obj,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Ditambahkan",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
const updateAccount = async (data) => {
  try {
    let result = await axios({
      method: "PUT",
      url: URL + "/update/" + data.id,
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: data,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dirubah",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (err) {
    console.log(err);
    Swal.fire({
      position: "center",
      icon: "error",
      title: err.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

const deleteAccount = async (data, cb) => {
  try {
    let result = await axios({
      method: "DELETE",
      url: URL + "/delete",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: { ids: data },
    });
    cb(result.data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dihapus",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (err) {
    console.log(err);
    Swal.fire({
      position: "center",
      icon: "error",
      title: err.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
const detailAccount = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/account/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const getAccountBank = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/bank/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const getAccountPayBank = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/account/payment/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};
const getBalanceDashboard = async (data, cb) => {
  try {
    let result = await axios({
      method: "POST",
      url: URL + "/balances",
      headers: { usertoken: localStorage.getItem("usertoken") },
      data: data,
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const getRevenueDashboard = async (data, cb) => {
  try {
    let result = await axios({
      method: "POST",
      url: URL + "/revenues",
      headers: { usertoken: localStorage.getItem("usertoken") },
      data: data,
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};
const endYearCloseBooks = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/close",
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

export {
  getAccounts,
  updateAccount,
  deleteAccount,
  addAccount,
  detailAccount,
  getAccountBank,
  getAccountPayBank,
  getBalanceDashboard,
  getRevenueDashboard,
  endYearCloseBooks,
};
