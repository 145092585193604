import React, { useEffect, useRef, useState } from "react";
import { Responsive, WidthProvider, GridLayout } from "react-grid-layout";
import * as Icons from "react-bootstrap-icons";

import "./style.css";
import Activity from "../Widget/Activity";
import { Dropdown } from "react-bootstrap";
import { getToken } from "../../fetch/userFetch";
import { Link, useNavigate } from "react-router-dom";
import Profit from "../Widget/Profit";
import Cash from "../Widget/Cash";
import {
  getAccountBank,
  getBalanceDashboard,
  getRevenueDashboard,
} from "../../fetch/accountFetch";
import { comparisonSales } from "../../fetch/invoiceFetch";
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}
const formatCurrency = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
};

const Dashboard = (props) => {
  const { loginStatus } = props;
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [isMoveable, setIsMoveable] = useState(false);
  const cursorStyle = isMoveable ? { cursor: "move" } : {};
  const [saldo, setSaldo] = useState({});
  const [sales, setSales] = useState({});
  const [revenue, setRevenue] = useState({});
  const [width] = useWindowSize();
  const balanceClass = saldo.Balance < 0 ? "text-danger" : "";
  const saleClass =
    sales.change < 0
      ? "text-danger small pt-1 fw-bold"
      : "text-success small pt-1 fw-bold";
  const revenueClass =
    revenue.percentageChange < 0
      ? "text-danger small pt-1 fw-bold"
      : "text-success small pt-1 fw-bold";

  const navigation = useNavigate();
  useEffect(() => {
    if (!loginStatus) {
      navigation("/login");
    }else{
      navigation('/dashboard')
    }
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (cb) => {
        setUsername(cb.username);
      });
      getBalance("Bank");
      getSales("day");
      getRevenue("day");
    }
    return () => clearTimeout(timer);
  }, [loginStatus, navigation]);

  const getBalance = (data) => {
    getBalanceDashboard({ accountType: data }, (cb) => {
      setSaldo(cb);
    });
  };
  const getSales = (data) => {
    comparisonSales({ period: data }, (result) => {
      setSales(result);
    });
  };
  const getRevenue = (data) => {
    getRevenueDashboard({ period: data }, (result) => {
      setRevenue(result);
    });
  };
  const layouts = {
    lg: [
      { i: "activity", x: 0, y: 0, w: 6, h: 2 },
      { i: "profit", x: 6, y: 0, w: 6, h: 2 },
      { i: "cash", x: 0, y: 2, w: 6, h: 2 },
    ],
    md: [
      { i: "activity", x: 0, y: 0, w: 5, h: 2 },
      { i: "profit", x: 5, y: 0, w: 5, h: 2 },
      { i: "cash", x: 0, y: 2, w: 5, h: 2 },
    ],
    sm: [
      { i: "activity", x: 0, y: 0, w: 3, h: 2 },
      { i: "cash", x: 0, y: 2, w: 6, h: 2 },
      { i: "profit", x: 3, y: 0, w: 3, h: 2 },
    ],
    xs: [
      { i: "activity", x: 0, y: 0, w: 2, h: 2 },
      { i: "cash", x: 0, y: 2, w: 6, h: 2 },
      { i: "profit", x: 2, y: 0, w: 2, h: 2 },
    ],
    xxs: [
      { i: "activity", x: 0, y: 0, w: 1, h: 2 },
      { i: "profit", x: 0, y: 0, w: 1, h: 2 },
      { i: "cash", x: 0, y: 2, w: 6, h: 2 },
    ],
  };

  const onDragStop = (layout, oldItem, newItem, placeholder, e, element) => {
    setIsMoveable(!isMoveable);
  };
  return (
    <>
      {!loading ? (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Dashboard</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="dashboard">
            <div className="row">
              <div className="col-xxl-4 col-md-6">
                <div className="card info-card revenue-card">
                  <div className="filter">
                    <Dropdown>
                      <Dropdown.Toggle
                        id={`dropdown-widget-button-drop`}
                        size="sm"
                        className="icon"
                      >
                        <Icons.ThreeDots className="icon-three" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        align="end"
                        className="dropdown-menu dropdown-menu-end dropdown-menu-arrow-right"
                      >
                        <Dropdown.Item
                          className="dropdown-item"
                          onClick={() => {
                            getBalance("Bank");
                          }}
                        >
                          Bank
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown-item"
                          onClick={() => {
                            getBalance("Kas");
                          }}
                        >
                          Kas
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="card-body">
                    <h5 className="card-title">
                      Saldo <span>| {saldo.Type} </span>
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <Icons.Cash />
                      </div>
                      <div className="ps-3">
                        <h6 className={balanceClass}>
                          {formatCurrency(saldo.Balance)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-md-6">
                <div className="card info-card sales-card">
                  <div className="filter">
                    <Dropdown>
                      <Dropdown.Toggle
                        id={`dropdown-widget-button-drop`}
                        size="sm"
                        className="icon"
                      >
                        <Icons.ThreeDots className="icon-three" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        align="end"
                        className="dropdown-menu dropdown-menu-end dropdown-menu-arrow-right"
                      >
                        <Dropdown.Item
                          className="dropdown-item"
                          onClick={() => {
                            getSales("day");
                          }}
                        >
                          Hari Ini
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown-item"
                          onClick={() => {
                            getSales("month");
                          }}
                        >
                          Bulan Ini
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown-item"
                          onClick={() => {
                            getSales("year");
                          }}
                        >
                          Tahun Ini
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="card-body">
                    <h5 className="card-title">
                      Penjualan{" "}
                      <span>
                        |{" "}
                        {sales.period === "month"
                          ? "Bulan Ini"
                          : sales.period === "year"
                          ? "Tahun Ini"
                          : "Hari ini"}
                      </span>
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <Icons.Cart />
                      </div>
                      <div className="ps-3">
                        <h6>{sales.currentSales}</h6>
                        <span className={saleClass}>
                          {Math.abs(parseFloat(sales.change.replace(",", ".")))}
                          %
                        </span>
                        <span className="text-muted small pt-2 ps-1">
                          {sales.label}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-md-6">
                <div className="card info-card customers-card">
                  <div className="filter">
                    <Dropdown>
                      <Dropdown.Toggle
                        id={`dropdown-widget-button-drop`}
                        size="sm"
                        className="icon"
                      >
                        <Icons.ThreeDots className="icon-three" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        align="end"
                        className="dropdown-menu dropdown-menu-end dropdown-menu-arrow-right"
                      >
                        <Dropdown.Item
                          className="dropdown-item"
                          onClick={() => {
                            getRevenue("day");
                          }}
                        >
                          <Icons.ArrowsMove size={14} className="me-2" />
                          Today
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown-item"
                          onClick={() => {
                            getRevenue("month");
                          }}
                        >
                          <Icons.GraphUpArrow size={14} className="me-2" />
                          Bulan Ini
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown-item"
                          onClick={() => {
                            getRevenue("year");
                          }}
                        >
                          <Icons.GraphDown size={14} className="me-2" />
                          Tahun Ini
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="card-body">
                    <h5 className="card-title">
                      Pendapatan <span>| Today</span>
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <Icons.CurrencyDollar />
                      </div>
                      <div className="ps-3">
                        <h6>{formatCurrency(revenue.revenue)}</h6>
                        <span className={revenueClass}>
                          {Math.abs(
                            parseFloat(
                              revenue.percentageChange.replace(",", ".")
                            )
                          )}
                          %
                        </span>
                        <span className="text-muted small pt-2 ps-1">
                          {revenue.percentageChange < 0
                            ? "Penurunan"
                            : "Peningkatan"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Responsive
              className="layout"
              width={1200}
              height={width}
              layouts={layouts}
              isDraggable={isMoveable}
              isResizable={isMoveable}
              onDragStop={onDragStop}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            >
              <div key="activity" style={cursorStyle}>
                <Activity
                  username={username}
                  isMoveable={isMoveable}
                  setIsMoveable={setIsMoveable}
                />
              </div>
              <div key="profit" style={cursorStyle}>
                <Profit isMoveable={isMoveable} setIsMoveable={setIsMoveable} />
              </div>
              <div key="cash" style={cursorStyle}>
                <Cash isMoveable={isMoveable} setIsMoveable={setIsMoveable} />
              </div>
              {/* Tambahkan widget lain di sini jika ada */}
            </Responsive>
          </div>
        </>
      ) : (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      )}
    </>
  );
};

export default Dashboard;
