import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Document,
  Page,
  Text,
  View,
  pdf,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import * as Icons from "react-bootstrap-icons";
import { Tooltip } from "react-tooltip";
import { getScaleReport } from "../../../fetch/reportFetch";
const formatCurrency = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
};
const tanggalWaktuIndonesia = new Date().toLocaleString("id-ID", {
  day: "numeric",
  month: "long",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  timeZoneName: "short", // Menampilkan nama zona waktu
});
const ScaleReport = () => {
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState(null);
  const flatpickrRef = useRef();
  const currentDate = new Date(); // Tanggal saat ini
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1); // Tanggal 1 Januari tahun ini
  const [dateRange, setDateRange] = useState([startOfYear, currentDate]);
  const [zoomLevel, setZoomLevel] = useState(1); // State for zoom level

  // Function to increase zoom level
  const zoomIn = () => {
    setZoomLevel((prevZoomLevel) =>
      prevZoomLevel < 2 ? prevZoomLevel + 0.1 : prevZoomLevel
    );
  };

  // Function to decrease zoom level
  const zoomOut = () => {
    setZoomLevel((prevZoomLevel) =>
      prevZoomLevel > 1 ? prevZoomLevel - 0.1 : prevZoomLevel
    );
  };
  useEffect(() => {
    fetchReportData(dateRange); // Pastikan fungsi ini dipanggil
  }, []);
  const fetchReportData = async (date) => {
    setLoading(true);
    try {
      getScaleReport({ rangeDate: date }, (response) => {
        setReportData(response);
        setLoading(false); // Pindahkan setLoading ke sini setelah data di-set
      });
    } catch (error) {
      console.error("Error fetching report data:", error);
      setLoading(false); // Pastikan untuk menghentikan loading bila terjadi error
    }
  };
  const TableRow = ({
    leftText,
    rightText,
    isTotal,
    isHeader,
    isTitle,
    isLaba,
    isSubTitle,
    isAccount,
    isSubAccount,
    isSubTotal,
    isSubLaba,
  }) => {
    let rowStyle, leftCellStyle, rightCellStyle;

    if (isHeader) {
      rowStyle = styles.tableHeader;
      leftCellStyle = styles.tableHeaderCellLeft;
      rightCellStyle = styles.tableHeaderCellRight;
    } else if (isTitle) {
      rowStyle = styles.tableRowTitle;
      leftCellStyle = styles.tableRowTitleCellLeft;
      rightCellStyle = styles.tableRowTitleCellRight;
    } else if (isSubTitle) {
      rowStyle = styles.tableRowSubTitle;
      leftCellStyle = styles.tableRowSubTitleCellLeft;
      rightCellStyle = styles.tableRowSubTitleCellRight;
    } else if (isAccount) {
      rowStyle = styles.tableRowAccount;
      leftCellStyle = styles.tableRowAccountCellLeft;
      rightCellStyle = styles.tableRowAccountCellRight;
    } else if (isSubAccount) {
      rowStyle = styles.tableRowSubAccount;
      leftCellStyle = styles.tableRowSubAccountCellLeft;
      rightCellStyle = styles.tableRowSubAccountCellRight;
    } else if (isSubTotal) {
      rowStyle = styles.tableRowSubTotal;
      leftCellStyle = styles.tableRowSubTotalCellLeft;
      rightCellStyle = styles.tableRowSubTotalCellRight;
    } else if (isSubLaba) {
      rowStyle = styles.tableRowSubLaba;
      leftCellStyle = styles.tableRowSubLabaCellLeft;
      rightCellStyle = styles.tableRowSubLabaCellRight;
    } else if (isLaba) {
      rowStyle = styles.tableRowLaba;
      leftCellStyle = styles.tableRowLabaCellLeft;
      rightCellStyle = styles.tableRowLabaCellRight;
    } else {
      rowStyle = isTotal ? styles.tableRowTotal : styles.tableRow;
      leftCellStyle = isTotal
        ? styles.tableRowTotalCellLeft
        : styles.tableCellLeft;
      rightCellStyle = isTotal
        ? styles.tableRowTotalCellRight
        : styles.tableCellRight;
    }

    return (
      <View style={rowStyle}>
        <Text style={leftCellStyle}>{leftText}</Text>
        <Text style={rightCellStyle}>{rightText}</Text>
      </View>
    );
  };
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      padding: 50, // Sesuaikan padding sesuai dengan kebutuhan desain
      backgroundColor: "#FFF",
    },
    header: {
      margin: 10,
    },
    title: {
      fontSize: 18,
      textAlign: "center",
      color: "#012970",
      fontFamily: "Helvetica-Bold",
    },
    company: {
      fontSize: 14,
      textAlign: "center",
      // Menggunakan Times-Roman sebagai font bawaan
      fontFamily: "Courier",
    },
    year: {
      fontSize: 12,
      textAlign: "center",
      fontWeight: "light",
      // Menggunakan Courier sebagai font bawaan
      fontFamily: "Courier",
    },
    table: {
      display: "table",
      width: "100%", // Lebar tabel menjadi 100%
      borderStyle: "solid",
      padding: 2,
    },
    tableHeader: {
      margin: "auto",
      flexDirection: "row",
      color: "#012970", // Warna teks untuk header
      borderBottomColor: "#012970",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      fontSize: 10,
      fontWeight: "bold",
    },
    tableHeaderCellLeft: {
      width: "85%",
      textAlign: "left",
    },
    tableHeaderCellRight: {
      width: "15%",
      paddingRight: 8,
      textAlign: "right",
    },
    tableRowTitle: {
      marginTop: 10,
      flexDirection: "row",
      alignItems: "center",
      fontFamily: "Helvetica-Bold",
      fontSize: 8, // Ukuran font untuk baris tabel
      fontWeight: "bold",
      paddingBottom: 4,
    },
    tableRowTitleCellRight: {
      width: "30%", // Lebar sel kanan disesuaikan
      textAlign: "right",
    },
    tableRowTitleCellLeft: {
      width: "70%", // Lebar sel kiri
      textAlign: "left",
    },
    tableRowSubTitle: {
      marginTop: 10,
      flexDirection: "row",
      alignItems: "center",
      fontFamily: "Helvetica-Bold",
      fontSize: 8, // Ukuran font untuk baris tabel
      fontWeight: "bold",
      paddingBottom: 4,
      paddingLeft: 6,
    },
    tableRowSubTitleCellRight: {
      width: "30%", // Lebar sel kanan disesuaikan
      textAlign: "right",
    },
    tableRowSubTitleCellLeft: {
      width: "70%", // Lebar sel kiri
      textAlign: "left",
    },
    tableRowAccount: {
      marginTop: 10,
      flexDirection: "row",
      alignItems: "center",
      fontFamily: "Helvetica-Bold",
      fontSize: 8, // Ukuran font untuk baris tabel
      fontWeight: "bold",
      paddingBottom: 4,
      paddingLeft: 12,
    },
    tableRowAccountCellRight: {
      width: "30%", // Lebar sel kanan disesuaikan
      textAlign: "right",
    },
    tableRowAccountCellLeft: {
      width: "70%", // Lebar sel kiri
      textAlign: "left",
    },
    tableRowSubAccount: {
      marginTop: 10,
      flexDirection: "row",
      alignItems: "center",
      fontFamily: "Helvetica-Bold",
      fontSize: 8, // Ukuran font untuk baris tabel
      fontWeight: "bold",
      paddingBottom: 4,
      paddingLeft: 18,
    },
    tableRowSubAccountCellRight: {
      width: "30%", // Lebar sel kanan disesuaikan
      textAlign: "right",
    },
    tableRowSubAccountCellLeft: {
      width: "70%", // Lebar sel kiri
      textAlign: "left",
    },
    tableRowTotal: {
      flexDirection: "row",
      alignItems: "center",
      fontFamily: "Helvetica-Bold",
      marginBottom: 10,
      fontSize: 8, // Ukuran font untuk baris tabel
      fontWeight: "bold",
      paddingTop: 4,
      paddingLeft: 12,
    },
    tableRowTotalCellRight: {
      width: "30%", // Lebar sel kanan disesuaikan
      textAlign: "right",
      paddingTop: 3,
      borderTopWidth: 1,
    },
    tableRowTotalCellLeft: {
      width: "70%", // Lebar sel kiri
      textAlign: "left",
    },
    tableRowSubTotal: {
      flexDirection: "row",
      alignItems: "center",
      fontFamily: "Helvetica-Bold",
      marginBottom: 10,
      fontSize: 8, // Ukuran font untuk baris tabel
      fontWeight: "bold",
      paddingTop: 4,
      paddingLeft: 18,
    },
    tableRowSubTotalCellRight: {
      width: "30%", // Lebar sel kanan disesuaikan
      textAlign: "right",
      paddingTop: 3,
      borderTopWidth: 1,
    },
    tableRowSubTotalCellLeft: {
      width: "70%", // Lebar sel kiri
      textAlign: "left",
    },
    tableRowLaba: {
      flexDirection: "row",
      alignItems: "center",
      fontFamily: "Helvetica-Bold",
      marginBottom: 10,
      marginTop: 10,
      fontSize: 8, // Ukuran font untuk baris tabel
      fontWeight: "bold",
    },
    tableRowLabaCellLeft: {
      width: "70%", // Lebar sel kiri
      textAlign: "left",
    },
    tableRowLabaCellRight: {
      width: "30%", // Lebar sel kanan disesuaikan
      textAlign: "right",
      borderTopWidth: 1,
      paddingTop: 3,
    },
    tableRowSubLaba: {
      flexDirection: "row",
      alignItems: "center",
      fontFamily: "Helvetica-Bold",
      marginBottom: 10,
      marginTop: 10,
      fontSize: 8, // Ukuran font untuk baris tabel
      fontWeight: "bold",
      paddingLeft: 6,
    },
    tableRowSubLabaCellLeft: {
      width: "70%", // Lebar sel kiri
      textAlign: "left",
    },
    tableRowSubLabaCellRight: {
      width: "30%", // Lebar sel kanan disesuaikan
      textAlign: "right",
      borderTopWidth: 1,
      paddingTop: 3,
    },
    tableRow: {
      flexDirection: "row",
      fontFamily: "Helvetica",
      fontWeight: "thin",
      alignItems: "center",
      fontSize: 8, // Ukuran font untuk baris tabel
      paddingLeft: 18,
    },
    tableCellLeft: {
      width: "60%", // Lebar sel kiri
      textAlign: "left",
      padding: 8,
    },
    tableCellRight: {
      width: "40%", // Lebar sel kanan disesuaikan
      paddingBottom: 4,
      textAlign: "right",
    },
    footer: {
      textAlign: "center",
      marginTop: "auto",
      borderTopColor: "#bfbfbf",
      borderTop: 1,
      fontFamily: "Courier",
      whiteSpace: "pre-wrap",
      fontSize: 10, // Sesuaikan ukuran font footer
    },
  });
  const ScaleReportPdf = ({ reportData }) => {
    const { Company, Year, Asset, LiabilitasdanEkuitas } = reportData;
    return (
      <Document>
        <Page style={styles.page}>
          <View fixed style={styles.header}>
            {/* Judul, Nama Perusahaan, dan Tahun */}
            <Text style={styles.company}>{Company}</Text>
            <Text style={styles.title}>Laporan Neraca</Text>
            <Text style={styles.year}>Dari {Year}</Text>
          </View>
          <View style={styles.table}>
            {/* Header Tabel */}
            <View fixed>
              <TableRow leftText="Deskripsi" rightText="Nilai (IDR)" isHeader />
            </View>
            {/* Aset  */}
            <TableRow leftText="ASET" rightText="" isTitle />
            {/* {Aset Lancar } */}
            <TableRow leftText="ASET LANCAR" rightText="" isSubTitle />
            {/* {Kas Dan Bank } */}
            <TableRow leftText="Kas dan Setara Kas" rightText="" isAccount />
            {/* Isi Tabel */}
            {Object.entries(Asset.AsetLancar.kasBank).map(
              ([key, value]) =>
                key !== "total" && (
                  <TableRow leftText={key} rightText={formatCurrency(value)} />
                )
            )}
            <TableRow
              leftText="Jumlah Kas dan Setara Kas"
              rightText={formatCurrency(Asset.AsetLancar.kasBank.total)}
              isTotal
            />
            <TableRow leftText="Piutang Usaha" rightText="" isAccount />
            {/* Isi Tabel */}
            {Object.entries(Asset.AsetLancar.piutang).map(
              ([key, value]) =>
                key !== "total" && (
                  <TableRow leftText={key} rightText={formatCurrency(value)} />
                )
            )}
            <TableRow
              leftText="Jumlah Piutang Usaha"
              rightText={formatCurrency(Asset.AsetLancar.piutang.total)}
              isTotal
            />
            <TableRow leftText="Persediaan" rightText="" isAccount />
            {/* Isi Tabel */}
            {Object.entries(Asset.AsetLancar.persediaan).map(
              ([key, value]) =>
                key !== "total" && (
                  <TableRow leftText={key} rightText={formatCurrency(value)} />
                )
            )}
            <TableRow
              leftText="Jumlah Persediaan"
              rightText={formatCurrency(Asset.AsetLancar.persediaan.total)}
              isTotal
            />
            <TableRow leftText="Aset Lancar Lainnya" rightText="" isAccount />
            {/* Isi Tabel */}
            {Object.entries(Asset.AsetLancar.asetLancarLainnya).map(
              ([key, value]) =>
                key !== "total" && (
                  <TableRow leftText={key} rightText={formatCurrency(value)} />
                )
            )}
            <TableRow
              leftText="Jumlah Aset Lancar Lainnya"
              rightText={formatCurrency(
                Asset.AsetLancar.asetLancarLainnya.total
              )}
              isTotal
            />
            <TableRow
              leftText="Jumlah Aset Lancar"
              rightText={formatCurrency(Asset.AsetLancar.total)}
              isSubLaba
            />
            <TableRow leftText="ASET TIDAK LANCAR" rightText="" isSubTitle />
            {/* {Kas Dan Bank } */}
            <TableRow leftText="Nilai Histori" rightText="" isAccount />
            {/* Isi Tabel */}
            {Object.entries(Asset.AsetTidakLancar.asetTetap).map(
              ([key, value]) =>
                key !== "total" && (
                  <TableRow leftText={key} rightText={formatCurrency(value)} />
                )
            )}
            <TableRow
              leftText="Jumlah Nilai Histori"
              rightText={formatCurrency(Asset.AsetTidakLancar.asetTetap.total)}
              isTotal
            />
            <TableRow leftText="Akumulasi Penyusutan" rightText="" isAccount />
            {/* Isi Tabel */}
            {Object.entries(Asset.AsetTidakLancar.akumulasi).map(
              ([key, value]) =>
                key !== "total" && (
                  <TableRow leftText={key} rightText={formatCurrency(value)} />
                )
            )}
            <TableRow
              leftText="Jumlah Akumulasi Penyusutan"
              rightText={formatCurrency(Asset.AsetTidakLancar.akumulasi.total)}
              isTotal
            />
            <TableRow
              leftText="Jumlah Aset Tidak Lancar"
              rightText={formatCurrency(Asset.AsetTidakLancar.total)}
              isSubLaba
            />
            <TableRow leftText="Aset Lainnya" rightText="" isSubTitle />
            {/* Isi Tabel */}
            {Object.entries(Asset.AsetLainnya).map(
              ([key, value]) =>
                key !== "total" && (
                  <TableRow leftText={key} rightText={formatCurrency(value)} />
                )
            )}
            <TableRow
              leftText="Jumlah Aset Lainnya"
              rightText={formatCurrency(Asset.AsetLainnya.total)}
              isSubLaba
            />
            <TableRow
              leftText="JUMLAH ASET"
              rightText={formatCurrency(Asset.TotalAsset)}
              isLaba
            />
            <TableRow leftText="LIABILITAS DAN EKUITAS" rightText="" isTitle />
            {/* {Aset Lancar } */}
            <TableRow leftText="LIABILITAS" rightText="" isSubTitle />
            <TableRow
              leftText="LIABILITAS JANGKA PENDEK"
              rightText=""
              isAccount
            />
            {/* {Kas Dan Bank } */}
            <TableRow leftText="Hutang Usaha" rightText="" isSubAccount />
            {/* Isi Tabel */}
            {Object.entries(
              LiabilitasdanEkuitas.Liabilitas.liabilityShort.utang
            ).map(
              ([key, value]) =>
                key !== "total" && (
                  <TableRow leftText={key} rightText={formatCurrency(value)} />
                )
            )}
            <TableRow
              leftText="Jumlah Hutang Usaha"
              rightText={formatCurrency(
                LiabilitasdanEkuitas.Liabilitas.liabilityShort.utang.total
              )}
              isSubTotal
            />
            <TableRow
              leftText="Liabilitas Jangka Pendek Lainnya"
              rightText=""
              isSubAccount
            />
            {/* Isi Tabel */}
            {Object.entries(
              LiabilitasdanEkuitas.Liabilitas.liabilityShort.liabilitasLainnya
            ).map(
              ([key, value]) =>
                key !== "total" && (
                  <TableRow leftText={key} rightText={formatCurrency(value)} />
                )
            )}
            <TableRow
              leftText="Jumlah Liabilitas Jangka Pendek Lainnya"
              rightText={formatCurrency(
                LiabilitasdanEkuitas.Liabilitas.liabilityShort.liabilitasLainnya
                  .total
              )}
              isSubTotal
            />
            <TableRow
              leftText="Jumlah Liabilitas Jangka Pendek"
              rightText={formatCurrency(
                LiabilitasdanEkuitas.Liabilitas.liabilityShort.total
              )}
              isTotal
            />
            <TableRow
              leftText="LIABILITAS JANGKA PANJANG"
              rightText=""
              isAccount
            />
            {Object.entries(LiabilitasdanEkuitas.Liabilitas.liabilityLong).map(
              ([key, value]) =>
                key !== "total" && (
                  <TableRow leftText={key} rightText={formatCurrency(value)} />
                )
            )}
            <TableRow
              leftText="Jumlah Liabilitas Jangka Panjang"
              rightText={formatCurrency(
                LiabilitasdanEkuitas.Liabilitas.liabilityLong.total
              )}
              isTotal
            />
            <TableRow
              leftText="Jumlah Liabilitas"
              rightText={formatCurrency(
                LiabilitasdanEkuitas.Liabilitas.TotalLiability
              )}
              isSubLaba
            />
            <TableRow leftText="EKUITAS" rightText="" isSubTitle />
            {Object.entries(LiabilitasdanEkuitas.Ekuitas.ekuitas).map(
              ([key, value]) =>
                key !== "total" && (
                  <TableRow leftText={key} rightText={formatCurrency(value)} />
                )
            )}
            <TableRow
              leftText="Jumlah Ekuitas"
              rightText={formatCurrency(
                LiabilitasdanEkuitas.Ekuitas.ekuitas.total
              )}
              isSubLaba
            />
            <TableRow
              leftText="JUMLAH LIABILITAS DAN EKUITAS"
              rightText={formatCurrency(
                LiabilitasdanEkuitas.TotalLiabilityEquity
              )}
              isLaba
            />
          </View>
          {/* Footer */}
          <Text fixed style={styles.footer}>
            {"RanaBlue Accounting System Report\nTercetak pada " +
              tanggalWaktuIndonesia}
          </Text>
        </Page>
      </Document>
    );
  };

  const handleDateChange = (dates) => {
    // Memeriksa apakah pengguna sudah selesai memilih tanggal
    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      fetchReportData(dates);
      setDateRange(dates);
    }
  };

  const refreshReport = () => {
    fetchReportData(dateRange);
  };

  // Fungsi untuk mencetak PDF
  const printPDF = () => {
    pdf(<ScaleReportPdf reportData={reportData} />)
      .toBlob()
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank"); // Buka PDF di jendela/tab baru
      })
      .catch((error) => {
        console.error("Error creating PDF blob:", error);
      });
  };
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Laporan Neraca</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">Laporan Keuangan</li>

                  <li className="breadcrumb-item active">Neraca</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="custom-toolbar">
            <div className="card ">
              <div
                className="card-body d-flex align-center justify-content-center"
                style={{ gap: 20 }}
              >
                <div
                  className="flatpckr me-3"
                  data-tooltip-id="toollbarCalendar"
                >
                  <Flatpickr
                    ref={flatpickrRef}
                    value={dateRange}
                    onChange={handleDateChange}
                    options={{
                      mode: "range",
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                    }}
                  />
                  <Tooltip
                    id="toollbarCalendar"
                    place="top"
                    ariant="info"
                    content="Pilih Periode"
                  />
                </div>
                <Link onClick={refreshReport}>
                  <Icons.ArrowRepeat
                    data-tooltip-id="toollbarRefresh"
                    size={24}
                  />
                  <Tooltip
                    id="toollbarRefresh"
                    place="top"
                    variant="info"
                    content="Refresh"
                  />
                </Link>
                <PDFDownloadLink
                  document={<ScaleReportPdf reportData={reportData} />}
                  fileName={`laporan-neraca-keuangan-${reportData.Company}.pdf`}
                >
                  <Icons.Download
                    data-tooltip-id="toollbarDownload"
                    size={24}
                  />
                  <Tooltip
                    id="toollbarDownload"
                    place="top"
                    variant="info"
                    content="Download"
                  />
                </PDFDownloadLink>
                <Link onClick={printPDF}>
                  <Icons.Printer data-tooltip-id="toollbarPrint" size={24} />
                  <Tooltip
                    id="toollbarPrint"
                    place="top"
                    variant="info"
                    content="Print"
                  />
                </Link>
                <Link onClick={zoomIn}>
                  <Icons.ZoomIn data-tooltip-id="toollbarZoomIn" size={24} />
                  <Tooltip
                    id="toollbarZoomIn"
                    place="top"
                    variant="info"
                    content="Zoom-In"
                  />
                </Link>
                <Link onClick={zoomOut}>
                  <Icons.ZoomOut data-tooltip-id="toollbarZoomOut" size={24} />
                  <Tooltip
                    id="toollbarZoomOut"
                    place="top"
                    variant="info"
                    content="Zoom-Out"
                  />
                </Link>
              </div>
            </div>
          </div>
          {reportData && (
            <>
              <PDFViewer width="100%" height="420px" showToolbar={false}>
                <ScaleReportPdf reportData={reportData} />
              </PDFViewer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ScaleReport;
