import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = apiDomain + "/industries";

const getIndustries = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL,
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

export { getIndustries };
