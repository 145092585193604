import React from "react";
import Logo from "../../assets/images/favicon-big.png";

import './style.css'

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div className="spin-animation">
        <img src={Logo} className="img-fluid w-50" alt="My Logo" />
        <div className="icon-spin">
          <div className="spinner">
            <div className="bar bar1"></div>
            <div className="bar bar2"></div>
            <div className="bar bar3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
