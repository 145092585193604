import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";

import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { HiDotsHorizontal } from "react-icons/hi";
import * as Icons from "react-bootstrap-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  addSalarie,
  detailSalarie,
  updateSalarie,
} from "../../../fetch/salariesFetch";
import { getToken } from "../../../fetch/userFetch";
import { getAccounts } from "../../../fetch/accountFetch";
const SalariesForm = () => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editable, setEditable] = useState(false);
  const isFormModifiedRef = useRef(false);
  const [accounts, setAccounts] = useState({});
  const [valid, setValid] = useState({
    isName: true,
    isType: true,
    isAccount: true,
  });
  const [data, setData] = useState({
    Nama: null,
    Tipe: null,
    Method: null,
    Persen: 0,
    AccountId: null,
  });
  const salaryTypes = [
    {
      label: "Gaji/Pensiun atau THT/JHT",
      value: "gaji_pensiun_tht_jht",
      type: "Bayar",
      persen: 0,
    },
    {
      label: "Tunjangan PPh",
      value: "tunjangan_pph",
      type: "Bayar",
      persen: 0,
    },
    { label: "Subsidi PPh", value: "subsidi_pph", type: "Bayar", persen: 0 },
    {
      label: "Tunjangan Lainnya, Uang lembur dan sebagainya",
      value: "tunjangan_lainnya",
      type: "Bayar",
      persen: 0,
    },
    {
      label: "Honorarium dan Imbalan lain sejenisnya",
      value: "honorarium_imbalan_lain",
      type: "Bayar",
      persen: 0,
    },
    {
      label: "Penerimaan dalam bentuk natura dan kenikmatan lainnya",
      value: "penerimaan_natura_kenikmatan_lainnya",
      type: "Bayar",
      persen: 0,
    },
    {
      label: "Tantiem, Bonus, Rapel, Gratifikasi, Jasa Produksi dan THR",
      value: "tantiem_bonus_rapel_gratifikasi_jasa_produksi_thr",
      type: "Bayar",
      persen: 0,
    },
    {
      label: "Potongan Gaji (Tidak Mengurangi PPh)",
      value: "potongan_gaji_tidak_mengurangi_pph",
      type: "Potongan",
      persen: 0,
    },
    {
      label: "Pengurangan Gaji (Mengurangi PPh)",
      value: "pengurangan_gaji_mengurangi_pph",
      type: "Potongan",
      persen: 0,
    },

    // Jenis gaji/tunjangan dengan persentase spesifik
    {
      label: "Tunjangan Jaminan Kecelakaan Kerja, Jaminan Kematian",
      value: "tunjangan_jaminan_kecelakaan_kematian",
      type: "Bayar",
      persen: 1.8,
    },
    {
      label: "Premi asuransi kesehatan yang dibayarkan pemberi kerja",
      value: "premi_asuransi_kesehatan_dibayarkan_pemberi_kerja",
      type: "Bayar",
      persen: 0.89,
    },
    {
      label: "Premi asuransi kesehatan dibayarkan pekerja",
      value: "premi_asuransi_kesehatan_dibayarkan_pekerja",
      type: "Potongan",
      persen: 0.89,
    },
    {
      label: "Tunjangan Iuran Pensiun/THT/JHT dibayarkan Pemberi Kerja",
      value: "tunjangan_iuran_pensiun",
      type: "Bayar",
      persen: 0.89,
    },
    {
      label: "Iuran Pensiun/THT/JHT dibayarkan Pekerja",
      value: "iuran_pensiun_tht_jht_dibayarkan_pekerja",
      type: "Potongan",
      persen: 0.89,
    },
  ];

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const salarieId = queryParams.salarieId;
  const navigation = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    getAccountsList();
    if (salarieId) {
      localStorage.setItem("salarieId", salarieId);
    }
    const storedProductId = localStorage.getItem("salarieId");
    if (storedProductId) {
      getSalarie(storedProductId);
      setEditable(true);
    }
    const handleBeforeUnload = (e) => {
      if (isFormModifiedRef.current) {
        e.preventDefault();
        e.returnValue =
          "Perubahan belum disimpan. Anda yakin ingin meninggalkan halaman ini?";
      }
    };
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      clearTimeout(timer);
    };
  }, []);
  const getSalarie = (id) => {
    detailSalarie(id, (result) => {
        let persenValue = result.Persen ? result.Persen.replace(/[^0-9.]/g, '') : '0';
        persenValue = parseFloat(persenValue) || 0;
      setData({
        Nama: result.Name,
        Tipe: result.Tipe,
        Method: result.Method,
        Persen: persenValue,
        AccountId: result.AccountId,
      });
    });

  };
  const getAccountsList = () => {
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        getAccounts(id, (cb) => {
          setAccounts(cb);
        });
      });
    }
  };
  const handleName = (e) => {
    const { value } = e.target;
    setData((prevState) => ({
      ...prevState,
      Nama: value,
    }));
    setValid((prevState) => ({
      ...prevState,
      isNama: e.target.value.trim() !== "",
    }));
    isFormModifiedRef.current = true;
  };
  const handleSalarieType = (selectedOption) => {
    setData({
      ...data,
      Tipe: selectedOption ? selectedOption.label : "",
      Method: selectedOption ? selectedOption.type : "",
      Persen: selectedOption ? selectedOption.persen : "",
    });
    setValid({
      ...valid,
      isType: selectedOption,
    });
  };
  const handleAccounts = (selectedOption) => {
    setData({
      ...data,
      AccountId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isAccount: selectedOption,
    });
    isFormModifiedRef.current = true;
  };
  const cancelSubmit = (e) => {
    e.preventDefault();
    isFormModifiedRef.current = false;
    navigation("/salaries");
  };
  const addForm = (e) => {
    e.preventDefault();
    isFormModifiedRef.current = false;
    setLoadingButton(true);
    const payload = {
      Name: data.Nama,
      Tipe: data.Tipe,
      AccountId: data.AccountId,
      Method: data.Method,
      Persen: data.Persen,
    };
    setTimeout(async () => {
      const result = await addSalarie(payload);
      if (result) {
        setLoadingButton(false);
        navigation("/salaries");
      } else {
        setLoadingButton(false);
      }
    }, 3000);
  };
  const editForm = (e) => {
    e.preventDefault();
    isFormModifiedRef.current = false;
    setLoadingButton(true);
    const payload = {
      id: salarieId,
      Name: data.Name,
      Tipe: data.Tipe,
      AccountId: data.AccountId,
      Method: data.Method,
      Persen: data.Persen,
    };
    setTimeout(async () => {
      const result = await updateSalarie(payload);
      if (result) {
        setLoadingButton(false);
        navigation("/salaries");
      } else {
        setLoadingButton(false);
      }
    }, 3000);
  };
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Gaji/Tunjangan</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">Perusahaan</li>
                  <li className="breadcrumb-item">
                    <a href="/salaries">Gaji/Tunjangan</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {editable ? "Edit" : "Add"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="salarieForm col-lg-8">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {editable ? "Edit Gaji/Tunjangan" : "Gaji/Tunjangan Baru"}
                </h5>
                <hr />
                <form onSubmit={editable ? editForm : addForm} id="formSalarie">
                  <div className="row mb-3">
                    <label
                      htmlFor="nameSalarie"
                      className="col-sm-4 col-form-label"
                    >
                      Nama <span className="required">*</span>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        id="nameSalarie"
                        name="nameSalarie"
                        className={`form-control ${
                          !valid.isName ? "is-invalid" : ""
                        }`}
                        onChange={handleName}
                        placeholder="Nama "
                        required
                        value={data.Nama}
                      />
                      {!valid.isName && (
                        <div className="invalid-feedback">
                          Nama Gaji/Tunjangan harus diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="statusSalarie"
                      className="col-sm-4 col-form-label"
                    >
                      Tipe Gaji/Tunjangan<span className="required">*</span>
                    </label>
                    <div className="col-sm-8">
                      <Select
                        id="statusSalarie"
                        key={data.Tipe || "no-value"}
                        options={salaryTypes.map((salary) => ({
                          label: salary.label,
                          value: salary.value,
                          type: salary.type,
                          persen: salary.persen,
                        }))}
                        value={
                          data.Tipe
                            ? {
                                label: salaryTypes.find(
                                  (type) => type.label === data.Tipe
                                )?.label,
                                value: data.Tipe,
                              }
                            : null
                        }
                        onChange={handleSalarieType}
                        className={
                          !valid.isType
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Tipe Gaji/Tunjangan..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        isDisabled={editable}
                        required
                      />
                      {!valid.isType && (
                        <div className="invalid-feedback">
                          Tipe Gaji/Tunjangan Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="methodSalarie"
                      className="col-sm-4 col-form-label"
                    >
                      Bayar/Potongan <span className="required">*</span>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        id="methodSalarie"
                        name="methodSalarie"
                        className="form-control"
                        onChange={(e) =>
                          setData({
                            ...data,
                            Method: e.target.value,
                          })
                        }
                        disabled
                        required
                        value={data.Method}
                      />
                    </div>
                  </div>
                  {data.Persen !== 0 ? (
                    <div className="row mb-3">
                      <label
                        htmlFor="persenSalarie"
                        className="col-sm-4 col-form-label"
                      >
                        Persen dari Gaji <span className="required">*</span>
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          id="persenSalarie"
                          name="persenSalarie"
                          className="form-control"
                          onChange={(e) =>
                            setData({
                              ...data,
                              Persen: e.target.value,
                            })
                          }
                          value={data.Persen}
                          required
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="row mb-5">
                    <label
                      htmlFor="accountSelect"
                      className="col-sm-4 col-form-label"
                    >
                      Akun Beban<span className="required">*</span>
                    </label>
                    <div className="col-sm-8 position-relative">
                      <Select
                        id="accountSelect"
                        key={data.AccountId || "no-value"}
                        options={accounts
                          .filter((account) => account.AccountType === "Beban")
                          .map((account) => ({
                            label: account.AccountName,
                            value: account.id,
                            type: account.AccountType,
                          }))}
                        value={
                          data.AccountId
                            ? {
                                label: accounts.find(
                                  (account) => account.id === data.AccountId
                                )?.AccountName,
                                value: data.AccountId,
                              }
                            : null
                        }
                        onChange={handleAccounts}
                        className={
                          !valid.isAccount
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Akun Perkiraan..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        required
                      />
                      {!valid.isAccount && (
                        <div className="invalid-feedback">
                          Akun Beban Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3 submit-button">
                    <div className="col-sm-10">
                      <button
                        className={`btn btn-sm me-3 btn-primary ${
                          loadingButton ? "" : "d-none"
                        }`}
                        type="button"
                        disabled={loadingButton}
                      >
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Menyimpan...
                      </button>
                      <button
                        type="submit"
                        className={`btn btn-sm btn-primary me-3 ${
                          loadingButton ? "d-none" : ""
                        }`}
                      >
                        Simpan
                      </button>
                      <Link
                        className="btn btn-sm  btn-secondary"
                        onClick={(e) => cancelSubmit(e)}
                      >
                        Batalkan
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SalariesForm;
