import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import {
  addAccount,
  detailAccount,
  updateAccount,
} from "../../../fetch/accountFetch";
import { getToken } from "../../../fetch/userFetch";
import Select from "react-select";

const AddAccount = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const { accountId } = props;
  const [isFormModified, setIsFormModified] = useState(false);
  const [editable, setEditable] = useState(false);
  const [valid, setValid] = useState({
    isName: true,
    isType: true,
    isBallance: true,
  });
  const [data, setData] = useState({
    AccountName: "",
    AccountNumber: null,
    AccountType: "",
    Balance: 0,
  });
  const [typeAccount] = useState([
    { name: "Kas", id: "Kas" },
    { name: "Bank", id: "Bank" },
    { name: "Piutang Usaha", id: "Piutang Usaha" },
    { name: "Persediaan", id: "Persediaan" },
    { name: "Aset Lancar Lainnya", id: "Aset Lancar Lainnya" },
    { name: "Aset Tetap", id: "Aset Tetap" },
    { name: "Akumulasi Penyusutan", id: "Akumulasi Penyusutan" },
    { name: "Aset Lainnya", id: "Aset Lainnya" },
    { name: "Hutang Usaha", id: "Hutang Usaha" },
    { name: "Liabilitas Jangka Pendek", id: "Liabilitas Jangka Pendek" },
    { name: "Liabilitas Jangka Panjang", id: "Liabilitas Jangka Panjang" },
    { name: "Modal", id: "Modal" },
    { name: "Pendapatan", id: "Pendapatan" },
    { name: "Beban Pokok Penjualan", id: "Beban Pokok Penjualan" },
    { name: "Beban", id: "Beban" },
    { name: "Beban Lainnya", id: "Beban Lainnya" },
    { name: "Pendapatan Lainnya", id: "Pendapatan Lainnya" },
  ]);

  const navigation = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    if (accountId) {
      localStorage.setItem("accountId", accountId);
    }
    const storedAccountId = localStorage.getItem("accountId");
    if (storedAccountId) {
      getAccount(storedAccountId);
      setEditable(true);
    }
    const handleBeforeUnload = (e) => {
      if (isFormModified) {
        e.preventDefault();
        e.returnValue =
          "Perubahan belum disimpan. Anda yakin ingin meninggalkan halaman ini?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [loading]);

  const getAccount = (id) => {
    detailAccount(id, (result) => {
      setData({
        AccountName: result.AccountName,
        AccountNumber: result.AccountNumber,
        AccountType: result.AccountType,
        Balance: result.Balance,
      });
    });
  };
  const validName = (e) => {
    const { value } = e.target;
    setData((prevState) => ({
      ...prevState,
      AccountName: value,
    }));
    setValid((prevState) => ({
      ...prevState,
      isName: e.target.value.trim() !== "",
    }));
  };
  const handleType = (selectedOption) => {
    const accountTypeToNumber = {
      Kas: "1101",
      Bank: "1102",
      "Piutang Usaha": "1103",
      Persediaan: "1104",
      "Aset Lancar Lainnya": "1106",
      "Aset Tetap": "1107",
      "Akumulasi Penyusutan": "1108",
      "Aset Lainnya": "1109",
      "Hutang Usaha": "2101",
      "Liabilitas Jangka Pendek": "2102",
      "Liabilitas Jangka Panjang": "2103",
      Modal: "3000",
      Pendapatan: "4101",
      "Beban Pokok Penjualan": "5101",
      Beban: "6999",
      "Beban Lainnya": "7201",
      "Pendapatan Lainnya": "4105",
    };

    setData({
      ...data,
      AccountType: selectedOption ? selectedOption.value : "",
      AccountNumber: selectedOption
        ? accountTypeToNumber[selectedOption.value] || ""
        : "",
    });
    setValid({
      ...valid,
      isType: selectedOption,
    });
  };

  const formatRupiah = (value) => {
    if (
      !value ||
      typeof value !== "string" ||
      !/^\d+$/.test(value.replace(/[^0-9]/g, ""))
    )
      return "0";
    const numberValue = parseInt(value.replace(/[^0-9]/g, ""), 10);

    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return formatter.format(numberValue).replace(/Rp\s?/, "");
  };

  const handleBalanceChange = (e) => {
    console.log(e);
    const formattedValue = formatRupiah(e.target.value);
    setData({ ...data, Balance: formattedValue });
  };
  const cancelSubmit = (e) => {
    e.preventDefault();
    setIsFormModified(false);
    navigation("/accounts");
  };
  const submitForm = (e) => {
    e.preventDefault();
    setIsFormModified(false);
    setLoadingButton(true);
    const newAkun = {
      AccountName: data.AccountName,
      AccountNumber: data.AccountNumber,
      AccountType: data.AccountType,
      Balance: data.Balance,
    };
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        setTimeout(() => {
          addAccount(id, newAkun);
          setLoadingButton(false);
          navigation("/accounts");
        }, 3000);
      });
    }
  };
  const editForm = (e) => {
    e.preventDefault();
    setIsFormModified(false);
    setLoadingButton(true);
    const editAkun = {
      id: accountId,
      AccountName: data.AccountName,
      AccountNumber: data.AccountNumber,
      AccountType: data.AccountType,
      Balance: data.Balance,
    };
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        setTimeout(() => {
          updateAccount(editAkun);
          setLoadingButton(false);
          navigation("/accounts");
        }, 3000);
      });
    }
  };
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Akun Perkiraan</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/accounts">Buku Besar</a>
                  </li>

                  <li className="breadcrumb-item ">
                    <a href="/accounts">Akun Perkiraan</a>
                  </li>

                  <li className="breadcrumb-item active">
                    {editable ? "Edit" : "Add"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="addForm col-lg-8">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {editable ? "Edit Akun Perkiraan" : "Akun Perkiraan Baru"}
                </h5>
                <hr />
                <form
                  onSubmit={editable ? editForm : submitForm}
                  id="formAccount"
                >
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label">
                      Tipe Akun<span className="required">*</span>
                    </label>
                    <div className="col-sm-10">
                      <Select
                        id="accountType"
                        options={typeAccount.map((type) => ({
                          label: type.name,
                          value: type.id,
                        }))}
                        value={
                          data.AccountType
                            ? {
                                label: typeAccount.find(
                                  (type) => type.id === data.AccountType
                                )?.name,
                                value: data.AccountType,
                              }
                            : null
                        }
                        onChange={handleType}
                        className={!valid.isType ? "is-invalid react-select" : "react-select"}
                        placeholder="Pilih Tipe Akun..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        isDisabled={editable}
                        required
                      />
                      {!valid.isType && (
                        <div className="invalid-feedback">
                          Tipe Akun Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="kodeAccount"
                      className="col-sm-2 col-form-label"
                    >
                      Kode Perkiraan
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="kodeAccount"
                        name="kodeAccount"
                        className="form-control"
                        value={data.AccountNumber}
                        onChange={(e) =>
                          setData({
                            ...data,
                            AccountNumber: e.target.value,
                          })
                        }
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="nameAccount"
                      className="col-sm-2 col-form-label"
                    >
                      Nama Akun<span className="required">*</span>
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="nameAccount"
                        name="nameAccount"
                        className={`form-control ${
                          !valid.isName ? "is-invalid" : ""
                        }`}
                        onChange={validName}
                        placeholder="Nama Akun Perkiraan"
                        value={data.AccountName}
                        required
                      />
                      {!valid.isName && (
                        <div className="invalid-feedback">
                          Nama Akun harus diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  {data.AccountType === "Kas" || data.AccountType === "Bank" ? (
                    <div className="row mb-5">
                      <label
                        htmlFor="balanceAccount"
                        className="col-sm-2 col-form-label"
                      >
                        Saldo
                      </label>
                      <div className="col-sm-6">
                        <div className="input-group mb-3">
                          <span className="input-group-text">Rp</span>
                          <input
                            type="text"
                            id="balanceAccount"
                            name="balanceAccount"
                            className="form-control"
                            onChange={handleBalanceChange}
                            value={data.Balance}
                            disabled={editable ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <hr />
                  <div className="row mb-3 submit-button">
                    <div className="col-sm-10">
                      <button
                        className={`btn btn-sm me-3 btn-primary ${
                          loadingButton ? "" : "d-none"
                        }`}
                        type="button"
                        disabled={loadingButton}
                      >
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Menyimpan...
                      </button>
                      <button
                        type="submit"
                        className={`btn btn-sm btn-primary me-3 ${
                          loadingButton ? "d-none" : ""
                        }`}
                      >
                        Simpan
                      </button>
                      <Link
                        className="btn btn-sm  btn-secondary"
                        onClick={(e) => cancelSubmit(e)}
                      >
                        Batalkan
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddAccount;
