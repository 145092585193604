import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ErrorPage from "../../assets/images/not-found.svg";
const Error = ({ setSidebarAndNavbarVisibility, setLoading, loginStatus }) => {
  const location = useLocation();
  const validPaths = [
    "/dashboard",
    "/accounts",
    "/accounts/add",
    "/customers",
    "/customers/add",
    "/products",
    "/products/form",
    "/invoices",
    "/invoices/form",
    "/journals",
    "/journals/form",
    "/payments",
    "/payments/form",
    "/banks/payments",
    "/banks/payments/form",
    "/banks/receipts",
    "/banks/receipts/form",
    "/employees",
    "/employees/form",
    "/salaries",
    "/salaries/form",
    "/payrolls",
    "/payrolls/form",
    "/expenses",
    "/expenses/form",
    "/profit/reports",
    "/scales/reports",
    // ... tambahkan path lain yang valid
  ];
  const navigation = useNavigate();

  useEffect(() => {
    setSidebarAndNavbarVisibility(validPaths.includes(location.pathname));
  }, [location, setSidebarAndNavbarVisibility]);

  const backToHome = () => {
    if (!loginStatus) {
      navigation("/login");
      setSidebarAndNavbarVisibility(true);
    } else {
      setSidebarAndNavbarVisibility(true);
      setLoading(true);
      navigation("/dashboard");
    }
  };
  return (
    <>
      <div className="container">
        <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <h1>404</h1>
          {!loginStatus ? (
            <h2>Login terlebih dahulu jika ingin ke halaman ini !</h2>
          ) : (
            <h2>Halaman tidak ditemukan, harap periksa kembali url anda.</h2>
          )}
          <button class="btn btn-error" onClick={backToHome}>
            Back to home
          </button>
          <img
            src={ErrorPage}
            className="img-fluid py-5"
            alt="Page Not Found"
          />
          <div className="credits text-dark">
            Copyright
            <Link to="https://ramusen.com/"> PT. Ranajaya Mulia Sentosa</Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default Error;
