import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = apiDomain + "/banks";
const payment = URL + "/payments";
const receipts = URL + "/receipts";

// Payments Banks
const getBankPayments = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: payment + "/",
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};
const detailPayBank = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: payment + "/detail/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const addPayBank = async (obj) => {
  try {
    let result = await axios({
      method: "POST",
      url: payment + "/add",
      headers: { usertoken: localStorage.getItem("usertoken") },

      data: obj,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Ditambahkan",
      showConfirmButton: false,
      timer: 1500,
    });
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error);
    let message = "Terjadi kesalahan, silakan coba lagi.";
    // Cek jika error.response dan error.response.data ada
    if (error.response && error.response.data) {
      message = error.response.data.message || message;
    } else if (error.request) {
      // Error terkait dengan request yang dibuat tapi tidak mendapatkan response
      message = "Tidak ada response dari server.";
    } else {
      // Error yang terjadi sebelum melakukan request
      message = error.message || message;
    }

    Swal.fire({
      position: "center",
      icon: "error",
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
const updatePayBanks = async (data) => {
  console.log("Payload : ", data);
  try {
    let result = await axios({
      method: "PUT",
      url: payment + "/update",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: data,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dirubah",
      showConfirmButton: false,
      timer: 1500,
    });
    return true;
  } catch (err) {
    console.log(err);
    Swal.fire({
      position: "center",
      icon: "error",
      title: err.response.data.message,
      showConfirmButton: false,
      timer: 3000,
    });
  }
};

const deletePayBanks = async (data, cb) => {
  try {
    let result = await axios({
      method: "DELETE",
      url: payment + "/deletes",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: { paymentIds: data },
    });
    cb(result.data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dihapus",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (error) {
    console.log(error);
    let message = "Terjadi kesalahan, silakan coba lagi.";
    // Cek jika error.response dan error.response.data ada
    if (error.response && error.response.data) {
      message = error.response.data.message || message;
    } else if (error.request) {
      // Error terkait dengan request yang dibuat tapi tidak mendapatkan response
      message = "Tidak ada response dari server.";
    } else {
      // Error yang terjadi sebelum melakukan request
      message = error.message || message;
    }

    Swal.fire({
      position: "center",
      icon: "error",
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

const updatePaid = async (obj) => {
  try {
    let result = await axios({
      method: "POST",
      url: payment + "/paid",
      headers: { usertoken: localStorage.getItem("usertoken") },

      data: obj,
    });
    return true;
  } catch (error) {
    console.log(error);
    let message = "Terjadi kesalahan, silakan coba lagi.";
    // Cek jika error.response dan error.response.data ada
    if (error.response && error.response.data) {
      message = error.response.data.message || message;
    } else if (error.request) {
      // Error terkait dengan request yang dibuat tapi tidak mendapatkan response
      message = "Tidak ada response dari server.";
    } else {
      // Error yang terjadi sebelum melakukan request
      message = error.message || message;
    }

    Swal.fire({
      position: "center",
      icon: "error",
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

// Receipt Bank
const getBankReceipts = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: receipts + "/",
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};
const detailReceiptBank = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: receipts + "/detail/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};
const addReceiptBank = async (obj) => {
  try {
    let result = await axios({
      method: "POST",
      url: receipts + "/add",
      headers: { usertoken: localStorage.getItem("usertoken") },

      data: obj,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Ditambahkan",
      showConfirmButton: false,
      timer: 1500,
    });
    return true;
  } catch (error) {
    console.log(error);
    let message = "Terjadi kesalahan, silakan coba lagi.";
    // Cek jika error.response dan error.response.data ada
    if (error.response && error.response.data) {
      message = error.response.data.message || message;
    } else if (error.request) {
      // Error terkait dengan request yang dibuat tapi tidak mendapatkan response
      message = "Tidak ada response dari server.";
    } else {
      // Error yang terjadi sebelum melakukan request
      message = error.message || message;
    }

    Swal.fire({
      position: "center",
      icon: "error",
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
const updateReceiptBank = async (data) => {
  try {
    let result = await axios({
      method: "PUT",
      url: receipts + "/update",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: data,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dirubah",
      showConfirmButton: false,
      timer: 1500,
    });
    return true;
  } catch (err) {
    console.log(err);
    Swal.fire({
      position: "center",
      icon: "error",
      title: err.response.data.message,
      showConfirmButton: false,
      timer: 3000,
    });
  }
};
const deleteReceiptBank = async (data, cb) => {
  try {
    let result = await axios({
      method: "DELETE",
      url: receipts + "/deletes",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: { paymentIds: data },
    });
    cb(result.data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dihapus",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (error) {
    console.log(error);
    let message = "Terjadi kesalahan, silakan coba lagi.";
    // Cek jika error.response dan error.response.data ada
    if (error.response && error.response.data) {
      message = error.response.data.message || message;
    } else if (error.request) {
      // Error terkait dengan request yang dibuat tapi tidak mendapatkan response
      message = "Tidak ada response dari server.";
    } else {
      // Error yang terjadi sebelum melakukan request
      message = error.message || message;
    }

    Swal.fire({
      position: "center",
      icon: "error",
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
export {
  getBankPayments,
  updatePayBanks,
  deletePayBanks,
  addPayBank,
  detailPayBank,
  getBankReceipts,
  detailReceiptBank,
  addReceiptBank,
  updateReceiptBank,
  deleteReceiptBank,
  updatePaid,
};
