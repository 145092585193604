import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Icons from "react-bootstrap-icons";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { getRecentActivity } from "../../../fetch/widgetFetch";
import "./style.css";
const Activity = (props) => {
  const { username, isMoveable, setIsMoveable } = props;
  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);
  const [isMove, setIsMove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getRecent();
  }, []);
  const formatDate = (dateString) => {
    const days = [
      "Minggu",
      "Senin",
      "Selasa",
      "Rabu",
      "Kamis",
      "Jumat",
      "Sabtu",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ];

    const date = new Date(dateString);
    const today = new Date();

    // Format today's date for comparison
    const formattedToday = today.toISOString().split("T")[0];

    // Check if the provided date is today
    if (dateString === formattedToday) {
      const day = date.getDate();
      const month = months[date.getMonth()];
      return { hari: "Hari Ini", tanggal: day, bulan: month };
    } else {
      const dayName = days[date.getDay()];
      const day = date.getDate();
      const month = months[date.getMonth()];

      return { hari: dayName, tanggal: day, bulan: month };
    }
  };
  const getRecent = () => {
    getRecentActivity((result) => {
      if (result && Array.isArray(result)) {
        setData(result);
        const newDates = result.map((activity) => formatDate(activity.date));
        setDates(newDates);
        setIsLoading(false);
      }
    });
  };

  const handleRefresh = () => {
    setIsLoading(true);

    setTimeout(() => {
      getRecent();
    }, 1200);
  };

  const toggleMoveable = () => {
    setIsMoveable(!isMoveable);
  };

  return (
    <div className="card">
      <div className="filter">
        <Link onClick={handleRefresh} className="refresh-button">
          <Icons.ArrowClockwise className="icon-refresh" />
        </Link>
        <Dropdown drop="left">
          <Dropdown.Toggle
            id={`dropdown-widget-button-drop`}
            size="sm"
            className="icon"
          >
            <Icons.ThreeDots className="icon-three" />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="end"
            className="dropdown-menu dropdown-menu-arrow-right"
          >
            <Dropdown.Item className="dropdown-item" onClick={toggleMoveable}>
              <Icons.ArrowsMove size={14} className="me-2" />
              Move
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="card-body">
        <h5 className="card-title">
          Aktivitas Terakhir Anda <span>| {username}</span>
        </h5>
        <div className="activity-section">
          {isLoading ? (
            <div className="spinner-main-widget">
              <div className="loader-widget">
                <div className="bounce1-widget"></div>
                <div className="bounce2-widget"></div>
                <div className="bounce3-widget"></div>
              </div>
              <p>Memuat Data...</p>
            </div>
          ) : data.length === 0 ? (
            <div className="text-muted text-center">
              <p>Tidak ada aktivitas terakhir untuk ditampilkan.</p>
            </div>
          ) : (
            <>
              {data.map((dayActivity, index) => (
                <div key={dayActivity.date} className="day-activity">
                  <div className="date-section text-center">
                    <div className="date-hari">{dates[index]?.hari}</div>
                    <div className="date-day">{dates[index]?.tanggal}</div>
                    <div className="date-month">{dates[index]?.bulan}</div>
                  </div>
                  <div className="activities">
                    {dayActivity.activities.map((activity, idx) => (
                      <div key={idx} className="activity-item">
                        {/* Menambahkan elemen untuk timestamp di sini */}
                        <span className="activity-dot"></span>
                        <div className="activity-time">
                          {activity.timestamp}
                        </div>

                        <div className="activity-description">
                          {activity.description}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Activity;
