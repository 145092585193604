import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Icons from "react-bootstrap-icons";
import Swal from "sweetalert2";
import { AgGridReact } from "ag-grid-react";
import queryString from "query-string";
import {
  addExpense,
  detailExpense,
  updateExpense,
} from "../../../fetch/expenseFetch";
import { detailAccount, getAccounts } from "../../../fetch/accountFetch";
import { getToken } from "../../../fetch/userFetch";

const currencyFormatter = (params) => {
  if (params.value == null || isNaN(params.value)) {
    return "";
  }
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(params.value);
};
const ExpenseForm = () => {
  const [loading, setLoading] = useState(true);
  const [editable, setEditable] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const [account, setAccount] = useState({});
  const [valid, setValid] = useState({
    isLiablity: true,
    isDate: true,
    isExpense: true,
  });
  const [data, setData] = useState({
    liabilityAccountId: null,
    expenseDate: new Date(),
    AccountId: null,
    expenses: [],
  });
  const [gridOptions] = useState({
    overlayNoRowsTemplate:
      "<span>Sesuiakan Data yang Akan Dimasukan Disini.Klik Dua Kali Di Kolom Yang Ingin Diubah</span>",
    // ... properti gridOptions lainnya
  });

  const [rowData, setRowData] = useState([]);
  const [columnDefs] = useState([
    {
      field: "",
      rowDrag: true,
      width: 50,
    },
    {
      field: "Akun",
      sortable: true,
      width: 250,
    },
    {
      field: "Nama Akun",
      sortable: true,
      width: 250,
    },
    {
      field: "Nilai",
      headerName: "Nilai*",
      headerTooltip: "Klik dua kali pada kolom untuk mengedit",
      filter: true,
      sortable: true,
      editable: true,
      valueFormatter: currencyFormatter,
      width: 450,
    },
    {
      field: "id",
      headerName: "Hapus",
      cellRenderer: (params) => (
        <Link
          className="btn btn-sm "
          onClick={() => handleRemoveRow(params.data.uniqueKey)}
          style={{
            marginLeft: "20px",
            cursor: "pointer",
          }}
        >
          <Icons.XCircle size={20} color="red" />
        </Link>
      ),
      width: 120,
    },
  ]);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const expenseId = queryParams.expenseId;
  const navigation = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    getAccountsList();
    if (expenseId) {
      localStorage.setItem("expenseId", expenseId);
    }
    const storedProductId = localStorage.getItem("expenseId");
    if (storedProductId) {
      getOneExpense(storedProductId);
      setEditable(true);
    }
    const handleBeforeUnload = (e) => {
      if (isFormModified) {
        const message =
          "Perubahan belum disimpan. Anda yakin ingin meninggalkan halaman ini?";
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const getOneExpense = (data) => {
    detailExpense(data, (result) => {
      if (result) {
        const expenses = result;
        setData({
          liabilityAccountId: expenses[0].liabilityAccountId,
          expenseDate: new Date(expenses[0].expenseDate),
        });

        // Mengasumsikan bahwa result adalah array dari expense
        const newRows = expenses.map((expense) => ({
          uniqueKey: Date.now() + expense.id,
          id: expense.Account.id,
          Akun: expense.Account.AccountNumber,
          "Nama Akun": expense.Account.AccountName,
          Nilai: expense.amount,
        }));

        console.log(newRows);
        setRowData(newRows);
      }
    });
  };
  const getAccountsList = () => {
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        getAccounts(id, (cb) => {
          setAccount(cb);
        });
      });
    }
  };
  const handleLiablity = (selectedOption) => {
    setData({
      ...data,
      liabilityAccountId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isLiablity: selectedOption,
    });
    setIsFormModified(true);
  };
  const handleDateChange = (date) => {
    setData({
      ...data,
      expenseDate: date,
    });
    setIsFormModified(true);
  };
  const handleExpense = (selectedOption) => {
    setData({
      ...data,
      AccountId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isExpense: selectedOption,
    });
    setIsFormModified(true);
    if (selectedOption) {
      getDetailExpense(selectedOption.value);
    }
  };
  const getDetailExpense = (value) => {
    if (!data.liabilityAccountId) {
      // Tampilkan pesan kesalahan jika liabilityAccount kosong atau null
      Swal.fire({
        icon: "warning",
        title: "Akun Hutang Kosong",
        text: "Isi dahulu akun hutangnya.",
      });
      return; // Keluar dari fungsi
    }
    detailAccount(value, (cb) => {
      const isDuplicate = rowData.some((row) => row.id === cb.id);
      if (isDuplicate) {
        // Tampilkan pesan kesalahan
        Swal.fire({
          icon: "warning",
          title: "Duplikat Akun Beban",
          text: "Hanya satu Akun Beban yang diizinkan.",
        });
        return;
      }
      console.log(cb);
      const newExpense = {
        uniqueKey: Date.now(),
        id: cb.id,
        Akun: cb.AccountNumber,
        "Nama Akun": cb.AccountName,
        Nilai: 0,
      };
      setRowData([...rowData, newExpense]);
    });
  };
  const handleRemoveRow = (uniqueKey) => {
    // Pastikan Anda memanggil setRowData dengan fungsi updater
    // untuk mendapatkan state terkini dari rowData
    setRowData((currentRowData) => {
      const updatedData = currentRowData.filter(
        (row) => row.uniqueKey !== uniqueKey
      );
      return updatedData; // Return the filtered array
    });
  };
  const handleCellValueChanged = (event) => {
    const updatedData = rowData.map((row) => {
      if (row.uniqueKey === event.data.uniqueKey) {
        // Jika Kode cocok, perbarui data sesuai perubahan
        return {
          ...row,
          Nama: event.data.Nama,
          Kuantitas: event.data.Kuantitas,
          Harga: event.data.Harga,
          "Total Harga": event.data.Harga * event.data.Kuantitas,
        };
      }
      return row; // Jika Kode tidak cocok, biarkan data tetap seperti sebelumnya
    });

    setRowData(updatedData); // Perbarui state dengan data yang diperbarui
    setIsFormModified(true);
  };
  const cancelSubmit = (e) => {
    e.preventDefault();
    setIsFormModified(false);
    navigation("/expenses");
  };
  const addForm = (e) => {
    e.preventDefault();
    const { expenseDate, liabilityAccountId } = data;
    setLoadingButton(true);
    setIsFormModified(false);
    const isDataValid = validateData();
    if (!isDataValid) {
      setLoadingButton(false);
      return;
    }
    const payload = {
      expenseDate: expenseDate.toISOString().split("T")[0],
      liabilityAccountId: liabilityAccountId.toString(),
      expenses: rowData.map((row) => ({
        accountId: row.id,
        amount: row.Nilai.toString(),
      })),
    };
    console.log(payload);
    setTimeout(async () => {
      const result = await addExpense(payload);
      if (result) {
        setLoadingButton(false);
        navigation("/expenses");
      } else {
        setLoadingButton(false);
      }
    }, 3000);
  };

  const editForm = (e) => {
    e.preventDefault();
    console.log(e);
    const { expenseDate, liabilityAccountId } = data;
    setLoadingButton(true);
    setIsFormModified(false);
    const isDataValid = validateData();
    if (!isDataValid) {
      setLoadingButton(false);
      return;
    }
    const expenseNumber = localStorage.getItem("expenseId");
    const payload = {
        expenseNumber: expenseNumber,
      expenseDate: expenseDate,
      liabilityAccountId: liabilityAccountId,
      expenses: rowData.map((row) => ({
        accountId: row.id,
        amount: row.Nilai.toString(),
      })),
    };
    setTimeout(async () => {
      const pay = await updateExpense(payload);
      if (pay) {
        setLoadingButton(false);
        navigation("/expenses");
      } else {
        setLoadingButton(false);
      }
    }, 3000);
  };
  const validateData = () => {
    let errorMessage = "";

    let isValid = true;

    // Contoh validasi: cek apakah setiap row memiliki item yang tidak kosong
    for (const row of rowData) {
      if (!row.Nilai) {
        isValid = false;
        errorMessage = "Setiap Akun Beban yang dipilih harus memiliki nilai .";
        break;
      }
    }
    if (rowData.length === 0) {
      isValid = false;
      errorMessage = "Akun Beban tidak boleh kosong .";
    }

    if (!isValid) {
      // Tampilkan pesan kesalahan atau lakukan tindakan validasi lainnya
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Validasi Gagal",
        text: errorMessage,
        showConfirmButton: false,
        timer: 1500,
      });
    }

    return isValid;
  };
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Pencatatan Beban</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/payrolls">Pencatatan Beban</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {editable ? "Edit" : "Add"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="formPayroll col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {editable ? "Edit Pencatatan Beban" : "Pencatatan Beban Baru"}
                </h5>
                <hr />
                <form id="formPayroll" onSubmit={editable ? editForm : addForm}>
                  <div className="row mb-3">
                    <label
                      htmlFor="liablitiySelect"
                      className="col-sm-2 col-form-label"
                    >
                      Akun Hutang<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        id="liablitiySelect"
                        key={data.liabilityAccountId || "no-value"}
                        options={account
                          .filter(
                            (acc) =>
                              acc.AccountType === "Liabilitas Jangka Pendek"
                          )
                          .map((acc) => ({
                            label: acc.AccountName,
                            value: acc.id,
                          }))}
                        value={
                          data.liabilityAccountId
                            ? {
                                label: account.find(
                                  (acc) => acc.id === data.liabilityAccountId
                                )?.AccountName,
                                value: data.liabilityAccountId,
                              }
                            : null
                        }
                        onChange={handleLiablity}
                        className={
                          !valid.isLiablity
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Akun Hutang..."
                        isClearable={true}
                        isDisabled={editable}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        required
                      />
                      {!valid.isLiablity && (
                        <div className="invalid-feedback">
                          Akun Hutang Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="payrollDate"
                      className="col-sm-2 col-form-label"
                    >
                      Tanggal<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <ReactDatePicker
                        id="payrollDate"
                        selected={data.expenseDate}
                        onChange={handleDateChange}
                        className="form-control"
                        dateFormat="dd/MM/yyyy" // Format date in the day/month/year format
                        placeholderText="Pilih Tanggal"
                        customInput={<input readOnly={editable} />}
                        required
                      />
                      {!valid.isDate && (
                        <div className="invalid-feedback">
                          Tanggal Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="expenseSelect"
                      className="col-sm-2 col-form-label"
                    >
                      Akun Beban<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        id="expenseSelect"
                        key={data.AccountId || "no-value"}
                        options={account
                          .filter(
                            (acc) =>
                              acc.AccountType === "Beban" ||
                              acc.AccountType === "Beban Pokok Penjualan" ||
                              acc.AccountType === "Beban Lainnya"
                          )
                          .map((acc) => ({
                            label: acc.AccountName,
                            value: acc.id,
                          }))}
                        value={
                          data.AccountId
                            ? {
                                label: account.find(
                                  (acc) => acc.id === data.AccountId
                                )?.AccountName,
                                value: data.AccountId,
                              }
                            : null
                        }
                        onChange={handleExpense}
                        className={
                          !valid.isExpense
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Akun Beban..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        required={editable ? false : true}
                      />
                      {!valid.isExpense && (
                        <div className="invalid-feedback">
                          Akun Beban Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div
                      style={{ width: "100%", height: "100%", zIndex: "0" }}
                      className="ag-theme-material"
                    >
                      <AgGridReact
                        rowData={rowData}
                        rowSelection={"multiple"}
                        animateRows={true}
                        columnDefs={columnDefs}
                        rowDragManaged={true}
                        tooltipShowDelay={0}
                        rowDragMultiRow={true}
                        cacheBlockSize={10}
                        gridOptions={gridOptions}
                        onCellValueChanged={handleCellValueChanged}
                        domLayout="autoHeight"
                      ></AgGridReact>
                    </div>
                  </div>
                  <div className="row mb-3 ">
                    <span style={{ fontSize: 14, fontStyle: "italic" }}>
                      <span className="text-danger">*</span> Kolom harus diisi
                      dengan cara mengklik dua kali kolom
                    </span>
                  </div>
                  <div className="row mb-3 submit-button">
                    <div className="col-sm-10">
                      <button
                        className={`btn btn-sm me-3 btn-primary ${
                          loadingButton ? "" : "d-none"
                        }`}
                        type="button"
                        disabled={loadingButton}
                      >
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Menyimpan...
                      </button>
                      <button
                        type="submit"
                        className={`btn btn-sm btn-primary me-3 ${
                          loadingButton ? "d-none" : ""
                        }`}
                      >
                        Simpan
                      </button>
                      <Link
                        className="btn btn-sm  btn-secondary"
                        onClick={(e) => cancelSubmit(e)}
                      >
                        Batalkan
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ExpenseForm;
