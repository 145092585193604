import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = apiDomain + "/tenants";

const getTenant = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL,
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    let message = "Terjadi kesalahan, silakan coba lagi.";
    // Cek jika error.response dan error.response.data ada
    if (error.response && error.response.data) {
      message = error.response.data.message || message;
    } else if (error.request) {
      // Error terkait dengan request yang dibuat tapi tidak mendapatkan response
      message = "Tidak ada response dari server.";
    } else {
      // Error yang terjadi sebelum melakukan request
      message = error.message || message;
    }

    Swal.fire({
      position: "center",
      icon: "error",
      text: error.response.data.error,
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

const addTenant = async (id, obj) => {
  try {
    console.log("Data : ", obj);
    console.log("ID : ", id);
    let result = await axios({
      method: "POST",
      url: URL + "/add/" + id,
      data: obj,
    });
    return true;
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
export { getTenant, addTenant };
