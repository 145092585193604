import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const URL = apiDomain + "/payments";

const getPayments = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/",
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};
const getInvoiceNumber = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/getInvoice/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const detailPayment = async (id, cb) => {
  try {
    let result = await axios({
      method: "GET",
      url: URL + "/detail/" + id,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const addPayments = async (obj) => {
  try {
    let result = await axios({
      method: "POST",
      url: URL + "/add",
      headers: { usertoken: localStorage.getItem("usertoken") },

      data: obj,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Ditambahkan",
      showConfirmButton: false,
      timer: 1500,
    });
    return true;
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
const updatePayment = async (data) => {
  try {
    let result = await axios({
      method: "PUT",
      url: URL + "/update",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: data,
    });
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dirubah",
      showConfirmButton: false,
      timer: 1500,
    });
    return true;
  } catch (err) {
    console.log(err);
    Swal.fire({
      position: "center",
      icon: "error",
      title: err.response.data.message,
      showConfirmButton: false,
      timer: 3000,
    });
  }
};

const deletePayments = async (data, cb) => {
  try {
    let result = await axios({
      method: "DELETE",
      url: URL + "/deletes",
      headers: {
        usertoken: localStorage.getItem("usertoken"),
      },
      data: { ids: data },
    });
    cb(result.data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Data Berhasil Dihapus",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (err) {
    console.log(err);
    Swal.fire({
      position: "center",
      icon: "error",
      title: err.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

export {
  getPayments,
  getInvoiceNumber,
  updatePayment,
  deletePayments,
  addPayments,
  detailPayment,
};
