import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import * as Icons from "react-bootstrap-icons";

import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getToken } from "../../../fetch/userFetch";
import { detailAccount, getAccounts } from "../../../fetch/accountFetch";
import { AgGridReact } from "ag-grid-react";
import {
  addJournals,
  detailJournal,
  updateJournals,
} from "../../../fetch/journalFetch";
import queryString from "query-string";
import Swal from "sweetalert2";
const currencyFormatter = (params) => {
  if (params.value == null || isNaN(params.value)) {
    return "";
  }
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(params.value);
};
const FormJournal = () => {
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [editable, setEditable] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const [accounts, setAccounts] = useState({});
  const [valid, setValid] = useState({
    isAccount: true,
    isDate: true,
    isProduct: true,
  });
  const [data, setData] = useState({
    date: new Date(),
    journalDetails: [],
    accountId: null,
  });
  const [gridOptions] = useState({
    overlayNoRowsTemplate:
      '<span style="padding: 10px; border: 1px solid #444; background: lightgoldenrodyellow;">Sesuiakan Data yang Akan Dimasukan Disini.Klik Dua Kali Di Kolom Yang Ingin Diubah</span>',
    // ... properti gridOptions lainnya
  });

  const [rowData, setRowData] = useState([]);
  const [columnDefs] = useState([
    {
      field: "",
      rowDrag: true,
      width: 50,
    },
    {
      field: "Kode",
      filter: true,
      sortable: true,
      width: 150,
    },
    {
      field: "Nama",
      sortable: true,
      width: 400,
    },
    {
      field: "Debit",
      headerName: "Debit*",
      headerTooltip: "Klik dua kali pada kolom untuk mengedit",
      filter: true,
      sortable: true,
      valueFormatter: currencyFormatter,
      width: 200,
      editable: (params) => {
        // Mengembalikan true jika kolom "Debit" kosong
        return !params.data.Kredit;
      },
    },
    {
      field: "Kredit",
      headerName: "Kredit*",
      filter: true,
      headerTooltip: "Klik dua kali pada kolom untuk mengedit",
      sortable: true,
      valueFormatter: currencyFormatter,
      width: 200,
      editable: (params) => {
        // Mengembalikan true jika kolom "Debit" kosong
        return !params.data.Debit;
      },
    },
    {
      field: "id",
      headerName: "Hapus",
      cellRenderer: (params) => (
        <Link
          className="btn btn-sm "
          onClick={() => handleRemoveRow(params.data.uniqueKey)}
          style={{
            marginLeft: "20px",
            cursor: "pointer",
          }}
        >
          <Icons.XCircle size={20} color="red" />
        </Link>
      ),
      width: 120,
    },
  ]);
  const navigation = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const journalId = queryParams.journalId;
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    getAccountsList();
    if (journalId) {
      localStorage.setItem("journalId", journalId);
    }
    const storedProductId = localStorage.getItem("journalId");
    if (storedProductId) {
      getJournalDetail(storedProductId);
      setEditable(true);
    }
    const handleBeforeUnload = (e) => {
      if (isFormModified) {
        const message =
          "Perubahan belum disimpan. Anda yakin ingin meninggalkan halaman ini?";
        e.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      clearTimeout(timer);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const getJournalDetail = (id) => {
    detailJournal(id, (result) => {
      if (result && Array.isArray(result)) {
        result.forEach((journal) => {
          setData({
            accountId: journal.Account.id,
            date: new Date(journal.JournalEntry.Date),
          });
          const newRows = result.map((journal, index) => ({
            uniqueKey: new Date(),
            id: journal.Account.id,
            Nama: journal.Account.AccountName,
            Kode: journal.Account.AccountNumber,
            Debit: parseInt(journal.Debit),
            Kredit: parseInt(journal.Credit),
          }));
          console.log(newRows);
          setRowData(newRows);
        });
      }
    });
  };
  const getAccountsList = () => {
    const userToken = localStorage.getItem("usertoken");
    if (!userToken) {
      console.log("No user token found.");
      // Set default state or perform other error handling
      return;
    }

    getToken(userToken, async (result) => {
      if (!result || !result.id) {
        console.log("Invalid token or user ID not found in token.");
        // Set default state or perform other error handling
        return;
      }

      await getAccounts(result.id, (cb) => {
        if (!cb) {
          console.log("Failed to fetch accounts list.");
          // Set default state or perform other error handling
          return;
        }
        setAccounts(cb);
      });
    });
  };

  const handleDateChange = (date) => {
    setData({
      ...data,
      date: date,
    });

    setIsFormModified(true);
  };
  const handleAccounts = (selectedOption) => {
    setData({
      ...data,
      accountId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isAccount: selectedOption,
    });
    setIsFormModified(true);
    if (selectedOption) {
      getDetailAccount(selectedOption.value);
    }
  };
  const getDetailAccount = (value) => {
    detailAccount(value, (cb) => {
      const isDuplicate = rowData.some((row) => row.Kode === cb.AccountNumber);
      if (isDuplicate) {
        // Tampilkan pesan kesalahan
        Swal.fire({
          icon: "error",
          title: "Duplikat Akun Perkiraan",
          text: "Hanya satu akun perkiraan dengan kode tertentu yang diizinkan.",
        });
        return; // Jangan tambahkan akun ke rowData
      }

      const newProduct = {
        uniqueKey: Date.now(),
        id: cb.id,
        Nama: cb.AccountName,
        Kode: cb.AccountNumber,
        Debit: 0,
        Kredit: 0,
      };
      setRowData([...rowData, newProduct]);
    });
  };
  const handleCellValueChanged = (event) => {
    const updatedData = rowData.map((row) => {
      if (row.uniqueKey === event.data.uniqueKey) {
        // Jika Kode cocok, perbarui data sesuai perubahan
        return {
          ...row,
          Nama: event.data.Nama,
          Kuantitas: event.data.Kuantitas,
          Harga: event.data.Harga,
          "Total Harga": event.data.Harga * event.data.Kuantitas,
        };
      }
      return row; // Jika Kode tidak cocok, biarkan data tetap seperti sebelumnya
    });

    setRowData(updatedData); // Perbarui state dengan data yang diperbarui
    setIsFormModified(true);
  };
  const handleRemoveRow = (uniqueKey) => {
    // Pastikan Anda memanggil setRowData dengan fungsi updater
    // untuk mendapatkan state terkini dari rowData
    setRowData((currentRowData) => {
      const updatedData = currentRowData.filter(
        (row) => row.uniqueKey !== uniqueKey
      );
      return updatedData; // Return the filtered array
    });
  };
  const cancelSubmit = (e) => {
    e.preventDefault();
    setIsFormModified(false);
    navigation("/journals");
  };
  const addForm = (e) => {
    e.preventDefault();
    const { date } = data;
    setLoadingButton(true);
    setIsFormModified(false);
    const isDataValid = validateData();
    if (!isDataValid) {
      setLoadingButton(false);
      return;
    }
    const payload = {
      date: date.toISOString().split("T")[0],
      journalDetails: rowData.map((row) => ({
        accountId: row.id.toString(),
        debit: row.Debit.toString(),
        credit: row.Kredit.toString(),
      })),
    };
    setTimeout(async () => {
      const success = await addJournals(payload);
      setLoadingButton(false);

      if (success) {
        // Jika berhasil, arahkan ke "/journals"
        navigation("/journals");
      } else {
        // Jika gagal, Anda dapat menambahkan pesan kesalahan atau tindakan lain yang sesuai
        console.error("Gagal menambahkan jurnal.");
      }
    }, 3000);
  };
  const editForm = (e) => {
    e.preventDefault();
    const { date } = data;
    setLoadingButton(true);
    setIsFormModified(false);
    const isDataValid = validateData();
    if (!isDataValid) {
      setLoadingButton(false);
      return;
    }
    const idJournal = localStorage.getItem("journalId");

    const payload = {
      journalEntryId: idJournal,
      date: date.toISOString().split("T")[0],
      journalDetails: rowData.map((row) => ({
        accountId: row.id.toString(),
        debit: row.Debit.toString(),
        credit: row.Kredit.toString(),
      })),
    };

    setTimeout(async () => {
      const success = await updateJournals(payload);
      setLoadingButton(false);

      if (success) {
        navigation("/journals");
      } else {
        console.error("Gagal menambahkan jurnal.");
      }
    }, 3000);
  };
  const validateData = () => {
    let errorMessage = "";

    let isValid = true;

    // Contoh validasi: cek apakah setiap row memiliki item yang tidak kosong
    for (const row of rowData) {
      if (!row.Debit && !row.Kredit) {
        isValid = false;
        errorMessage =
          "Setiap Akun Perkiraan harus memiliki nilai untuk 'Debit' atau 'Kredit'.";
        break;
      }
    }
    if (rowData.length === 0) {
      isValid = false;
      errorMessage = "Akun Perkiraan tidak boleh kosong .";
    }

    if (!isValid) {
      // Tampilkan pesan kesalahan atau lakukan tindakan validasi lainnya
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Validasi Gagal",
        text: errorMessage,
        showConfirmButton: false,
        timer: 1500,
      });
    }

    return isValid;
  };
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Jurnal Umum</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/invoices">Jurnal Umum</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {editable ? "Edit" : "Add"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="form col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {editable ? "Edit Jurnal Umum" : "Jurnal Umum Baru"}
                </h5>
                <hr />
                <form id="formJournal" onSubmit={editable ? editForm : addForm}>
                  <div className="row mb-3">
                    <label
                      htmlFor="journalDate"
                      className="col-sm-2 col-form-label"
                    >
                      Tanggal<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <ReactDatePicker
                        id="journalDate"
                        selected={data.date}
                        onChange={handleDateChange}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Pilih Tanggal"
                        disabled={editable}
                        customInput={<input readOnly={editable} />}
                        required
                      />
                      {!valid.isDate && (
                        <div className="invalid-feedback">
                          Tanggal Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="accountSelect"
                      className="col-sm-2 col-form-label"
                    >
                      Akun Perkiraan<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        id="accountSelect"
                        key={data.accountId || "no-value"}
                        options={accounts.map((account) => ({
                          label: account.AccountName,
                          value: account.id,
                        }))}
                        value={
                          data.accountId
                            ? {
                                label: accounts.find(
                                  (account) => account.id === data.accountId
                                )?.AccountName,
                                value: data.accountId,
                              }
                            : null
                        }
                        onChange={handleAccounts}
                        className={
                          !valid.isAccount
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Akun Perkiraan..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        required
                      />
                      {!valid.isAccount && (
                        <div className="invalid-feedback">
                          Akun Perkiraan Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-5 mb-5">
                    <div
                      style={{ width: "100%", height: "100%" }}
                      className="ag-theme-material"
                    >
                      <AgGridReact
                        rowData={rowData}
                        rowSelection={"multiple"}
                        tooltipShowDelay={0}
                        animateRows={true}
                        columnDefs={columnDefs}
                        rowDragManaged={true}
                        rowDragMultiRow={true}
                        cacheBlockSize={10}
                        gridOptions={gridOptions}
                        onCellValueChanged={handleCellValueChanged}
                        domLayout="autoHeight"
                      ></AgGridReact>
                    </div>
                  </div>
                  <div className="row mb-3 ">
                    <span style={{ fontSize: 14, fontStyle: "italic" }}>
                      <span className="text-danger">*</span> Kolom harus diisi
                      dengan cara mengklik dua kali kolom
                    </span>
                  </div>
                  <div className="row mb-3 submit-button">
                    <div className="col-sm-10">
                      <button
                        className={`btn btn-sm me-3 btn-primary ${
                          loadingButton ? "" : "d-none"
                        }`}
                        type="button"
                        disabled={loadingButton}
                      >
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Menyimpan...
                      </button>
                      <button
                        type="submit"
                        className={`btn btn-sm btn-primary me-3 ${
                          loadingButton ? "d-none" : ""
                        }`}
                      >
                        Simpan
                      </button>
                      <Link
                        className="btn btn-sm  btn-secondary"
                        onClick={(e) => cancelSubmit(e)}
                      >
                        Batalkan
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FormJournal;
