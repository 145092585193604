import axios from "axios";
import Swal from "sweetalert2";

const apiDomain = process.env.REACT_APP_API_DOMAIN;

const getRecentActivity = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      headers: { usertoken: localStorage.getItem("usertoken") },
      url: apiDomain + "/recents",
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
const getProfitYear = async (year, cb) => {
  try {
    let result = await axios({
      method: "POST",
      url: apiDomain + "/accounts/profit",
      data: year,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const getCashFlow = async (period, cb) => {
  try {
    let result = await axios({
      method: "POST",
      url: apiDomain + "/accounts/cash",
      data: period,
      headers: { usertoken: localStorage.getItem("usertoken") },
    });
    cb(result.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: error.response.data.message,
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

export { getRecentActivity, getProfitYear, getCashFlow };
