import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import * as Icons from "react-bootstrap-icons";
import { AgGridReact } from "ag-grid-react";
import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import { HiDotsHorizontal } from "react-icons/hi";

import "./style.css";
import { deleteCustomers, getCustomers } from "../../fetch/customerFetch";
import { getToken } from "../../fetch/userFetch";
import Swal from "sweetalert2";
const Customer = (props) => {
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [ready, setReady] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const { customerId, setcustomerId } = props;
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const [rowData, setRowData] = useState([
    {
      "ID Pelanggan": 0,
      Nama: "",
      Saldo: "",
      Email: "",
      Telephone: "",
      Status: "",
    },
  ]);
  const currencyFormatter = (params) => {
    if (params.value == null || isNaN(params.value)) {
      return "";
    }
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(params.value);
  };
  const [columnDefs] = useState([
    {
      field: "ID Pelanggan",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      sortable: true,
      width: 270,
    },
    {
      field: "Nama",
      filter: true,
      sortable: true,
      width: 270,
      editable: true,
    },
    {
      field: "Email",
      filter: true,
      sortable: true,
      hide: true,
      width: 270,
      editable: true,
    },
    {
      field: "Telephone",
      filter: "agSetColumnFilter",
      sortable: true,
      editable: true,
      width: 270,
    },
    {
      field: "Status",
      filter: "agSetColumnFilter",
      editable: true,
      sortable: true,
      hide: true,
    },
    {
      field: "Saldo",
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: currencyFormatter,
      width: 300,
    },
  ]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    const userToken = localStorage.getItem("usertoken");
    localStorage.removeItem("customerId");

    if (userToken) {
      getToken(userToken, (cb) => {
        if (cb && cb.id) {
          getCustomers(cb.id, (result) => {
            if (result && Array.isArray(result)) {
              const formattedData = result.map((customer, idx) => ({
                id: customer.id,
                "ID Pelanggan": customer.CustomerId || 0,
                Nama: customer.Name || "",
                Email: customer.Email || "Belum Diisi",
                Telephone: customer.Phone || "Belum Diisi",
                Saldo: customer.Balance || "",
                Status: customer.IsActive ? "Aktif" : "Tidak Aktif",
              }));
              setRowData(formattedData);
              if (gridApi) {
                gridApi.setRowData([...formattedData]);
              }
              setReady(formattedData.length > 0);
            } else {
              setReady(false);
            }
          });
        }
      });
    }
    return () => clearTimeout(timer);
  }, []);
  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
  };
  const handleBulkDelete = useCallback(() => {
    const selectedIds = selectedRows.map((row) => row.id);

    if (selectedRows.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Tidak Ada Baris Yang Dipilih !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    Swal.fire({
      title: "Are you sure to delete ?",
      text: "Are You Sure ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCustomers(selectedIds, (result) => {
            if (result.status === 204 || result.status === 200) {
              const newRows = rowData.filter(
                (row) => !selectedIds.includes(row.id)
              );
              setRowData(newRows);
              setSelectedRows([]);
            }
          });
        } catch (error) {
          const errorMsg =
            error.message || "Terjadi kesalahan saat menghapus akun.";
        }
      }
    });
  }, [selectedRows, rowData]);

  const handleEdit = () => {
    const selectedIds = selectedRows.map((row) => row.id);

    if (selectedRows.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Tidak Ada Baris Yang Dipilih !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    if (selectedRows.length > 1) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Hanya Satu yang bisa Diedit !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    Swal.fire({
      title: "Are you sure to Edited This ?",
      text: "Are You Sure ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setcustomerId(selectedIds[0]);
        navigate("/customers/add");
      }
    });
  };

  const navigate = useNavigate();
  const handleNew = () => {
    setcustomerId(0);
    navigate("/customers/add");
  };
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Pelanggan</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Pelanggan</li>
                </ol>
              </nav>
            </div>
            <div className="action-button">
              <Link
                className="btn btn-sm btn-primary mx-3"
                onClick={(e) => {
                  handleNew();
                  e.preventDefault();
                }}
              >
                <Icons.PlusLg className="me-1" /> Baru
              </Link>
              {/* <Link
                className="btn btn-sm btn-danger"
                onClick={handleBulkDelete}
              >
                <Icons.XLg className="me-1" /> Delete
              </Link> */}
              <DropdownButton
                as={ButtonGroup}
                id={`dropdown-button-drop`}
                size="sm"
                title={<HiDotsHorizontal color="black" size="18px" />}
                className="no-chevron-dropdown"
              >
                <Dropdown.Item onClick={handleEdit}>
                  <Icons.Pencil /> Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={handleBulkDelete}>
                  <Icons.Trash /> Delete
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          {ready ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{ width: "100%", height: "100%" }}
                className="ag-theme-material"
              >
                <AgGridReact
                  rowData={rowData}
                  rowSelection={"multiple"}
                  animateRows={true}
                  columnDefs={columnDefs}
                  rowDragManaged={true}
                  rowDragMultiRow={true}
                  onSelectionChanged={onSelectionChanged}
                  cacheBlockSize={10}
                  onGridReady={onGridReady}
                  domLayout="autoHeight"
                  pagination={true}
                  paginationPageSize={10}
                ></AgGridReact>
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  padding: "20px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <h5
                  style={{
                    fontSize: "1h5x",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Pelanggan Tidak Ditemukan
                </h5>
                <small className="text-muted my-5">
                  Buat Pelanggan Terlebih Dahulu Untuk Melakukan Traksasi
                </small>
                <br></br>
                <button className=" mt-5 btn btn-primary" onClick={handleNew}>
                  Buat Pelanggan Baru
                </button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Customer;
