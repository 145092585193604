import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Icons from "react-bootstrap-icons";
import "./style.css";
function Sidebar() {
  const [openedSection, setOpenedSection] = useState(null);
  const [active, setActive] = useState(null);
  const toggleCollapse = (section) => {
    if (openedSection === section) {
      setOpenedSection(null);
    } else {
      setOpenedSection(section);
    }
  };
  const toggleActive = (section) => {
    setActive(section);
  };

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {/* Dashboard Nav */}
        <li className="nav-item">
          <Link
            to="/dashboard"
            className={`nav-link ${active === "dashboard" ? "active" : ""}`}
            onClick={() => {
              toggleCollapse(null);
              toggleActive("dashboard");
            }}
          >
            <Icons.Grid className="icon-dashboard" />
            <span>Dashboard</span>
          </Link>
        </li>

        <li className="nav-heading">General</li>

        {/* Company Nav */}
        <li className="nav-item">
          <div
            className={`nav-link ${openedSection === "company" ? "collapsed" : ""}`}
            onClick={() => toggleCollapse("company")}
          >
            <Icons.Buildings className="icon-dashboard" />
            <span>Perusahaan </span>
            <Icons.ChevronDown className="ms-auto icon-chevron" />
          </div>
          <ul
            id="company-nav"
            className={`nav-content collapse ${
              openedSection === "company" ? "show" : ""
            }`}
          >
            <li>
              <Link
                to="/employees"
                onClick={() => toggleActive("employees")}
                className={`${active === "employees" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Karyawan</span>
              </Link>
            </li>
            <li>
              <Link
                to="/salaries"
                onClick={() => toggleActive("salaries")}
                className={`${active === "salaries" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Gaji/Tunjangan</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-heading">Accounts</li>

        {/* Big Books Nav */}
        <li className="nav-item">
          <div
            className={`nav-link ${
              openedSection === "books" ? "collapsed" : ""
            }`}
            onClick={() => toggleCollapse("books")}
          >
            <Icons.JournalBookmark className="icon-dashboard" />
            <span>Buku Besar</span>
            <Icons.ChevronDown className="ms-auto icon-chevron" />
          </div>
          <ul
            id="penjualan-nav"
            className={`nav-content collapse ${
              openedSection === "books" ? "show" : ""
            }`}
          >
            <li>
              <Link
                to="/accounts"
                onClick={() => toggleActive("accounts")}
                className={`${active === "accounts" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Akun Perkiraan</span>
              </Link>
            </li>
            <li>
              <Link
                to="/journals"
                onClick={() => toggleActive("journals")}
                className={`${active === "journals" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Jurnal Umum</span>
              </Link>
            </li>
            <li>
              <Link
                to="/expenses"
                onClick={() => toggleActive("expenses")}
                className={`${active === "expenses" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Pencatatan Beban</span>
              </Link>
            </li>
            <li>
              <Link
                to="/payrolls "
                onClick={() => toggleActive("payrolls ")}
                className={`${active === "payrolls " ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Pencatatan Gaji</span>
              </Link>
            </li>
          </ul>
        </li>
        {/* Bank Nav */}
        <li className="nav-item">
          <div
            className={`nav-link ${
              openedSection === "banks" ? "collapsed" : ""
            }`}
            onClick={() => toggleCollapse("banks")}
          >
            <Icons.Bank className="icon-dashboard" />
            <span>Kas & Bank</span>
            <Icons.ChevronDown className="ms-auto icon-chevron" />
          </div>
          <ul
            id="pembayaran-nav"
            className={`nav-content collapse ${
              openedSection === "banks" ? "show" : ""
            }`}
          >
            <li>
              <Link
                to="/banks/payments"
                onClick={() => toggleActive("paymentBanks")}
                className={`${active === "paymentBanks" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Pembayaran</span>
              </Link>
            </li>
            <li>
              <Link
                to="/banks/receipts"
                onClick={() => toggleActive("receipts")}
                className={`${active === "receipts" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Penerimaan</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-heading">Transaksi</li>

        {/* Penjualan Nav */}
        <li className="nav-item">
          <div
            className={`nav-link ${
              openedSection === "penjualan" ? "collapsed" : ""
            }`}
            onClick={() => toggleCollapse("penjualan")}
          >
            <Icons.Cart className="icon-dashboard" />
            <span>Penjualan</span>
            <Icons.ChevronDown className="ms-auto icon-chevron" />
          </div>
          <ul
            id="components-nav"
            className={`nav-content collapse ${
              openedSection === "penjualan" ? "show" : ""
            }`}
          >
            <li>
              <Link
                to="/customers"
                onClick={() => toggleActive("vendor")}
                className={`${active === "vendor" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Pelanggan</span>
              </Link>
            </li>
            <li>
              <Link
                to="/products"
                onClick={() => toggleActive("products")}
                className={`${active === "products" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Barang & Jasa</span>
              </Link>
            </li>
            <li>
              <Link
                to="/invoices"
                onClick={() => toggleActive("invoice")}
                className={`${active === "invoice" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Faktur Penjualan</span>
              </Link>
            </li>
            <li>
              <Link
                to="/payments"
                onClick={() => toggleActive("payments")}
                className={`${active === "payments" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Penerimaan Penjualan</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="nav-heading">Pelaporan</li>
        {/* Lapora Nav  */}
        <li className="nav-item">
          <div
            className={`nav-link ${
              openedSection === "reports" ? "collapsed" : ""
            }`}
            onClick={() => toggleCollapse("reports")}
          >
            <Icons.FileEarmarkBarGraphFill className="icon-dashboard" />
            <span>Laporan Keuangan</span>
            <Icons.ChevronDown className="ms-auto icon-chevron" />
          </div>
          <ul
            id="components-nav"
            className={`nav-content collapse ${
              openedSection === "reports" ? "show" : ""
            }`}
          >
            <li>
              <Link
                to="/profit/reports"
                onClick={() => toggleActive("profit")}
                className={`${active === "profit" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Laporan Laba/Rugi</span>
              </Link>
            </li>
            <li>
              <Link
                to="/scales/reports"
                onClick={() => toggleActive("scales")}
                className={`${active === "scales" ? "active" : ""}`}
              >
                <Icons.Circle className="icon-circle" />
                <span>Laporan Neraca Keuangan</span>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </aside>
  );
}

export default Sidebar;
