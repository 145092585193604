import React, { useCallback, useEffect, useState } from "react";
import { getCustomers } from "../../../fetch/customerFetch";
import { getToken } from "../../../fetch/userFetch";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import {
  addInvoice,
  detailInvoice,
  getInvoiceNumber,
  updateInvoices,
} from "../../../fetch/invoiceFetch";
import { Tooltip } from "react-tooltip";
import { AgGridReact } from "ag-grid-react";

import * as Icons from "react-bootstrap-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { detailProduct, getProducts } from "../../../fetch/productFetch";
import queryString from "query-string";
import Swal from "sweetalert2";

const currencyFormatter = (params) => {
  if (params.value == null || isNaN(params.value)) {
    return "";
  }
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(params.value);
};
const formatDateToIndonesian = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("id-ID", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
const addOneMonth = (date) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + 1);
  return newDate;
};
const FormInvoice = () => {
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [editable, setEditable] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const [customer, setCustomer] = useState({});
  const [product, setProduct] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceIds, setInvoiceIds] = useState("");
  const [valid, setValid] = useState({
    isCustomer: true,
    isDate: true,
    isProduct: true,
  });
  const [data, setData] = useState({
    CustomerId: null,
    saleDate: new Date(),
    DueDate: addOneMonth(new Date()),
    ItemId: null,
    numberInvoice: null,
    items: [],
    Status: "",
  });
  const [gridOptions] = useState({
    overlayNoRowsTemplate:
      '<span style="padding: 10px; border: 1px solid #444; background: lightgoldenrodyellow;">Sesuiakan Data yang Akan Dimasukan Disini.Klik Dua Kali Di Kolom Yang Ingin Diubah</span>',
    // ... properti gridOptions lainnya
  });

  const [rowData, setRowData] = useState([]);
  const [columnDefs] = useState([
    {
      field: "",
      rowDrag: true,
      width: 50,
    },
    {
      field: "Nama",
      sortable: true,
      width: 220,
    },
    {
      field: "Kode",
      filter: true,
      sortable: true,
      width: 150,
    },
    {
      field: "Harga",
      headerName: "Harga*",
      filter: true,
      sortable: true,
      valueFormatter: currencyFormatter,
      width: 200,
      editable: true,
      headerTooltip: "Klik dua kali pada kolom untuk mengedit",
    },
    {
      field: "Kuantitas",
      headerName: "Kuantitas*",
      filter: true,
      sortable: true,
      width: 150,
      headerTooltip: "Klik dua kali pada kolom untuk mengedit",
      editable: true,
    },

    {
      field: "Total Harga",
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: currencyFormatter,
      width: 220,
    },
    {
      field: "id",
      headerName: "Hapus",
      cellRenderer: (params) => (
        <Link
          className="btn btn-sm "
          onClick={() => handleRemoveRow(params.data.uniqueKey)}
          style={{
            marginLeft: "20px",
            cursor: "pointer",
          }}
        >
          <Icons.XCircle size={20} color="red" />
        </Link>
      ),
      width: 135,
    },
  ]);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const invoiceId = queryParams.invoiceId;
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    getCustomer();
    getProductList();
    setInvoiceIds(invoiceId);
    if (invoiceId) {
      localStorage.setItem("invoiceId", invoiceId);
    }
    const storedProductId = localStorage.getItem("invoiceId");
    if (storedProductId) {
      getOneInvoice(storedProductId);
      setEditable(true);
    }
    const handleBeforeUnload = (e) => {
      if (isFormModified) {
        const message =
          "Perubahan belum disimpan. Anda yakin ingin meninggalkan halaman ini?";
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const navigation = useNavigate();

  const getOneInvoice = (id) => {
    detailInvoice(id, (result) => {
      if (result && Array.isArray(result)) {
        result.forEach((sale) => {
          setData({
            ItemId: sale.ItemId,
            CustomerId: sale.CustomerId,
            saleDate: new Date(sale.SaleDate),
            numberInvoice: sale.Invoice.InvoiceNumber,
            DueDate: new Date(sale.Invoice.DueDate),
            Status: sale.Invoice.Status,
          });
          const newRows = result.map((sale, index) => ({
            uniqueKey: sale.uniqueKey,
            id: sale.Product.id,
            Nama: sale.Product.name,
            Kode: sale.Product.ProductId,
            Kuantitas: sale.Quantity,
            Harga: sale.Price,
            "Total Harga": sale.TotalPrice,
          }));

          setRowData(newRows);
        });
      }
    });
  };
  const getCustomer = () => {
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        getCustomers(id, (cb) => {
          setCustomer(cb);
        });
      });
    }
  };

  const getProductList = () => {
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        getProducts(id, (cb) => {
          setProduct(cb);
        });
      });
    }
  };

  const getInvoice = () => {
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        getInvoiceNumber(id, (cb) => {
          setInvoiceNumber(cb);
        });
      });
    }
  };

  const handleCustomer = (selectedOption) => {
    setData({
      ...data,
      CustomerId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isCustomer: selectedOption,
    });
    getInvoice();
    setIsFormModified(true);
  };
  const handleDateChange = (date) => {
    setData({
      ...data,
      saleDate: date,
    });
    setData((prevData) => ({
      ...prevData,
      DueDate: addOneMonth(prevData.saleDate),
    }));
    setIsFormModified(true);
  };

  const handleDueDateChange = (date) => {
    setData({
      ...data,
      DueDate: date,
    });
    setIsFormModified(true);
  };
  const handleProduct = (selectedOption) => {
    setData({
      ...data,
      ItemId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isProduct: selectedOption,
    });
    setIsFormModified(true);

    if (selectedOption) {
      getDetailProduct(selectedOption.value);
    }
  };
  const getDetailProduct = (value) => {
    detailProduct(value, (cb) => {
      const newProduct = {
        uniqueKey: Date.now(),
        id: cb.id,
        Nama: cb.name,
        Kode: cb.ProductId,
        Kuantitas: 1,
        Harga: 0,
        "Total Harga": 0,
      };
      setRowData([...rowData, newProduct]);
    });
  };
  const handleCellValueChanged = (event) => {
    const updatedData = rowData.map((row) => {
      if (row.uniqueKey === event.data.uniqueKey) {
        // Jika Kode cocok, perbarui data sesuai perubahan
        return {
          ...row,
          Nama: event.data.Nama,
          Kuantitas: event.data.Kuantitas,
          Harga: event.data.Harga,
          "Total Harga": event.data.Harga * event.data.Kuantitas,
        };
      }
      return row; // Jika Kode tidak cocok, biarkan data tetap seperti sebelumnya
    });

    setRowData(updatedData); // Perbarui state dengan data yang diperbarui
    setIsFormModified(true);
  };
  const handleRemoveRow = (uniqueKey) => {
    // Pastikan Anda memanggil setRowData dengan fungsi updater
    // untuk mendapatkan state terkini dari rowData
    setRowData((currentRowData) => {
      const updatedData = currentRowData.filter(
        (row) => row.uniqueKey !== uniqueKey
      );
      return updatedData; // Return the filtered array
    });
  };
  const cancelSubmit = (e) => {
    e.preventDefault();
    setIsFormModified(false);
    navigation("/invoices");
  };
  const addForm = async (e) => {
    e.preventDefault();
    // Data yang ada di state untuk customerId dan saleDate
    const { CustomerId, saleDate } = data;
    setLoadingButton(true);
    setIsFormModified(false);
    const isDataValid = validateData();
    if (!isDataValid) {
      setLoadingButton(false);
      return;
    }
    // Menyiapkan payload untuk dikirim ke server
    const payload = {
      customerId: CustomerId.toString(),
      items: rowData.map((row) => ({
        itemId: row.id.toString(),
        price: row.Harga.toString(),
        quantity: row.Kuantitas.toString(),
        uniqueKey: row.uniqueKey.toString(),
      })),
      saleDate: saleDate.toISOString().split("T")[0], // Mengonversi ke format YYYY-MM-DD
    };
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        setTimeout(async () => {
          await addInvoice(id, payload);
          setLoadingButton(false);
          navigation("/invoices");
        }, 3000);
      });
    }
  };

  const editForm = (e) => {
    e.preventDefault();
    const { CustomerId, saleDate, DueDate } = data;
    setLoadingButton(true);
    setIsFormModified(false);

    // Validasi data sebelum mengirim permintaan ke server
    const isDataValid = validateData();
    if (!isDataValid) {
      setLoadingButton(false);
      return;
    }
    const idInvoice = localStorage.getItem("invoiceId");
    // Menyiapkan payload untuk dikirim ke server
    const payload = {
      customerId: CustomerId.toString(),
      invoiceId: idInvoice,
      items: rowData.map((row) => ({
        uniqueKey: row.uniqueKey,
        itemId: row.id.toString(),
        price: row.Harga.toString(),
        quantity: row.Kuantitas.toString(),
      })),
      saleDate: saleDate.toISOString().split("T")[0],
      dueDate: DueDate.toISOString().split("T")[0],
    };
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        setTimeout(async () => {
          await updateInvoices(id, payload);
          setLoadingButton(false);
          navigation("/invoices");
        }, 3000);
      });
    }
  };

  const validateData = () => {
    // Validasi data di sini
    // Anda dapat menambahkan logika validasi sesuai kebutuhan

    let isValid = true;

    // Contoh validasi: cek apakah setiap row memiliki item yang tidak kosong
    for (const row of rowData) {
      if (!row) {
        isValid = false;
        break;
      }
    }
    if (rowData.length === 0) {
      isValid = false;
    }

    if (!isValid) {
      // Tampilkan pesan kesalahan atau lakukan tindakan validasi lainnya
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Produk Tidak Boleh Kosong",
        showConfirmButton: false,
        timer: 1500,
      });
    }

    return isValid;
  };

  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Faktur Penjualan</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/invoices">Faktur Penjualan</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {editable ? "Edit" : "Add"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="form col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {editable ? "Edit Faktur Penjualan" : "Faktur Penjualan Baru"}
                </h5>
                <hr />
                <form id="formProduct" onSubmit={editable ? editForm : addForm}>
                  <div className="row mb-3">
                    <label
                      htmlFor="vendorSelect"
                      className="col-sm-2 col-form-label"
                    >
                      Pelanggan<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        id="vendorSelect"
                        key={data.CustomerId || "no-value"}
                        options={customer.map((custom) => ({
                          label: custom.Name,
                          value: custom.id,
                        }))}
                        value={
                          data.CustomerId
                            ? {
                                label: customer.find(
                                  (custom) => custom.id === data.CustomerId
                                )?.Name,
                                value: data.CustomerId,
                              }
                            : null
                        }
                        onChange={handleCustomer}
                        className={
                          !valid.isCustomer
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Pelanggan..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        isDisabled={editable}
                        required
                      />
                      {!valid.isCustomer && (
                        <div className="invalid-feedback">
                          Pelanggan Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="invoiceDate"
                      className="col-sm-2 col-form-label"
                    >
                      Tanggal<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <ReactDatePicker
                        id="invoiceDate"
                        selected={data.saleDate}
                        onChange={handleDateChange}
                        className="form-control"
                        dateFormat="dd/MM/yyyy" // Format date in the day/month/year format
                        placeholderText="Pilih Tanggal"
                        disabled={editable}
                        customInput={<input readOnly={editable} />}
                        required
                      />
                      {!valid.isDate && (
                        <div className="invalid-feedback">
                          Tanggal Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="invoiceDueDate"
                      className="col-sm-2 col-form-label"
                    >
                      Jatuh Tempo
                      <Icons.InfoCircle
                        data-tooltip-id="info-due-date"
                        className="ms-2"
                        size="0.8em"
                      />
                      <Tooltip
                        id="info-due-date"
                        place="right"
                        variant="info"
                        content="Jatuh tempo ditentukan 30 hari setelah tanggal faktur. Perubahan dapat dilakukan melalui opsi edit."
                        style={{ zIndex: "1" }}
                      />
                    </label>
                    <div className="col-sm-5">
                      <ReactDatePicker
                        id="invoiceDueDate"
                        selected={data.DueDate}
                        onChange={handleDueDateChange}
                        className="form-control"
                        dateFormat="dd/MM/yyyy" // Format date in the day/month/year format
                        disabled={!editable}
                        placeholderText="Pilih Tanggal"
                        customInput={<input readOnly={!editable} />}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="invoiceNumber"
                      className="col-sm-2 col-form-label"
                    >
                      No Faktur<span className="required">*</span>
                      <Icons.InfoCircle
                        data-tooltip-id="info-id-product"
                        className="ms-2"
                        size="0.8em"
                      />
                      <Tooltip
                        id="info-id-product"
                        place="right"
                        variant="info"
                        content="Nomor Faktur Penjualan akan dihasilkan secara otomatis dan dapat dilihat setelah proses penyimpanan."
                      />
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        id="invoiceNumber"
                        className="form-control"
                        value={editable ? data.numberInvoice : invoiceNumber}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label
                      htmlFor="typeProduct"
                      className="col-sm-2 col-form-label"
                    >
                      Barang & Jasa<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        id="typeProduct"
                        options={product.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        value={
                          data.ItemId
                            ? {
                                label: product.find(
                                  (item) => item.id === data.ItemId
                                )?.name,
                                value: data.ItemId,
                              }
                            : null
                        }
                        onChange={handleProduct}
                        className={
                          !valid.isProduct
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Barang & Jasa..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        required
                      />
                      {!valid.isProduct && (
                        <div className="invalid-feedback">
                          Barang & Jasa Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-5 mb-5">
                    <div
                      style={{ width: "100%", height: "100%" }}
                      className="ag-theme-material"
                    >
                      <AgGridReact
                        rowData={rowData}
                        rowSelection={"multiple"}
                        animateRows={true}
                        columnDefs={columnDefs}
                        rowDragManaged={true}
                        tooltipShowDelay={0}
                        rowDragMultiRow={true}
                        cacheBlockSize={10}
                        gridOptions={gridOptions}
                        onCellValueChanged={handleCellValueChanged}
                        domLayout="autoHeight"
                      ></AgGridReact>
                      {data.Status === "Lunas" && (
                        <div className="lunas-overlay">
                          <img
                            src="https://png.pngtree.com/png-clipart/20230915/original/pngtree-paid-off-stamp-in-red-color-vector-png-image_12224654.png"
                            alt="Lunas"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3 ">
                    <span style={{ fontSize: 14, fontStyle: "italic" }}>
                      <span className="text-danger">*</span> Kolom harus diisi
                      dengan cara mengklik dua kali kolom
                    </span>
                  </div>
                  <div className="row mb-3 submit-button">
                    <div className="col-sm-10">
                      <button
                        className={`btn btn-sm me-3 btn-primary ${
                          loadingButton ? "" : "d-none"
                        }`}
                        type="button"
                        disabled={loadingButton}
                      >
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Menyimpan...
                      </button>
                      <button
                        type="submit"
                        className={`btn btn-sm btn-primary me-3 ${
                          loadingButton ? "d-none" : ""
                        }`}
                      >
                        Simpan
                      </button>
                      <Link
                        className="btn btn-sm  btn-secondary"
                        onClick={(e) => cancelSubmit(e)}
                      >
                        Batalkan
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FormInvoice;
