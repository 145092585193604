import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Icons from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import "./style.css";
import { getProfitYear } from "../../../fetch/widgetFetch";
const currencyFormatter = (params) => {
  if (params.value == null || isNaN(params.value)) {
    return "";
  }
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(params.value);
};
const Profit = (props) => {
  const { isMoveable, setIsMoveable } = props;

  const [data, setData] = useState({
    Status: "",
    Pendapatan: "",
    Pengeluaran: "",
    Profit: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [yearBefore, setYearBefore] = useState("");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  useEffect(() => {
    setYearBefore(currentYear - 1);
    fetchDataProfit(currentYear);
  }, []);

  const fetchDataProfit = (yearValue) => {
    getProfitYear({ year: yearValue }, (result) => {
      if (result.error) {
        console.error("Terjadi kesalahan saat mengambil data: ", result.error);
        // Anda dapat mengatur state error di sini jika Anda ingin menampilkan pesan kesalahan di UI
      } else {
        // Asumsikan 'result' memiliki struktur { Revenue: number, Expenses: number }
        const revenue = result.Revenue || 0;
        const expenses =
          result.Expenses < 0 ? -result.Expenses : result.Expenses || 0; // Jika Expenses negatif, ubah menjadi positif untuk visualisasi
        setChartData({
          datasets: [
            {
              label: "Rp.",
              data: [revenue, expenses],
              backgroundColor: [
                "rgba(0, 79, 221, 0.8)",
                "rgba(203, 16, 9, 0.91)",
              ],
              borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
              borderWidth: 1,
            },
          ],
        });
        setData({
          Pendapatan: revenue,
          Pengeluaran: expenses,
          Profit: result.Profit,
          Status: result.Status,
        });
      }
    });
    setIsLoading(false);
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };
  const handleRefresh = () => {
    setIsLoading(true);

    setTimeout(() => {
      fetchDataProfit(currentYear);
    }, 1200);
  };

  const toggleMoveable = () => {
    setIsMoveable(!isMoveable);
  };

  return (
    <div className="card">
      <div className="filter">
        <Link onClick={handleRefresh} className="refresh-button">
          <Icons.ArrowClockwise className="icon-refresh" />
        </Link>
        <Dropdown>
          <Dropdown.Toggle
            id={`dropdown-widget-button-drop`}
            size="sm"
            className="icon"
          >
            <Icons.ThreeDots className="icon-three" />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="end"
            className="dropdown-menu dropdown-menu-end dropdown-menu-arrow-right"
          >
            <Dropdown.Item className="dropdown-item" onClick={toggleMoveable}>
              <Icons.ArrowsMove size={14} className="me-2" />
              Move
            </Dropdown.Item>
            <Dropdown.Item
              className="dropdown-item"
              onClick={() => {
                fetchDataProfit(2023);
              }}
            >
              <Icons.GraphUpArrow size={14} className="me-2" />
              Tahun Sekarang
            </Dropdown.Item>
            <Dropdown.Item
              className="dropdown-item"
              onClick={() => {
                fetchDataProfit(yearBefore);
              }}
            >
              <Icons.GraphDown size={14} className="me-2" />
              Tahun Sebelumya
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="card-body">
        <h5 className="card-title">Laba/Rugi Tahun Ini</h5>
        <div className="activity-section">
          {isLoading ? (
            <div className="spinner-main-widget">
              <div className="loader-widget">
                <div className="bounce1-widget"></div>
                <div className="bounce2-widget"></div>
                <div className="bounce3-widget"></div>
              </div>
              <p>Memuat Data...</p>
            </div>
          ) : chartData ? (
            <div className="profit-widget row">
              <div className="profitChart col-4">
                <Doughnut data={chartData} options={options} />
              </div>
              <div className="col-8">
                <div className="row section-revenue">
                  <div className="profit-detail col-5">
                    <div>
                      <span
                        className="color-box"
                        style={{ backgroundColor: "rgba(54, 162, 235, 1)" }}
                      ></span>
                      <span>Pendapatan</span>
                    </div>
                    <div>
                      <span
                        className="color-box"
                        style={{ backgroundColor: "rgba(255, 99, 132, 1)" }}
                      ></span>
                      <span>Pengeluaran</span>
                    </div>

                    {/* Tambahkan lebih banyak jika diperlukan */}
                  </div>
                  <div className="data-profit col-7">
                    <span>{currencyFormatter({ value: data.Pendapatan })}</span>
                    <div>
                      <span>
                        {currencyFormatter({ value: data.Pengeluaran })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row section-profit">
                  <div className="profit-desc col-lg-5">
                    <p>{data.Status}</p>
                  </div>
                  <div className="value-profit col-lg-7">
                    <p>{currencyFormatter({ value: data.Profit })}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>Data tidak tersedia atau terjadi kesalahan</div> // Tampilkan pesan ini jika tidak ada data
          )}
        </div>
      </div>
    </div>
  );
};

export default Profit;
