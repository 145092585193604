import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Select from "react-select";
import * as Icons from "react-bootstrap-icons";

import "./style.css";
import axios from "axios";
import { getToken } from "../../../fetch/userFetch";
import {
  addCustomer,
  detailCustomer,
  upateCustomer,
} from "../../../fetch/customerFetch";

const AddCustomer = (props) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const isFormModifiedRef = useRef(false);
  const [addAddress, setAddAddress] = useState(false);
  const [provinsi, setProvinsi] = useState([]);
  const [code, setCode] = useState("");
  const [cities, setCities] = useState([]);
  const [editable, setEditable] = useState(false);
  const { customerId } = props;
  
  const [valid, setValid] = useState({
    isName: true,
    isPhone: true,
    isEmail: true,
    isBallance: true,
  });
  const [data, setData] = useState({
    Name: "",
    CustomerId: "",
    Email: "",
    Phone: "",
    Address: "",
    City: "",
    Provinsi: "",
    ZipCode: "",
    Country: "",
    Balance: 0,
  });
  const navigation = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);

    fetchLocation();
    if (customerId) {
      localStorage.setItem("customerId", customerId);
    }
    const storedCustomerId = localStorage.getItem("customerId");
    if (storedCustomerId) {
      getCustomer(storedCustomerId);
      setEditable(true);
      data.Address || data.Provinsi
        ? setAddAddress(true)
        : setAddAddress(false);
    }
    const handleBeforeUnload = (e) => {
      if (isFormModifiedRef.current) {
        e.preventDefault();
        e.returnValue =
          "Perubahan belum disimpan. Anda yakin ingin meninggalkan halaman ini?";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [loading, customerId]); // Tambahkan data sebagai dependensi

  const getCustomer = (id) => {
    detailCustomer(id, (data) => {
      setData({
        Name: data.Name,
        CustomerId: data.CustomerId,
        Email: data.Email,
        Phone: data.Phone,
        Address: data.Address,
        City: data.City,
        Provinsi: data.Provinsi,
        ZipCode: data.ZipCode,
        Country: data.Country,
        Balance: data.Balance,
      });
      setCode(data.Provinsi);
    });
  };
  const fetchLocation = async (kode) => {
    const apiKey =
      "cab3171a3ed4221835e9a860756533201915f91be1a976ebc862711b8b8e3551";
    const kodeApi = kode ? kode : code;
    try {
      const response = await axios.get(
        `https://api.binderbyte.com/wilayah/provinsi?api_key=${apiKey}`
      );
      setProvinsi(response.data.value);
      const result = await axios.get(
        `https://api.binderbyte.com/wilayah/kabupaten?api_key=${apiKey}&id_provinsi=${kodeApi}`
      );
      setCities(result.data.value);
    } catch (err) {
      console.error(err.message || err);
    }
  };

  const handleSelect = (selectedOption) => {
    setData({
      ...data,
      Provinsi: selectedOption ? selectedOption.value : "",
    });
    setCode(selectedOption ? selectedOption.value : "");
    fetchLocation(selectedOption ? selectedOption.value : "");
  };
  const validName = (e) => {
    const { value } = e.target;
    setData((prevState) => ({
      ...prevState,
      Name: value,
    }));
    setValid((prevState) => ({
      ...prevState,
      isName: e.target.value.trim() !== "",
    }));
    isFormModifiedRef.current = true;
  };
  const handlePhoneChange = (event) => {
    let value = event.target.value;
    if (value.startsWith("08")) {
      value = "+62" + value.substring(2);
    }

    const phoneRegex = /^(?:\+62\d{10,}|08\d{9,})$/;

    const isPhoneValid = phoneRegex.test(value);
    if (isPhoneValid && value.startsWith("08")) {
      value = "+62" + value.substring(2);
    }

    setData((prevState) => ({
      ...prevState,
      Phone: value,
    }));

    setValid((prevState) => ({
      ...prevState,
      isPhone: isPhoneValid,
    }));
    isFormModifiedRef.current = true;
  };
  const handleEmailChange = (event) => {
    const value = event.target.value;
    // Regex untuk validasi format email sederhana
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setData((prevState) => ({
      ...prevState,
      Email: value,
    }));
    setValid((prevState) => ({
      ...prevState,
      isEmail: emailRegex.test(value),
    }));
    isFormModifiedRef.current = true;
  };
  const handleAddAddressChange = (event) => {
    setAddAddress(event.target.checked);
  };
  const cancelSubmit = (e) => {
    e.preventDefault();
    isFormModifiedRef.current = false;
    navigation("/customers");
  };
  const submitForm = (e) => {
    e.preventDefault();
    isFormModifiedRef.current = false;
    setLoadingButton(true);
    const newCustomer = {
      Name: data.Name,
      CustomerId: data.CustomerId,
      Email: data.Email,
      Phone: data.Phone,
      Address: data.Address,
      City: data.City,
      Provinsi: code,
      ZipCode: data.ZipCode,
      Country: data.Country,
      Balance: data.Balance,
    };
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        setTimeout(() => {
          addCustomer(id, newCustomer);
          setLoadingButton(false);
          navigation("/customers");
        }, 3000);
      });
    }
  };
  const editForm = (e) => {
    e.preventDefault();
    isFormModifiedRef.current = false;
    setLoadingButton(true);
    const editNewCustomer = {
      id: customerId,
      Name: data.Name,
      CustomerId: data.CustomerId,
      Email: data.Email,
      Phone: data.Phone,
      Address: data.Address,
      City: data.City,
      Provinsi: code,
      ZipCode: data.ZipCode,
      Country: data.Country,
      Balance: data.Balance,
    };
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        const id = result.id;
        setTimeout(() => {
          upateCustomer(editNewCustomer);
          setLoadingButton(false);
          navigation("/customers");
        }, 3000);
      });
    }
  };
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Pelanggan</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/customers">Pelanggan</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {editable ? "Edit" : "Add"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="addForm col-lg-8">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {editable ? "Edit Pelanggan" : "Pelanggan Baru"}
                </h5>
                <hr />
                <form
                  onSubmit={editable ? editForm : submitForm}
                  id="formAccount"
                >
                  <div className="row mb-3">
                    <label
                      htmlFor="nameCustomer"
                      className="col-sm-4 col-form-label"
                    >
                      Nama Pelanggan<span className="required">*</span>
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="nameCustomer"
                        name="nameCustomer"
                        className={`form-control ${
                          !valid.isName ? "is-invalid" : ""
                        }`}
                        onChange={validName}
                        placeholder="Nama Pelanggan"
                        required
                        value={data.Name}
                      />
                      {!valid.isName && (
                        <div className="invalid-feedback">
                          Nama Pelanggan harus diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="IDCustomer"
                      className="col-sm-4 col-form-label"
                    >
                      ID Pelanggan<span className="required">*</span>
                      <Icons.InfoCircle
                        data-tooltip-id="info-id-pelanggan"
                        className="ms-2"
                        size="0.8em"
                      />
                      <Tooltip
                        id="info-id-pelanggan"
                        place="right"
                        ariant="info"
                        content="ID Pelanggan Akan Otomatis Terbuat Dengan ID C.0000"
                      />
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="IDCustomer"
                        name="IDCustomer"
                        className="form-control"
                        placeholder="ID Pelanggan"
                        value={editable ? data.CustomerId : "C.0000"}
                        disabled
                      />
                      {!valid.isName && (
                        <div className="invalid-feedback">
                          ID Pelanggan harus diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="phoneCustomer"
                      className="col-sm-4 col-form-label"
                    >
                      Nomor Handphone
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="tel"
                        id="phoneCustomer"
                        name="phoneCustomer"
                        className={`form-control ${
                          !valid.isPhone ? "is-invalid" : ""
                        }`}
                        placeholder="+6281234567890"
                        value={data.Phone}
                        onChange={handlePhoneChange}
                      />
                      {!valid.isPhone && (
                        <div className="invalid-feedback">
                          Format Nomor Handphone Salah
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="emailCustomer"
                      className="col-sm-4 col-form-label"
                    >
                      Email
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="email"
                        id="emailCustomer"
                        name="emailCustomer"
                        className={`form-control ${
                          !valid.isEmail ? "is-invalid" : ""
                        }`}
                        placeholder="alamatemail@example.com"
                        value={data.Email}
                        onChange={handleEmailChange}
                      />
                      {!valid.isEmail && (
                        <div className="invalid-feedback">
                          Harap masukkan alamat email yang valid.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-4 col-form-label">
                      Tambahkan Alamat
                    </div>
                    <div className="col-sm-6">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={addAddress}
                          onChange={handleAddAddressChange}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {addAddress && (
                    <>
                      <div className="row mb-3">
                        <label
                          htmlFor="country"
                          className="col-sm-4 col-form-label"
                        >
                          Negara
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            id="country"
                            name="Country"
                            className="form-control"
                            value="Indonesia"
                            disabled
                            onChange={(e) =>
                              setData({ ...data, Country: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="address"
                          className="col-sm-4 col-form-label"
                        >
                          Alamat Lengkap
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            id="address"
                            name="Address"
                            className="form-control"
                            value={data.Address}
                            onChange={(e) =>
                              setData({ ...data, Address: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="province"
                          className="col-sm-4 col-form-label"
                        >
                          Provinsi
                        </label>
                        <div className="col-sm-6">
                          <Select
                            id="province"
                            key={data.Provinsi || "no-value"}
                            options={provinsi.map((province) => ({
                              label: province.name,
                              value: province.id,
                            }))}
                            value={
                              data.Provinsi
                                ? {
                                    label: provinsi.find(
                                      (province) =>
                                        province.id === data.Provinsi
                                    )?.name,
                                    value: data.Provinsi,
                                  }
                                : null
                            }
                            onChange={handleSelect}
                            placeholder="Pilih Provinsi..."
                            isClearable={true}
                            closeMenuOnSelect={true}
                            isSearchable={true}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="city"
                          className="col-sm-4 col-form-label"
                        >
                          Kota
                        </label>
                        <div className="col-sm-6">
                          <Select
                            id="city"
                            options={cities.map((city) => ({
                              label: city.name,
                              value: city.id,
                            }))}
                            onChange={(selectedOption) => {
                              setData({
                                ...data,
                                City: selectedOption
                                  ? selectedOption.value
                                  : "",
                              });
                            }}
                            value={
                              data.City
                                ? {
                                    label: cities.find(
                                      (city) => city.id === data.City
                                    )?.name,
                                    value: data.City,
                                  }
                                : null
                            }
                            placeholder="Pilih Kota/Kabupaten..."
                            isClearable={true}
                            isSearchable={true}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="zipcode"
                          className="col-sm-4 col-form-label"
                        >
                          Kode Pos
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            id="zipcode"
                            name="zipcode"
                            className="form-control"
                            value={data.ZipCode}
                            onChange={(e) =>
                              setData({ ...data, ZipCode: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="row mb-3 submit-button">
                    <div className="col-sm-10">
                      <button
                        className={`btn btn-sm me-3 btn-primary ${
                          loadingButton ? "" : "d-none"
                        }`}
                        type="button"
                        disabled={loadingButton}
                      >
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Menyimpan...
                      </button>
                      <button
                        type="submit"
                        className={`btn btn-sm btn-primary me-3 ${
                          loadingButton ? "d-none" : ""
                        }`}
                      >
                        Simpan
                      </button>
                      <Link
                        className="btn btn-sm  btn-secondary"
                        onClick={(e) => cancelSubmit(e)}
                      >
                        Batalkan
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddCustomer;
