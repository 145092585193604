import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/favicon-big.png";
import Background from "../../assets/images/background.jpg";
import { userLogin } from "../../fetch/userFetch";
import "./style.css";
import { useNavigate } from "react-router-dom";
const Login = (props) => {
  const { setLoading, loginHandler } = props;
  const [form, setForm] = useState({
    identifier: "",
    password: "",
  });
  const navigation = useNavigate();
  useEffect(() => {
    setLoading(false)
  }, [])
  
  const handleSubmit = (event) => {
    event.preventDefault();
    userLogin(form, (result) => {
      localStorage.setItem("usertoken", result.acces_token);
      setTimeout(() => {
        navigation("/dashboard");
        setLoading(true);
        loginHandler(true);
      }, 1000);
    });
  };
  return (
    <section
      className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex justify-content-center py-4">
              <a
                href="index.html"
                className="logo d-flex align-items-center w-auto"
              >
                <img src={Logo} alt="Logo Rana Blue" />
                <span className="d-none d-lg-block logo-text">Rana BLUE</span>
              </a>
            </div>

            <div className="card mb-3">
              <div className="card-body">
                <div className="pt-4 pb-2">
                  <h5 className="card-title text-center pb-0 fs-4">
                    Login to Your Account
                  </h5>
                  <p className="text-center small">
                    Enter your Email or Username & password to login
                  </p>
                </div>

                <form
                  name="formLogin"
                  className="row g-3 needs-validation"
                  onSubmit={handleSubmit}
                >
                  <div className="col-12">
                    <label htmlFor="yourEmail" className="form-label">
                      Email Or Username
                    </label>
                    <div className="input-group has-validation">
                      <input
                        onChange={(e) =>
                          setForm({ ...form, identifier: e.target.value })
                        }
                        type="text"
                        name="username"
                        className="form-control"
                        id="yourUsername"
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter your username.
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <label htmlFor="yourPassword" className="form-label">
                      Password
                    </label>
                    <input
                      onChange={(e) =>
                        setForm({ ...form, password: e.target.value })
                      }
                      type="password"
                      name="password"
                      className="form-control"
                      id="yourPassword"
                      required
                    />
                    <div className="invalid-feedback">
                      Please enter your password!
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="remember"
                        value="true"
                        id="rememberMe"
                      />
                      <label className="form-check-label" htmlFor="rememberMe">
                        Remember me
                      </label>
                    </div>
                  </div>

                  <div className="col-12">
                    <button className="btn btn-primary w-100" type="submit">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="credits">
              Copyright{" "}
              <a href="https://ramusen.com/">PT.Ranajaya Mulia Sentosa</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
