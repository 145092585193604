import React, { useCallback, useEffect, useRef, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { HiDotsHorizontal } from "react-icons/hi";
import * as Icons from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import { AgGridReact } from "ag-grid-react";
import { createRoot } from "react-dom/client";

import {
  deleteInvoices,
  detailInvoice,
  getInvoices,
} from "../../fetch/invoiceFetch";
import { getToken } from "../../fetch/userFetch";
import Swal from "sweetalert2";
import PrintInvoice from "./Print";
const currencyFormatter = (params) => {
  if (params.value == null || isNaN(params.value)) {
    return "";
  }
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(params.value);
};
const formatDateToIndonesian = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("id-ID", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
const Invoice = () => {
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [ready, setReady] = useState(false);
  const [dataPrint, setDataPrint] = useState([]);
  const [rowData, setRowData] = useState([
    {
      "Nomor Faktur": "",
      Tanggal: "",
      Pelanggan: "",
      "Jatuh Tempo": "",
      Total: "",
      Status: "",
    },
  ]);
  const [columnDefs] = useState([
    {
      field: "Nomor Faktur",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      sortable: true,
      width: 220,
    },
    {
      field: "Tanggal",
      filter: true,
      sortable: true,
      width: 150,
      editable: true,
    },
    {
      field: "Pelanggan",
      filter: true,
      sortable: true,
      width: 220,
      tooltipValueGetter: (params) => {
        return params.value;
      },
      editable: true,
    },
    {
      field: "Status",
      filter: true,
      sortable: true,
      width: 200,
      editable: true,
    },
    {
      field: "Jatuh Tempo",
      filter: true,
      sortable: true,
      width: 150,
      editable: true,
    },
    {
      field: "Total",
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: currencyFormatter,
      width: 220,
    },
  ]);
  const [gridApi, setGridApi] = useState(null);
  const navigation = useNavigate();
  const printRef = useRef();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      if (dataPrint.length !== 0) {
        handleReadyPrint();
      }
    }, 1200);
    const userToken = localStorage.getItem("usertoken");
    localStorage.removeItem("invoiceId");
    if (userToken) {
      getToken(userToken, (cb) => {
        if (cb && cb.id) {
          getInvoices(cb.id, (result) => {
            if (result && Array.isArray(result)) {
              const temp = {};

              result.forEach((sale) => {
                const invoiceNum = sale.Invoice.InvoiceNumber || "";

                // Check if we already have this invoice number
                if (temp[invoiceNum]) {
                } else {
                  // Insert new entry
                  temp[invoiceNum] = {
                    id: sale.InvoiceId,
                    "Nomor Faktur": invoiceNum,
                    Tanggal: formatDateToIndonesian(sale.SaleDate) || "",
                    Pelanggan: sale.Customer.Name || "",
                    Total: sale.Invoice.TotalAmount || "",
                    Status: sale.Invoice.Status || "",
                    "Jatuh Tempo":
                      formatDateToIndonesian(sale.Invoice.DueDate) || "",
                  };
                }
              });

              // Convert temp object back to array
              const formattedData = Object.values(temp);
              setRowData(formattedData);
              if (gridApi) {
                gridApi.setRowData([...formattedData]);
              }
              setReady(formattedData.length > 0);
            } else {
              setReady(false);
            }
          });
        }
      });
    }

    return () => clearTimeout(timer);
  }, [dataPrint]);

  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
  };
  const handleNew = () => {
    navigation("/invoices/form");
  };
  const handleEdit = () => {
    const selectedIds = selectedRows.map((row) => row.id);

    if (selectedRows.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Tidak Ada Baris Yang Dipilih !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    if (selectedRows.length > 1) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Hanya Satu yang bisa Diedit !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    Swal.fire({
      title: "Are you sure to Edited This ?",
      text: "Are You Sure ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigation(`/invoices/form?invoiceId=${selectedIds[0]}`);
      }
    });
  };
  const handleBulkDelete = useCallback(() => {
    const selectedIds = selectedRows.map((row) => row.id);

    if (selectedRows.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Tidak Ada Baris Yang Dipilih !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    Swal.fire({
      title: "Are you sure to delete ?",
      text: "Are You Sure ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteInvoices(selectedIds, (result) => {
            if (result.status === 204 || result.status === 200) {
              const newRows = rowData.filter(
                (row) => !selectedIds.includes(row.id)
              );
              setRowData(newRows);
              setSelectedRows([]);
            }
          });
        } catch (error) {
          const errorMsg =
            error.message || "Terjadi kesalahan saat menghapus akun.";
        }
      }
    });
  }, [selectedRows, rowData]);
  const handlePrint = async () => {
    const selectedIds = selectedRows.map((row) => row.id);

    if (selectedRows.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Tidak Ada Baris Yang Dipilih !",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    // Assuming you have defined the detailInvoice and formatDateToIndonesian functions

    detailInvoice(selectedIds, (result) => {
      if (result && Array.isArray(result)) {
        // Ambil data terakhir dari result untuk dicetak
        const lastSale = result[result.length - 1];
        const printData = {
          pelanggan: lastSale.Customer.Name,
          date: formatDateToIndonesian(lastSale.SaleDate),
          invoiceNumber: lastSale.Invoice.InvoiceNumber,
          items: result.map((sale) => ({
            kode: sale.Product.ProductId,
            nama: sale.Product.name,
            qty: sale.Quantity,
            price: sale.Price,
            total: sale.TotalPrice,
          })),
          totalAmount: lastSale.Invoice.TotalAmount,
          company: lastSale.Tenant.name,
          currency: lastSale.Tenant.currency,
          dueDate: formatDateToIndonesian(lastSale.Invoice.DueDate),
        };
        setDataPrint(printData);
        setLoading(true);
      }
    });
  };

  const handleReadyPrint = () => {
    const printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Gagal membuka jendela pencetakan.");
      return;
    }

    // Set title and style
    printWindow.document.title = "Print Invoice";
    const bootstrapCDN =
      '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css">';
    const style = `.invoice-box {
      max-width: 800px;
      margin: auto;
      padding: 30px;
      border: 1px solid #eee;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      font-size: 16px;
      line-height: 24px;
      font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
      color: #000;
    }
    
    /* Common table styles */
    .invoice-box table {
      width: 100%;
      line-height: inherit;
      text-align: left;
    }
    .title {
      width: 170px;
      height: 50px;
    }
    .logo-tittle img {
      width: 100px;
    }
    .logo-tittle small {
      margin-bottom: 10px;
      font-size: 10px;
      font-weight: bolder;
    }
    .title-company {
      display: flex;
      height: 150px;
      justify-content: center;
      align-items: center;
    }
    .title-company h4 {
      font-weight: bold;
    }
    .vendor-section {
      border-top: 1px solid #000; /* 1px solid black border on the top */
      border-bottom: 1px solid #000;
    }
    .info-faktur-detail {
      border: 1px solid;
    }
    .table .me-5 {
      margin-right: 5%;
    }
    .invoice-box table td {
      padding: 5px;
      vertical-align: top;
    }
    
    .invoice-box table tr.top table td {
      padding-bottom: 20px;
    }
    
    .invoice-box table tr.top table td.title {
      font-size: 45px;
      line-height: 45px;
      color: #333;
    }
    
    .table .span-info {
      width: 150px;
    }
    .sign-section .border-bottom {
      margin-top: 150px;
    }
    .aggre {
      padding-left: 50px;
    }
    .invoice-box table tr.information table td {
      padding-bottom: 40px;
    }
    
    .invoice-box table tr.heading td {
      background: #eee;
      border-bottom: 1px solid #ddd;
      font-weight: bold;
    }
    
    .invoice-box table tr.details td {
      padding-bottom: 20px;
    }
    
    .invoice-box table tr.item td {
      border-bottom: 1px solid #eee;
    }
    
    .invoice-box table tr.item.last td {
      border-bottom: none;
    }
    
    .invoice-box table tr.total td:nth-child(2) {
      border-top: 2px solid #eee;
      font-weight: bold;
    }
    
    @media print {
      @page {
        size: A4 portrait;
        margin: 0mm; /* Remove default page margin */
      }
      .invoice-box {
        width: 100%;
        max-width: 210mm; /* A4 width */
        margin: 10mm auto; /* Add some margin to the top and bottom */
        box-shadow: none; /* Remove box shadow in print */
      }
    
    
      .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
      }
      .title {
        width: 170px;
        height: 50px;
      }
      .logo-tittle img {
        width: 100px;
      }
      .logo-tittle small {
        margin-bottom: 10px;
        font-size: 10px;
        font-weight: bolder;
      }
      .title-company {
        display: flex;
        height: 150px;
        justify-content: center;
        align-items: center;
      }
      .title-company h4 {
        font-weight: bold;
      }
      .vendor-section {
        border-top: 1px solid #000; /* 1px solid black border on the top */
        border-bottom: 1px solid #000;
      }
      .info-faktur-detail {
        border: 1px solid;
      }
      .table .me-5 {
        margin-right: 5%;
      }
      
    } `;

    printWindow.document.head.innerHTML = `${bootstrapCDN}<style>${style}</style>`;
    // Buat kontainer di dalam body untuk React root
    const container = printWindow.document.createElement("div");
    printWindow.document.body.appendChild(container);

    // Gunakan container untuk createRoot dan render komponen
    const root = createRoot(container);
    root.render(<PrintInvoice data={dataPrint} />);

    // Delay printing to ensure content is rendered
    setTimeout(() => {
      setLoading(false);

      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }, 500); // Adjust timeout as necessary
  };

  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Faktur Penjualan</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Faktur Penjualan</li>
                </ol>
              </nav>
            </div>
            <div className="action-button">
              <Link
                className="btn btn-sm btn-primary mx-3"
                onClick={(e) => {
                  handleNew();
                  e.preventDefault();
                }}
              >
                <Icons.PlusLg className="me-1" /> Baru
              </Link>
              <DropdownButton
                as={ButtonGroup}
                id={`dropdown-button-drop`}
                size="sm"
                title={<HiDotsHorizontal color="black" size="18px" />}
                className="no-chevron-dropdown"
              >
                <Dropdown.Item onClick={handleEdit}>
                  <Icons.Pencil color="blue"/> Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={handleBulkDelete}>
                  <Icons.Trash color="red"/> Delete
                </Dropdown.Item>
                <Dropdown.Item onClick={handlePrint}>
                  <Icons.Printer color="black" /> Print
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          {ready ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{ width: "100%", height: "100%" }}
                className="ag-theme-material"
              >
                <AgGridReact
                  rowData={rowData}
                  rowSelection={"multiple"}
                  animateRows={true}
                  columnDefs={columnDefs}
                  rowDragManaged={true}
                  rowDragMultiRow={true}
                  onSelectionChanged={onSelectionChanged}
                  cacheBlockSize={10}
                  domLayout="autoHeight"
                  pagination={true}
                  onGridReady={onGridReady}
                  paginationPageSize={10}
                ></AgGridReact>
              </div>
            </div>
          ) : (
            <div
              style={{
                padding: "20px",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              <h5
                style={{
                  fontSize: "1h5x",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Faktur Penjualan Tidak Ditemukan
              </h5>
              <small className="text-muted my-5">
                Buat Faktur Penjualan Terlebih Dahulu Untuk Melakukan Traksasi
              </small>
              <br></br>
              <button className=" mt-5 btn btn-primary" onClick={handleNew}>
                Buat Faktur Penjualan Baru
              </button>
            </div>
          )}
        </>
      )}
      <div ref={printRef} className="print-section">
        <PrintInvoice data={dataPrint} />
      </div>
    </>
  );
};

export default Invoice;
