import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Icons from "react-bootstrap-icons";
import Swal from "sweetalert2";
import { AgGridReact } from "ag-grid-react";
import queryString from "query-string";
import {
  addPayroll,
  detailPayroll,
  updatePayroll,
} from "../../../fetch/payrollFetch";
import { getEmployees } from "../../../fetch/employeeFetch";
import { detailSalarie, getSalaries } from "../../../fetch/salariesFetch";
import "./style.css";
const currencyFormatter = (params) => {
  if (params.value == null || isNaN(params.value)) {
    return "";
  }
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(params.value);
};
const formatDateToIndonesian = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("id-ID", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
const PayrollForm = () => {
  const [loading, setLoading] = useState(true);
  const [editable, setEditable] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const [employees, setEmployees] = useState({});
  const [salary, setSalary] = useState({});
  const [totals, setTotals] = useState({
    totalGajiPokok: 0,
    totalTunjangan: 0,
    totalPotongan: 0,
    totalGajiBersih: 0,
  });

  const [valid, setValid] = useState({
    isEmployee: true,
    isDate: true,
    isSalary: true,
  });
  const [data, setData] = useState({
    employeeId: null,
    paymentDate: new Date(),
    salaryId: null,
    items: [],
  });
  const [gridOptions] = useState({
    overlayNoRowsTemplate:
      "<span>Sesuiakan Data yang Akan Dimasukan Disini.Klik Dua Kali Di Kolom Yang Ingin Diubah</span>",
    // ... properti gridOptions lainnya
  });

  const [rowData, setRowData] = useState([]);
  const [columnDefs] = useState([
    {
      field: "",
      rowDrag: true,
      width: 50,
    },
    {
      field: "Komponen Gaji",
      sortable: true,
      width: 500,
    },
    {
      field: "Nilai",
      headerName: "Nilai*",
      headerTooltip: "Klik dua kali pada kolom untuk mengedit",
      filter: true,
      sortable: true,
      editable: true,
      valueFormatter: currencyFormatter,
      width: 450,
    },
    {
      field: "id",
      headerName: "Hapus",
      cellRenderer: (params) => (
        <Link
          className="btn btn-sm "
          onClick={() => handleRemoveRow(params.data.uniqueKey)}
          style={{
            marginLeft: "20px",
            cursor: "pointer",
          }}
        >
          <Icons.XCircle size={20} color="red" />
        </Link>
      ),
      width: 120,
    },
  ]);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const payrollId = queryParams.payrollId;
  const navigation = useNavigate();
  useEffect(() => {
    let totalGajiPokok = 0;
    let totalTunjangan = 0;
    let totalPotongan = 0;

    rowData.forEach((row) => {
      const nilai = parseFloat(row.Nilai);
      if (row["Komponen Gaji"] === "Gaji Pokok") {
        totalGajiPokok += isNaN(nilai) ? 0 : nilai;
      } else if (
        row.Type === "Bayar" &&
        row["Komponen Gaji"] !== "Gaji Pokok"
      ) {
        totalTunjangan += isNaN(nilai) ? 0 : nilai;
      } else if (row.Type === "Potongan") {
        totalPotongan += isNaN(nilai) ? 0 : nilai;
      }
    });

    const totalGajiBersih = totalGajiPokok + totalTunjangan - totalPotongan;

    setTotals({
      totalGajiPokok,
      totalTunjangan,
      totalPotongan,
      totalGajiBersih,
    });
  }, [rowData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    getEmployeeList();
    getSalaryList();
    if (payrollId) {
      localStorage.setItem("payrollId", payrollId);
    }
    const storedProductId = localStorage.getItem("payrollId");

    if (storedProductId) {
      getOnePayroll(storedProductId);
      setEditable(true);
    }
    const handleBeforeUnload = (e) => {
      if (isFormModified) {
        const message =
          "Perubahan belum disimpan. Anda yakin ingin meninggalkan halaman ini?";
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const getOnePayroll = (id) => {
    detailPayroll(id, (result) => {
      if (result) {
        const payroll = result;
        console.log(payroll.PayrollDetail);
        setData({
          employeeId: payroll.EmployeeId,
          paymentDate: new Date(payroll.PaymentDate),
        });
        const newRows = payroll.PayrollDetail.map((PayrollDetail, index) => ({
          uniqueKey: Date.now() + index,
          id: PayrollDetail.Salary.id,
          Type: PayrollDetail.Salary.Method,
          "Komponen Gaji": PayrollDetail.Salary.Name,
          Nilai: PayrollDetail.Amount,
        }));
        console.log(newRows);
        // getInvoice(payment.InvoicePayments[0].CustomerId);
        setRowData(newRows);
      }
    });
  };
  const getEmployeeList = () => {
    getEmployees((cb) => {
      setEmployees(cb);
    });
  };
  const getSalaryList = () => {
    getSalaries((cb) => {
      setSalary(cb);
    });
  };
  const handleEmployee = (selectedOption) => {
    setData({
      ...data,
      employeeId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isEmployee: selectedOption,
    });
    setIsFormModified(true);
  };
  const handleDateChange = (date) => {
    setData({
      ...data,
      paymentDate: date,
    });
    setIsFormModified(true);
  };
  const handleSalary = (selectedOption) => {
    setData({
      ...data,
      salaryId: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isSalary: selectedOption,
    });
    setIsFormModified(true);
    if (selectedOption) {
      getDetailSalary(selectedOption.value);
    }
  };
  const getDetailSalary = (value) => {
    detailSalarie(value, (cb) => {
      const isDuplicate = rowData.some((row) => row.id === cb.id);
      if (isDuplicate) {
        // Tampilkan pesan kesalahan
        Swal.fire({
          icon: "error",
          title: "Duplikat Komponen Gaji",
          text: "Hanya satu Komponen Gaji yang diizinkan.",
        });
        return;
      }

      const newProduct = {
        uniqueKey: Date.now(),
        id: cb.id,
        Type: cb.Method,
        "Komponen Gaji": cb.Name,
        Nilai: 0,
      };
      setRowData([...rowData, newProduct]);
    });
  };
  const handleRemoveRow = (uniqueKey) => {
    // Pastikan Anda memanggil setRowData dengan fungsi updater
    // untuk mendapatkan state terkini dari rowData
    setRowData((currentRowData) => {
      const updatedData = currentRowData.filter(
        (row) => row.uniqueKey !== uniqueKey
      );
      return updatedData; // Return the filtered array
    });
  };
  const handleCellValueChanged = (event) => {
    const updatedData = rowData.map((row) => {
      if (row.uniqueKey === event.data.uniqueKey) {
        // Jika Kode cocok, perbarui data sesuai perubahan
        return {
          ...row,
          Nama: event.data.Nama,
          Kuantitas: event.data.Kuantitas,
          Harga: event.data.Harga,
          "Total Harga": event.data.Harga * event.data.Kuantitas,
        };
      }
      return row; // Jika Kode tidak cocok, biarkan data tetap seperti sebelumnya
    });

    setRowData(updatedData); // Perbarui state dengan data yang diperbarui
    setIsFormModified(true);
  };
  const cancelSubmit = (e) => {
    e.preventDefault();
    setIsFormModified(false);
    navigation("/payrolls");
  };
  const addForm = (e) => {
    e.preventDefault();
    const { paymentDate, employeeId } = data;
    setLoadingButton(true);
    setIsFormModified(false);
    const isDataValid = validateData();
    if (!isDataValid) {
      setLoadingButton(false);
      return;
    }
    const payload = {
      paymentDate: paymentDate.toISOString().split("T")[0],
      EmployeeId: employeeId.toString(),
      items: rowData.map((row) => ({
        SalaryId: row.id,
        Amount: row.Nilai.toString(),
      })),
    };
    console.log(payload);
    setTimeout(async () => {
      const result = await addPayroll(payload);
      if (result) {
        setLoadingButton(false);
        navigation("/payrolls");
      } else {
        setLoadingButton(false);
      }
    }, 3000);
  };

  const editForm = (e) => {
    e.preventDefault();
    console.log(e);
    const { paymentDate, employeeId } = data;
    setLoadingButton(true);
    setIsFormModified(false);
    const isDataValid = validateData();
    if (!isDataValid) {
      setLoadingButton(false);
      return;
    }
    const payrollId = localStorage.getItem("payrollId");
    const payload = {
      payrollId: payrollId,
      paymentDate: paymentDate,
      EmployeeId: employeeId,
      items: rowData.map((row) => ({
        SalaryId: row.id,
        Amount: row.Nilai.toString(),
      })),
    };
    setTimeout(async () => {
      const pay = await updatePayroll(payload);
      if (pay) {
        setLoadingButton(false);
        navigation("/payrolls");
      } else {
        setLoadingButton(false);
      }
    }, 3000);
  };
  const validateData = () => {
    let errorMessage = "";

    let isValid = true;
    const hasGajiPokok = rowData.some(
      (row) => row["Komponen Gaji"] === "Gaji Pokok"
    );

    if (!hasGajiPokok) {
      isValid = false;
      errorMessage = "Gaji Pokok wajib diisi nilai!";
    }
    // Contoh validasi: cek apakah setiap row memiliki item yang tidak kosong
    for (const row of rowData) {
      if (!row.Nilai) {
        isValid = false;
        errorMessage =
          "Setiap Komponen Gaji yang dipilih harus memiliki nilai .";
        break;
      }
    }
    if (rowData.length === 0) {
      isValid = false;
      errorMessage = "Komponen Gaji tidak boleh kosong .";
    }

    if (!isValid) {
      // Tampilkan pesan kesalahan atau lakukan tindakan validasi lainnya
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Validasi Gagal",
        text: errorMessage,
        showConfirmButton: false,
        timer: 1500,
      });
    }

    return isValid;
  };
  return (
    <>
      {loading ? (
        <div className="spinner-main">
          <div className="loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <p>Tunggu Sebentar...</p>
        </div>
      ) : (
        <>
          <div className="header-page d-flex justify-content-between">
            <div className="pagetitle">
              <h1>Pencatatan Gaji</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/payrolls">Pencatatan Gaji</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {editable ? "Edit" : "Add"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="formPayroll col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {editable ? "Edit Pencatatan Gaji" : "Pencatatan Gaji Baru"}
                </h5>
                <hr />
                <form id="formPayroll" onSubmit={editable ? editForm : addForm}>
                  <div className="row mb-3">
                    <label
                      htmlFor="employeeSelect"
                      className="col-sm-2 col-form-label"
                    >
                      Karyawan<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        id="employeeSelect"
                        key={data.employeeId || "no-value"}
                        options={employees.map((employee) => ({
                          label: employee.FullName,
                          value: employee.id,
                        }))}
                        value={
                          data.employeeId
                            ? {
                                label: employees.find(
                                  (employee) => employee.id === data.employeeId
                                )?.FullName,
                                value: data.employeeId,
                              }
                            : null
                        }
                        onChange={handleEmployee}
                        className={
                          !valid.isEmployee
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Karyawan..."
                        isClearable={true}
                        isDisabled={editable}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        required
                      />
                      {!valid.isEmployee && (
                        <div className="invalid-feedback">
                          Karyawan Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="payrollDate"
                      className="col-sm-2 col-form-label"
                    >
                      Periode Bulan<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <ReactDatePicker
                        id="payrollDate"
                        selected={data.paymentDate}
                        onChange={handleDateChange}
                        className="form-control"
                        dateFormat="dd/MM/yyyy" // Format date in the day/month/year format
                        placeholderText="Pilih Tanggal"
                        customInput={<input readOnly={editable} />}
                        required
                      />
                      {!valid.isDate && (
                        <div className="invalid-feedback">
                          Tanggal Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="salarySelect"
                      className="col-sm-2 col-form-label"
                    >
                      Komponen Gaji<span className="required">*</span>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        id="salarySelect"
                        key={data.salaryId || "no-value"}
                        options={salary.map((salarie) => ({
                          label: salarie.Name,
                          value: salarie.id,
                        }))}
                        value={
                          data.salaryId
                            ? {
                                label: salary.find(
                                  (account) => account.id === data.salaryId
                                )?.Name,
                                value: data.salaryId,
                              }
                            : null
                        }
                        onChange={handleSalary}
                        className={
                          !valid.isSalary
                            ? "is-invalid react-select"
                            : "react-select"
                        }
                        placeholder="Pilih Kompoenen Gaji..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        required={editable ? false : true}
                      />
                      {!valid.isSalary && (
                        <div className="invalid-feedback">
                          Kompoenen Gaji Harus Diisi.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div
                      style={{ width: "100%", height: "100%", zIndex: "0" }}
                      className="ag-theme-material"
                    >
                      <AgGridReact
                        rowData={rowData}
                        rowSelection={"multiple"}
                        animateRows={true}
                        columnDefs={columnDefs}
                        rowDragManaged={true}
                        tooltipShowDelay={0}
                        rowDragMultiRow={true}
                        cacheBlockSize={10}
                        gridOptions={gridOptions}
                        onCellValueChanged={handleCellValueChanged}
                        domLayout="autoHeight"
                      ></AgGridReact>
                    </div>
                  </div>
                  <div className="row justify-content-end mt-4">
                    <div className="col-6">
                      <div className="card summary-card">
                        <div className="card-body">
                          <div className="row summary-row">
                            <div className="col summary-title">Gaji Pokok</div>
                            <div className="col-auto summary-value">
                              {currencyFormatter({
                                value: totals.totalGajiPokok,
                              })}
                            </div>
                          </div>
                          <div className="row summary-row">
                            <div className="col summary-title">
                              Total Tunjangan
                            </div>
                            <div className="col-auto summary-value">
                              {currencyFormatter({
                                value: totals.totalTunjangan,
                              })}
                            </div>
                          </div>
                          <div className="row summary-row">
                            <div className="col summary-title">
                              Total Potongan
                            </div>
                            <div className="col-auto summary-value">
                              {currencyFormatter({
                                value: totals.totalPotongan,
                              })}
                            </div>
                          </div>
                          <div className="row summary-row">
                            <div className="col summary-title">Gaji Bersih</div>
                            <div className="col-auto summary-value">
                              {currencyFormatter({
                                value: totals.totalGajiBersih,
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 ">
                    <span style={{ fontSize: 14, fontStyle: "italic" }}>
                      <span className="text-danger">*</span> Kolom harus diisi
                      dengan cara mengklik dua kali kolom
                    </span>
                  </div>
                  <div className="row mb-3 submit-button">
                    <div className="col-sm-10">
                      <button
                        className={`btn btn-sm me-3 btn-primary ${
                          loadingButton ? "" : "d-none"
                        }`}
                        type="button"
                        disabled={loadingButton}
                      >
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Menyimpan...
                      </button>
                      <button
                        type="submit"
                        className={`btn btn-sm btn-primary me-3 ${
                          loadingButton ? "d-none" : ""
                        }`}
                      >
                        Simpan
                      </button>
                      <Link
                        className="btn btn-sm  btn-secondary"
                        onClick={(e) => cancelSubmit(e)}
                      >
                        Batalkan
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PayrollForm;
