import React, { useEffect, useState } from "react";
import { detail, getToken } from "../../fetch/userFetch";
import { AiOutlinePoweroff } from "react-icons/ai";
import Background from "../../assets/images/background.jpg";
import Logo from "../../assets/images/favicon-big.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./style.css";
import { getIndustries } from "../../fetch/industriFetch";
import axios from "axios";
import { addTenant } from "../../fetch/tenantFetch";
const Tenant = (props) => {
  const { loginHandler, loginStatus, checkHandler, setLoading } = props;
  const [user, setUser] = useState("");
  const [code, setCode] = useState(0);
  const [cities, setCities] = useState([]);
  const [provinsi, setProvinsi] = useState([]);
  const [industries, seIndustries] = useState([]);
  const [valid, setValid] = useState({
    isCompany: true,
    isPostCode: true,
    isAddres: true,
  });
  const [data, setData] = useState({
    name: "",
    industry_id: 0,
    currency: "IDR",
    postcode: 0,
    address: "",
  });
  useEffect(() => {
    const userToken = localStorage.getItem("usertoken");
    getToken(userToken, (cb) => {
      detail(cb.id, (result) => {
        setUser(result.username);
      });
    });

    getIndustries((cb) => {
      seIndustries(cb);
    });
    fetchProvinsi();
    fetchKota();
  }, [code]);
  const fetchProvinsi = async () => {
    try {
      const apiKey =
        "cab3171a3ed4221835e9a860756533201915f91be1a976ebc862711b8b8e3551";
      const respone = await axios.get(
        `https://api.binderbyte.com/wilayah/provinsi?api_key=${apiKey}`
      );
      setProvinsi(respone.data.value);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchKota = async () => {
    try {
      const apiKey =
        "cab3171a3ed4221835e9a860756533201915f91be1a976ebc862711b8b8e3551";

      const kode = code;
      const result = await axios.get(
        `https://api.binderbyte.com/wilayah/kabupaten?api_key=${apiKey}&id_provinsi=${kode}`
      );
      setCities(result.data.value);
    } catch (err) {
      console.log(err);
    }
  };

  const handleValidCompany = (e) => {
    const { value } = e.target;
    setData((prevState) => ({
      ...prevState,
      name: value,
    }));
    setValid((prevState) => ({
      ...prevState,
      isCompany: e.target.value.trim() !== "",
    }));
  };
  const handleValidPost = (e) => {
    const { value } = e.target;
    setData((prevState) => ({
      ...prevState,
      postcode: value,
    }));
    setValid((prevState) => ({
      ...prevState,
      isPostCode: e.target.value.trim() !== "",
    }));
  };
  const handleValidAddres = (e) => {
    const { value } = e.target;
    setData((prevState) => ({
      ...prevState,
      address: value,
    }));
    setValid((prevState) => ({
      ...prevState,
      isAddres: e.target.value.trim() !== "",
    }));
  };
  const logoutHandler = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are You Sure ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (loginStatus) {
          localStorage.clear();
          setLoading(true);
          loginHandler(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Logout Successful !",
            text: "Successfully Logout !",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          loginHandler(true);
        }
      }
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    const newTenant = {
      name: data.name,
      industry_id: data.industry_id,
      currency: data.currency,
      postcode: data.postcode,
      address: data.address,
    };
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, async (result) => {
        const id = result.id;
        const success = await addTenant(id, newTenant);
        if (success) {
          checkHandler(true);
          setLoading(true);
        }
      });
    }
  };
  return (
    <>
      <div className="container-fluid container-tenant min-vh-100  d-flex flex-column">
        <div className="row">
          <div className="d-flex justify-content-end logout-tenant">
            <span>
              Selamat Datang <strong>{user}</strong>
            </span>
            <div className="icon-logout">
              <Link onClick={logoutHandler}>
                <AiOutlinePoweroff />
              </Link>
            </div>
          </div>
        </div>
        <div className="row d-flex align-items-center mx-auto w-50 ">
          <div className="header-tenant">
            <div className="logo-header-tenant">
              <img className="logo-image" src={Logo} alt="Logo Rana Blue" />
              <div className="logo-texts">
                <span className="logo-text">Rana</span>
                <span className="logo-subtext">Blue</span>
              </div>
              <div className="vertical-line"></div>
              <div className="header-desc">
                <small>
                  Rana Blue adalah perangkat lunak akuntansi online yang
                  membantu memudahkan pembukuan anda.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex align-items-center mx-auto w-50 my-5">
          <div className="form-tenant p-5">
            <div className="pagetitle text-center">
              <h5>Konfigurasikan Profil Perusahaan Anda </h5>
            </div>
            <small className="section-header text-muted text-uppercase font-small text-regular">
              Detail Perusahaan
            </small>
            <div className="form-vertical">
              <form className="row g-3" onSubmit={submitForm}>
                <div className="col-md-12">
                  <label htmlFor="name" className="form-label">
                    Nama Perusahaan <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      !valid.isCompany ? "is-invalid" : ""
                    }`}
                    id="name"
                    onChange={handleValidCompany}
                    required
                  />
                  {!valid.isCompany && (
                    <div className="invalid-feedback">
                      Nama Perusahaan harus diisi.
                    </div>
                  )}
                </div>
                <div className="col-md-12">
                  <label htmlFor="Industri" className="form-label">
                    Industri
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="industri"
                    onChange={(e) =>
                      setData({
                        ...data,
                        industry_id: e.target.value,
                      })
                    }
                  >
                    <option></option>
                    {industries.map((industry) => (
                      <option key={industry.id} value={industry.id}>
                        {industry.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12">
                  <label htmlFor="currency" className="form-label">
                    Mata Uang<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="currency"
                    value="IDR - Indonesia Rupiah"
                    disabled
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="negara" className="form-label">
                    Lokasi Bisnis
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="negara"
                    placeholder="Lokasi Negara"
                    value="Indonesia"
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputProvinsi" className="form-label">
                    Provinsi
                  </label>
                  <select
                    className="form-select"
                    id="inputProvinsi"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                  >
                    <option className="text-muted">Pilih Provinsi</option>
                    {provinsi.map((prov) => (
                      <option key={prov.id} value={prov.id}>
                        {prov.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputKota" className="form-label">
                    Kota/Kabupaten
                  </label>
                  <select
                    className="form-select"
                    id="inputKota"
                    aria-label="Default select example"
                  >
                    <option className="text-muted">Pilih Kota/Kabupaten</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="kodepos" className="form-label">
                    Kode Pos<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    className={`form-control ${
                      !valid.isPostCode ? "is-invalid" : ""
                    }`}
                    id="kodepos"
                    onChange={handleValidPost}
                    placeholder="Kode Pos"
                    required
                  />
                  {!valid.isPostCode && (
                    <div className="invalid-feedback">
                      Kode Pos harus diisi.
                    </div>
                  )}
                </div>
                <div className="col-md-12">
                  <label htmlFor="alamat" className="form-label">
                    Alamat<span className="required">*</span>
                  </label>
                  <textarea
                    className={`form-control ${
                      !valid.isAddres ? "is-invalid" : ""
                    }`}
                    id="alamat"
                    onChange={handleValidAddres}
                    placeholder="Alamat Perusahaan"
                    style={{ height: "100px" }}
                    required
                  ></textarea>
                  {!valid.isAddres && (
                    <div className="invalid-feedback">Alamat harus diisi.</div>
                  )}
                </div>
                <div className="btn-start">
                  <button type="submit" id="btn-submit" className="btn ">
                    Memulai
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tenant;
