import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";

import "./styles/App.css";
import * as Icons from "react-bootstrap-icons";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css"; // Tema Material

import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import Login from "./pages/Login";
import ReactDatePicker from "react-datepicker";

import Logout from "./pages/Logout";
import {
  addCashBankAccount,
  checkCash,
  checkStatus,
  getToken,
} from "./fetch/userFetch";
import Tenant from "./pages/Tenant";
import Loading from "./components/Loading";
import SideNav from "./components/Sidenav";
import Header from "./components/Header";
import Accounts from "./components/Accounts";
import AddAccount from "./components/Accounts/AddAccount";
import Error from "./pages/404";
import Customer from "./components/Customer";
import AddCustomer from "./components/Customer/AddCustomer";
import Products from "./components/Products";
import FormProduct from "./components/Products/Form";
import Invoice from "./components/Invoice";
import FormInvoice from "./components/Invoice/Form";
import PrintInvoice from "./components/Invoice/Print";
import Dashboard from "./components/Dashboard";
import Jurnal from "./components/Jurnal";
import FormJournal from "./components/Jurnal/Form";
import Payments from "./components/Payments";
import FormPayments from "./components/Payments/Form";
import PayBank from "./components/Banks/PayBank";
import PayBankForm from "./components/Banks/PayBank/Form";
import ReceiptBank from "./components/Banks/Income";
import FormReceipts from "./components/Banks/Income/Form";
import Employee from "./components/Employee";
import EmployeeForm from "./components/Employee/Form";
import Salaries from "./components/Salaries";
import SalariesForm from "./components/Salaries/Form";
import Payroll from "./components/Payroll";
import PayrollForm from "./components/Payroll/Form";
import Expense from "./components/Expense";
import ExpenseForm from "./components/Expense/Form";
import ProfitReport from "./components/Reports/ProfitReport";
import ScaleReport from "./components/Reports/ScaleReport";
import Swal from "sweetalert2";
import { endYearCloseBooks } from "./fetch/accountFetch";
const formatDateToIndonesian = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("id-ID", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
function App() {
  const [loginStatus, setLoginStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState(false);
  const [isCash, setIsCash] = useState(true);
  const [showCashInitModal, setShowCashInitModal] = useState(false);
  const [customerId, setcustomerId] = useState(0);
  const [accountId, setAccountId] = useState(0);
  const [isSidebarToggled, setIsSidebarToggled] = useState(false);
  const [showSidebarAndNavbar, setShowSidebarAndNavbar] = useState(true);
  const [prevIsCash, setPrevIsCash] = useState(isCash);
  const [firstDateMonth, setFirstDateMonth] = useState(
    formatDateToIndonesian(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    )
  );
  const [valid, setValid] = useState({
    isName: true,
    isType: true,
    isEquity: true,
    isDate: true,
  });
  const [data, setData] = useState({
    name: "",
    Balance: null,
    type: "",
    equity: "",
    Date: new Date(),
  });
  const [endYear, setEndYear] = useState(new Date().getFullYear());
  const loginHandler = (result) => {
    setLoginStatus(result);
  };
  const checkHandler = (result) => {
    setCheck(result);
  };
  const handleToggleSidebar = () => {
    setIsSidebarToggled(!isSidebarToggled);
  };

  const handleCloseModal = () => {
    Swal.fire({
      position: "center",
      icon: "info",
      title: "Buat Kas/Bank Terlebih dahulu!",
      text: "Buat akun Kas/Bank terlebih dahulu untuk memulai transaksi",
      showConfirmButton: false,
      timer: 1500,
    });
  };
  const formatRupiah = (value) => {
    if (
      !value ||
      typeof value !== "string" ||
      !/^\d+$/.test(value.replace(/[^0-9]/g, ""))
    )
      return "0";
    const numberValue = parseInt(value.replace(/[^0-9]/g, ""), 10);

    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return formatter.format(numberValue).replace(/Rp\s?/, "");
  };
  useEffect(() => {
    setTimeout(() => {
      if (new Date() === new Date(`${endYear}-12-31`) && check) {
        Swal.fire({
          title: "Penutupan Buku",
          text: "Penutupan Buku otomatis dijalankan pada akhir tahun ! ",
          icon: "warning",
        });
      }
    }, 4000);
  }, []);

  useEffect(() => {
    const userToken = localStorage.getItem("usertoken");
    if (userToken) {
      getToken(userToken, (result) => {
        checkStatus(result.id, (cb) => {
          setCheck(cb);
        });
        checkCash((result) => {
          setTimeout(() => {
            setIsCash(result);
          }, 2000);
        });
      });
      setTimeout(() => {
        setLoginStatus(true);
        setShowCashInitModal(!isCash);
        setLoading(false);
      }, 2000); // Tunda selama 3 detik sebelum menghentikan loading
    } else {
      setLoading(false); // Hentikan loading jika tidak ada token
    }
    if (prevIsCash && check && !isCash && showSidebarAndNavbar) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Buat Kas/Bank!",
        text: "Buat Kas/Bank terlebih dahulu",
        showConfirmButton: false,
        timer: 4000,
      });
    }
    setPrevIsCash(isCash);
  }, [
    check,
    isSidebarToggled,
    customerId,
    loginStatus,
    loading,
    isCash,
    showSidebarAndNavbar,
  ]);
  const validName = (e) => {
    const { value } = e.target;
    setData((prevState) => ({
      ...prevState,
      name: value,
    }));
    setValid((prevState) => ({
      ...prevState,
      isName: e.target.value.trim() !== "",
    }));
  };
  const handleTypeChange = (selectedOption) => {
    setData({
      ...data,
      type: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isType: true,
    });
    if (!selectedOption) {
      setValid({
        ...valid,
        isType: selectedOption,
      });
    }
  };
  const handleAccountEquity = (selectedOption) => {
    setData({
      ...data,
      equity: selectedOption ? selectedOption.value : "",
    });
    setValid({
      ...valid,
      isEquity: true,
    });
    if (!selectedOption) {
      setValid({
        ...valid,
        isEquity: selectedOption,
      });
    }
  };
  const handleDateChange = (date) => {
    setData({
      ...data,
      Date: date,
    });
  };
  const handleBalanceChange = (e) => {
    const formattedValue = formatRupiah(e.target.value);
    setData({ ...data, Balance: formattedValue });
  };
  const addForm = async (e) => {
    e.preventDefault();
    const { name, type, Balance, equity, Date } = data;
    if (!Balance || Balance <= 0) {
      return Swal.fire({
        position: "center",
        icon: "warning",
        title: "Saldo Awal harus diisi!",
        text: "Saldo harus diisi",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    const numericBalance = parseFloat(Balance.replace(/\./g, ""));
    const payload = {
      AccountName: name,
      AccountType: type,
      Balance: numericBalance,
      EquityName: "Equitas Saldo Awal",
      Date: Date,
    };

    const result = await addCashBankAccount(payload);
    console.log(result);
    if (result) {
      setShowCashInitModal(false);
    } else {
      return;
    }
  };
  return (
    <>
      <Router>
        {loading ? (
          <Loading />
        ) : loginStatus ? (
          check ? (
            <div className={`App ${isSidebarToggled ? "toggle-sidebar" : ""}`}>
              {showSidebarAndNavbar && (
                <Header
                  handleToggleSidebar={handleToggleSidebar}
                  setLoading={setLoading}
                  loginHandler={loginHandler}
                  loginStatus={loginStatus}
                />
              )}
              {showSidebarAndNavbar && (
                <SideNav handleToggleSidebar={handleToggleSidebar} />
              )}
              <main id="main" className="main">
                <Routes>
                  <Route
                    path="*"
                    element={
                      <Error
                        loginStatus={loginStatus}
                        setSidebarAndNavbarVisibility={setShowSidebarAndNavbar}
                        setLoading={setLoading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/"
                    element={<Dashboard loginStatus={loginStatus} />}
                  />
                  <Route
                    exact
                    path="/dashboard"
                    element={<Dashboard loginStatus={loginStatus} />}
                  />
                  <Route
                    path="accounts"
                    element={
                      <Accounts
                        setAccountId={setAccountId}
                        accountId={accountId}
                      />
                    }
                  />
                  <Route
                    path="accounts/add"
                    element={<AddAccount accountId={accountId} />}
                  />
                  <Route
                    path="/customers"
                    element={
                      <Customer
                        setcustomerId={setcustomerId}
                        customerId={customerId}
                      />
                    }
                  />
                  <Route
                    path="/customers/add"
                    element={<AddCustomer customerId={customerId} />}
                  />
                  <Route path="/products" element={<Products />} />
                  <Route path="/products/form" element={<FormProduct />} />
                  <Route path="/invoices" element={<Invoice />} />
                  <Route path="/invoices/form" element={<FormInvoice />} />
                  <Route path="/journals" element={<Jurnal />} />
                  <Route path="/journals/form" element={<FormJournal />} />
                  <Route path="/payments" element={<Payments />} />
                  <Route path="/payments/form" element={<FormPayments />} />
                  <Route path="/banks/payments" element={<PayBank />} />
                  <Route
                    path="/banks/payments/form"
                    element={<PayBankForm />}
                  />
                  <Route path="/banks/receipts" element={<ReceiptBank />} />
                  <Route
                    path="/banks/receipts/form"
                    element={<FormReceipts />}
                  />
                  <Route path="/employees" element={<Employee />} />
                  <Route path="/employees/form" element={<EmployeeForm />} />
                  <Route path="/salaries" element={<Salaries />} />
                  <Route path="/salaries/form" element={<SalariesForm />} />
                  <Route path="/payrolls" element={<Payroll />} />
                  <Route path="/payrolls/form" element={<PayrollForm />} />
                  <Route path="/expenses" element={<Expense />} />
                  <Route path="/expenses/form" element={<ExpenseForm />} />
                  <Route path="/profit/reports" element={<ProfitReport />} />
                  <Route path="/scales/reports" element={<ScaleReport />} />
                </Routes>
              </main>
            </div>
          ) : (
            <Tenant
              loginStatus={loginStatus}
              loginHandler={loginHandler}
              checkHandler={checkHandler}
              setLoading={setLoading}
            />
          )
        ) : (
          <Routes>
            <Route
              path="*"
              element={
                <Error
                  loginStatus={loginStatus}
                  setSidebarAndNavbarVisibility={setShowSidebarAndNavbar}
                  setLoading={setLoading}
                />
              }
            />
            <Route
              path="/"
              element={
                <Login
                  loginStatus={loginStatus}
                  loginHandler={loginHandler}
                  setLoading={setLoading}
                />
              }
            />
            <Route
              path="/login"
              element={
                <Login
                  loginStatus={loginStatus}
                  loginHandler={loginHandler}
                  setLoading={setLoading}
                />
              }
            />
          </Routes>
        )}
        {showCashInitModal && !loading && check && showSidebarAndNavbar && (
          <>
            <div className="modal-backdrop show"></div>
            <div
              className="modal modal-lg show fade"
              tabIndex="-1"
              role="dialog"
              style={{ display: "block" }}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Kas & Bank</h5>
                    <Link
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal}
                    >
                      <span aria-hidden="true">
                        <Icons.X className="icon-x" />
                      </span>
                    </Link>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={addForm}>
                      <div className="row mb-3">
                        <label
                          htmlFor="typeCashBank"
                          className="col-sm-4 col-form-label"
                        >
                          Jenis<span className="required">*</span>
                        </label>
                        <div className="col-sm-8">
                          <Select
                            id="typeCashBank"
                            key={data.type || "no-value"}
                            options={[
                              { label: "Bank", value: "Bank" },
                              { label: "Kas", value: "Kas" },
                            ]}
                            value={
                              data.type
                                ? {
                                    label:
                                      data.type === "Bank" ? "Bank" : "Kas",
                                    value: data.type,
                                  }
                                : null
                            }
                            onChange={handleTypeChange}
                            className={
                              !valid.isType
                                ? "is-invalid react-select"
                                : "react-select"
                            }
                            placeholder="Pilih Jenis..."
                            isClearable={true}
                            closeMenuOnSelect={true}
                            isSearchable={true}
                            required
                          />
                          {!valid.isType && (
                            <div className="invalid-feedback">
                              Jenis Harus Diisi.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="nameCashBank"
                          className="col-sm-4 col-form-label"
                        >
                          Nama<span className="required">*</span>
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            id="nameCashBank"
                            name="nameCashBank"
                            className={`form-control ${
                              !valid.isName ? "is-invalid" : ""
                            }`}
                            onChange={validName}
                            placeholder="Contoh: BCA a/c XXX-XXX, dll"
                            required
                            value={data.name}
                          />
                          {!valid.isName && (
                            <div className="invalid-feedback">
                              Nama Barang & Jasa harus diisi.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="nameCashBank"
                          className="col-sm-4 col-form-label"
                        >
                          Per Tanggal<span className="required">*</span>
                        </label>
                        <div className="col-sm-8">
                          <ReactDatePicker
                            id="payrollDate"
                            selected={data.Date}
                            onChange={handleDateChange}
                            className="form-control"
                            dateFormat="dd/MM/yyyy" // Format date in the day/month/year format
                            placeholderText="Pilih Tanggal"
                            required
                          />
                          {!valid.isDate && (
                            <div className="invalid-feedback">
                              Tanggal Harus Diisi.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="balanceCashBank"
                          className="col-sm-4 col-form-label"
                        >
                          Saldo Per{" "}
                          {data.Date
                            ? formatDateToIndonesian(new Date(data.Date))
                            : "Tanggal"}
                        </label>
                        <div className="col-sm-8">
                          <div className="input-group mb-3">
                            <span className="input-group-text">Rp</span>
                            <input
                              type="text"
                              id="balanceCashBank"
                              name="balanceCashBank"
                              className="form-control"
                              onChange={handleBalanceChange}
                              value={data.Balance}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="row mb-3 text-danger"
                        style={{
                          fontSize: 14,
                          fontStyle: "italic",
                          padding: 10,
                        }}
                      >
                        Saldo terakhir kas dapat dimasukkan lain waktu melalui
                        menu "Akun Perkiraan"
                      </div>
                      <div className="row mb-3 submit-button-cashbank">
                        <div className="col-sm-12 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-md btn-primary me-3"
                          >
                            Lanjut
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Router>
    </>
  );
}

export default App;
